<section class="mb-6">
  <h1 class="section-title">Contact Us</h1>
</section>

<section class="d-flex">
    <form class="mx-auto col-md-8 wx-form narrow primary" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label class="label" for="name">Name</label>
            <input type="text" formControlName="name" class="wx-form-control" id="name">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <label class="label" for="company">Company</label>
            <input type="text" formControlName="company" class="wx-form-control" id="company">
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
              <div *ngIf="f.company.errors.required">Company is required</div>
            </div>
        </div>
        <div class="form-group">
            <label class="label" for="email">Email</label>
            <input type="email" formControlName="email" class="wx-form-control" id="email">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        <div class="form-group">
            <label class="label" for="message">Message</label>
            <textarea class="wx-form-control" formControlName="message" id="message" rows="3"></textarea>
            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
              <div *ngIf="f.message.errors.required">Message is required</div>
            </div>
        </div>
        <div class="mt-3">
          <re-captcha formControlName="captcha" required siteKey="{{ siteKey }}"></re-captcha>
        </div>

        <button type="submit" class="btn btn-accent btn-block mt-3">Send</button>
    </form>
</section>
