import { Component, ElementRef, HostListener, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GarmentService } from 'src/services/garment.service';
import { environment } from 'src/environments/environment';
import { GarmentPairing } from '../models/garment-pairing.model';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SpecSheetService } from 'src/services/spec-sheet.service';
import { AppSettingsService } from 'src/services/app-settings.service';
import { StyleDetails } from '../models/style.model';
import { ProductLineService } from 'src/services/product-line.service';
import { GarmentDetails } from '../models/garment.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BrowseModalComponent } from '../browse-modal/browse-modal.component';
import { GarmentFilterService } from 'src/services/garment-filter.service';

@Component({
  selector: 'app-garment-selector',
  templateUrl: './garment-selector.component.html',
  styleUrls: ['./garment-selector.component.scss']
})
export class GarmentSelectorComponent implements OnInit {

  pairedGarments$: Observable<GarmentPairing[]>;
  baseUrl$: string;
  route: string;
  styleId: number;
  selectedGarmentId$: Observable<number>;
  isMaleActive$: Observable<boolean>;
  isFemaleActive$: Observable<boolean>;
  garmentIds$: number[];
  smallSidebar$: boolean;
  bsModalRef: BsModalRef;

  constructor(private garmentService: GarmentService,
    private router: Router, private cdref: ChangeDetectorRef,
    private specSheetService: SpecSheetService,
    private appSettingsService: AppSettingsService,
    private productLineService: ProductLineService,
    private garmentFilters: GarmentFilterService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.garmentService.garmentSelectorComponents$.push(this);

    this.route = this.router.url.split('/')[1];
    this.styleId = parseInt(this.router.url.split('/')[2]);

    this.garmentIds$ = [];
    if(this.route == "product-details"){
      var garments = this.garmentService.getAllGarments().pipe(map(garments => garments.filter(garment => garment.styles.some(style => style.id == this.styleId))));
      garments.subscribe(garments => garments.filter(garment => this.garmentIds$.push(garment.id)));
    }

    if (this.garmentService.activeSpecSheetId != this.specSheetService.activeSpecSheetIdString$) {
      this.garmentService.clearGarmentPairings();
      this.garmentService.addGarmentPairings(this.specSheetService.get(this.specSheetService.activeSpecSheetIdString$).garmentPairings);
      this.garmentService.activeSpecSheetId = this.specSheetService.activeSpecSheetIdString$;
    }

    this.pairedGarments$ = this.garmentService.garmentsPairings$;

    this.baseUrl$ = `${environment.apiUrl}`;

    this.selectedGarmentId$ = this.garmentService.selectedGarmentId$;

    this.smallSidebar$ = window.innerWidth < 992;

    this.isMaleActive$ = this.appSettingsService.appSettings$.pipe(
      map( appSettings => appSettings.gender == 1)
    )

    this.isFemaleActive$ = this.appSettingsService.appSettings$.pipe(
      map( appSettings => appSettings.gender == 2)
    )

    this.cdref.detectChanges();
  }

  ngOnDestroy(): void {
    this.garmentService.garmentSelectorComponents$ = this.garmentService.garmentSelectorComponents$.filter(component => component != this);
  }

  toggleSelectedGarment(garmentId: number, removePairing: boolean = false){
    var isPaired;
    this.isGarmentPaired(garmentId).subscribe(bool => isPaired = bool);

    var selectedGarmentId;
    this.selectedGarmentId$.subscribe(id => selectedGarmentId = id);

    if (this.route == "product-details" || removePairing){
      this.garmentService.toggleGarmentPairing(garmentId, this.styleId);

      if (selectedGarmentId == garmentId) {
        this.garmentService.setSelectedGarmentId(-1);
      }
    } else if (this.route == "uniform-builder" && isPaired && selectedGarmentId != garmentId){
      this.garmentService.setSelectedGarmentId(garmentId);
    } else if (this.route == "uniform-builder" && isPaired && selectedGarmentId == garmentId){
      this.garmentService.setSelectedGarmentId(-1);
    }else{
      this.openModal(garmentId);
    }
  }

  openModal(id:number) {
      this.bsModalRef = this.modalService.show(BrowseModalComponent,
        {
          class: 'modal-dialog-centered',
          initialState: { garmentId: id }
        });
  }

  isGarmentPaired(garmentId: number): Observable<boolean> {
    return this.pairedGarments$.pipe(map(garments => garments.some(garment => garment.garmentId == garmentId)));
  }

  getPairedGarment(garmentId: number): Observable<GarmentPairing> {
    return this.garmentService.getGarmentPairing(garmentId);
  }

  getStyleDetails(styleId: number): Observable<StyleDetails> {
    return this.productLineService.getStyle(styleId);
  }

  getGarmentDetails(garmentId: number): Observable<GarmentDetails> {
    return this.garmentService.get(garmentId);
  }

  showGarment(garmentId: number): boolean {
    return this.garmentIds$.includes(garmentId);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.smallSidebar$ = window.innerWidth < 992;
  }
}
