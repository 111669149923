import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Order, OrderDetails } from 'src/app/models/order.model';
import { StyleDetails } from 'src/app/models/style.model';
import { environment } from 'src/environments/environment';

interface ContactInfo {
  name: string;
  company: string;
  email: string;
  address: string;
  city: string;
  state: string;
}

@Injectable()
export class ShoppingCartService {

  private _selectedSwatches: BehaviorSubject<StyleDetails[]> = new BehaviorSubject([]);
  private _selectedSwatches$!: Observable<StyleDetails[]>;
  get selectedSwatches$() { 
    if(!this._selectedSwatches$) {
      this._selectedSwatches$ = this._selectedSwatches.asObservable().pipe(shareReplay(1));
    }
    return this._selectedSwatches$; 
  }

  private _contactInfo: BehaviorSubject<ContactInfo | null> = new BehaviorSubject(null);
  private _contactInfo$!: Observable<ContactInfo | null>;
  get contactInfo$() { 
    if(!this._contactInfo$) {
      this._contactInfo$ = this._contactInfo.asObservable().pipe(shareReplay(1));
    }
    return this._contactInfo$; 
  }

  private _formPristine: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _formPristine$!: Observable<boolean>;
  get formPristine$() { 
    if(!this._formPristine$) {
      this._formPristine$ = this._formPristine.asObservable().pipe(shareReplay(1));
    }
    return this._formPristine$; 
  }

  private _formInvalid: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _formInvalid$!: Observable<boolean>;
  get formInvalid$() { 
    if(!this._formInvalid$) {
      this._formInvalid$ = this._formInvalid.asObservable().pipe(shareReplay(1));
    }
    return this._formInvalid$; 
  }

  constructor(private http: HttpClient) { 
    let swatches = localStorage.getItem('shopping-cart');
    if(swatches && swatches.length) {
      this._selectedSwatches.next(JSON.parse(swatches));
    }
  }

  addToCart(swatch: StyleDetails) {
    let swatches = [...this._selectedSwatches.value];

    let existingSwatch = swatches.find(s => s.id === swatch.id);

    if(!existingSwatch) {
      swatches.push(swatch);
      this.updateCart(swatches);
    }
  }

  removeFromCart(swatch: StyleDetails) {
    let swatches = [...this._selectedSwatches.value];

    swatches = swatches.filter(s => s.id !== swatch.id);
    this.updateCart(swatches);
  }

  clearCart() {
    this.updateCart([]);
  }

  cartIncludesSwatch(swatch: StyleDetails): Observable<boolean> {
    return this._selectedSwatches.pipe(
      map(swatches => {
        return swatches.some(s => s.id === swatch.id)
      })    
    )
  }

  checkout(orderInfo: Order): Observable<OrderDetails> {
    return this.http.post<OrderDetails>(`${environment.apiUrl}/orders`, orderInfo);
  }

  private updateCart(swatches: StyleDetails[]) {
    localStorage.setItem('shopping-cart', JSON.stringify(swatches));
    this._selectedSwatches.next(swatches);
  }

  updateContactInfo(info: ContactInfo, formPristine: boolean, formInvalid: boolean) {
    this._contactInfo.next(info);
    this._formPristine.next(formPristine);
    this._formInvalid.next(formInvalid);
  }

  updateFormPristine(formPristine: boolean) {
    this._formPristine.next(formPristine);
  }

  updateFormInvalid(formInvalid: boolean) {
    this._formInvalid.next(formInvalid);
  }

  clearContactInfo() {
    this._contactInfo.next(null);
    this._formInvalid.next(true);
    this._formPristine.next(true);
  }
}
