import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ManagerDetails } from 'src/app/models/manager.model';
import { Region, RegionDetails } from 'src/app/models/region.model';
import { AppSettings } from 'src/app/models/settings.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private appSettingsSubject$ = new BehaviorSubject<AppSettings>(new AppSettings());
  public appSettings$ = this.appSettingsSubject$.asObservable();

  private openRegionsSubject$ = new BehaviorSubject<number[]>([]);
  private openRegions$ = this.openRegionsSubject$.asObservable();

  private regions$: Observable<RegionDetails[]>;
  private managers$: Observable<ManagerDetails[]>;

  constructor(private http: HttpClient) { }

  getAllRegions(){
    if(!this.regions$) {
      this.regions$ = this.http.get<RegionDetails[]>(`${environment.apiUrl}` + "/regions").pipe(
        shareReplay(1)
      );
    }

    return this.regions$;
  }

  getRegion(id: number){
    return this.getAllRegions().pipe(
      map(regions => regions.find(region => region.id == id))
    );
  }

  getOpenRegions(){
    return this.openRegions$;
  }

  getAllManagers(){
    if(!this.managers$) {
      this.managers$ = this.http.get<ManagerDetails[]>(`${environment.apiUrl}` + "/managers").pipe(
        shareReplay(1)
      );
    }

    return this.managers$;
  }

  toggleActiveRegion(regionId: number) {
    let settings = this.appSettingsSubject$.value;

    settings.region = regionId;

    this.appSettingsSubject$.next(settings);
  }

  toggleOpenRegion(regionId: number) {
    let arr = [...this.openRegionsSubject$.value];
    if (arr.includes(regionId) && arr.length > 0){
        let i = arr.findIndex( val => val == regionId);
        arr.splice(i, 1);
    }else{
        arr.push(regionId);
    }

    this.openRegionsSubject$.next(arr);
  }

  toggleActiveLanguage(langId: number) {
    let settings = this.appSettingsSubject$.value;
    settings.language = langId;

    this.appSettingsSubject$.next(settings);
  }

  toggleActiveGender(gender: number) {
    let settings = this.appSettingsSubject$.value;
    settings.gender = gender;

    this.appSettingsSubject$.next(settings);
  }
}
