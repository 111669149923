import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface RemoveEvent {
  name: string;
  value: any;
}

@Component({
  selector: 'wx-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input()
  label?: string;

  @Input()
  value: any;

  @Input()
  name: string;

  @Input()
  showRemoveIcon: boolean = true;

  @Output('remove')
  onRemove = new EventEmitter<RemoveEvent>();

  removeIcon = faTimes;

  constructor() { }

  ngOnInit(): void {
  }

  remove() {
    this.onRemove.emit({
      name: this.name,
      value: this.value
    });
  }

}
