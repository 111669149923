import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StandardsComponent } from './standards/standards.component';
import { FooterComponent } from './footer/footer.component';
import { SelectedStandardsComponent } from './selected-standards/selected-standards.component';
import { ProductCatalogComponent } from './product-catalog/product-catalog.component';
import { HazardsComponent } from './hazards/hazards.component';
import { StandardDetailsComponent } from './standard-details/standard-details.component';
import { HazardDetailsComponent } from './hazard-details/hazard-details.component';
import { ProductLinesComponent } from './product-lines/product-lines.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductLinesExpandedComponent } from './product-lines-expanded/product-lines-expanded.component';
import { GarmentSelectorComponent } from './garment-selector/garment-selector.component';
import { UniformBuilderComponent } from './uniform-builder/uniform-builder.component';
import { SpecSheetDetailsComponent } from './spec-sheet-details/spec-sheet-details.component';
import { AppSettingsService } from 'src/services/app-settings.service';
import { SettingsComponent } from './settings/settings.component';
import { MySpecSheetsComponent } from './my-spec-sheets/my-spec-sheets.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChipComponent } from './chip/chip.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MessageService } from 'primeng/api';
import { ResourceSidebarComponent } from './resource-sidebar/resource-sidebar.component';
import { BrowseModalComponent } from './browse-modal/browse-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { RegionsComponent } from './regions/regions.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SpecSheetExportComponent } from './spec-sheet-export/spec-sheet-export.component';
import { UniformModalComponent } from './uniform-modal/uniform-modal.component';
import { ContactSalesComponent } from './contact-sales/contact-sales.component';
import { ShoppingCartService } from 'src/services/shopping-cart.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    DashboardComponent,
    StandardsComponent,
    FooterComponent,
    SelectedStandardsComponent,
    ProductCatalogComponent,
    ProductLinesComponent,
    HazardsComponent,
    StandardDetailsComponent,
    HazardDetailsComponent,
    ProductDetailsComponent,
    ProductLinesExpandedComponent,
    GarmentSelectorComponent,
    UniformBuilderComponent,
    SpecSheetDetailsComponent,
    SettingsComponent,
    MySpecSheetsComponent,
    ChipComponent,
    ContactUsComponent,
    ResourceSidebarComponent,
    BrowseModalComponent,
    RegionsComponent,
    NotFoundComponent,
    SpecSheetExportComponent,
    UniformModalComponent,
    ContactSalesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    GalleriaModule,
    ConfirmDialogModule,
    FormsModule,
    ToastModule,
    FontAwesomeModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    DialogModule,
    GuidedTourModule,
    ProgressSpinnerModule
  ],
  providers: [AppSettingsService, MessageService, BsModalService, GuidedTourService, BsModalRef, ShoppingCartService],
  bootstrap: [AppComponent]
})
export class AppModule { }
