import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { CategoryDetails } from "../models/category.model";
import { Region } from "../models/region.model";
import { StandardDetails } from "../models/standard.model";
import { StyleDetails } from "../models/style.model";

/**
 * Given an observable of standard IDs, filter the array of styles.
 */
export const filterStandards = (filterIds: Observable<number[]>) => (styles: Observable<StyleDetails[]>) => combineLatest([styles, filterIds]).pipe(
  map(([styles, standardIds]) => {
    let result = styles;

    if(standardIds.length > 0) {
      result = result.filter(style => style.standards.some(standard => standardIds.includes(standard.id)));
    }

    return result;
  })
);

/**
 * Given an observable of category IDs, filter the array of styles.
 */
export const filterCategories = (filterIds: Observable<number[]>) => (styles: Observable<StyleDetails[]>) => combineLatest([styles, filterIds]).pipe(
  map(([styles, categoryIds]) => {
    let result = styles;

    if(categoryIds.length > 0) {
      result = result.filter(style => categoryIds.includes(style.line.category as number));
    }

    return result;
  })
);

/**
 * Given an observable of line IDs, filter the array of styles.
 */
export const filterLines = (filterIds: Observable<number[]>) => (styles: Observable<StyleDetails[]>) => combineLatest([styles, filterIds]).pipe(
  map(([styles, lineIds]) => {
    let result = styles;

    if(lineIds.length > 0) {
      result = result.filter(style => lineIds.includes(style.line.id));
    }

    return result;
  })
);

/**
 * Given an observable of garment IDs, filter the array of styles.
 */
export const filterGarments = (filterIds: Observable<number[]>) => (styles: Observable<StyleDetails[]>) => combineLatest([styles, filterIds]).pipe(
  map(([styles, garmentIds]) => {
    let result = styles;

    if(garmentIds.length > 0) {
      result = result.filter(style => style.garments.some(garment => garmentIds.includes(garment.id)));
    }

    return result;
  })
);

/**
 * Given an observable of special IDs, filter the array of styles.
 */
export const filterSpecials = (filterIds: Observable<number[]>) => (styles: Observable<StyleDetails[]>) => combineLatest([styles, filterIds]).pipe(
  map(([styles, specialIds]) => {
    let result = styles;

    if(specialIds.length > 0) {
      result = result.filter(style => style.specials.some(special => specialIds.includes(special.id)));
    }

    return result;
  })
);

/**
 * Given an observable of special IDs, filter the array of styles.
 */
export const filterRegions = (standards: Observable<StandardDetails[]>, filterIds: Observable<number[]>) => (styles: Observable<StyleDetails[]>) => combineLatest([styles, standards, filterIds]).pipe(
  map(([styles, allStandards, regionIds]) => {
    let result = styles;

    if (regionIds.length > 0){
        result = result.filter(style => style.standards.some(standard => allStandards.some(s => s.id == standard.id && s.regions.some(r => regionIds.includes(r.id)))));
    }


    return result;
  })
);

/**
 * Given an observable of search string and all categories, filter the array of styles. Searches on line name and description, category name.
 */
export const filterSearchProductLine = (search: Observable<string>, allCategories: Observable<CategoryDetails[]>) => (styles: Observable<StyleDetails[]>) => combineLatest([styles, search, allCategories]).pipe(
  map(([styles, search, categories]) => {
    let result = styles;

    if(search) {
      result = result.filter(style => style.line.name?.toLowerCase()?.includes(search.toLowerCase())
        || style.line.description?.toLowerCase()?.includes(search.toLowerCase())
        || categories.find(category => category.id == style.line.category as number)?.name?.toLowerCase()?.includes(search.toLowerCase())
      );
    }

    return result;
  })
);

/**
 * Given an observable of search string, filter the array of styles. Searches on line name, style name and description.
 */
export const filterSearchProductCatalog = (search: Observable<string>) => (styles: Observable<StyleDetails[]>) => combineLatest([styles, search]).pipe(
  map(([styles, search]) => {
    let result = styles;

    if(search) {
      result = result.filter(style => style.line.name?.toLowerCase()?.includes(search.toLowerCase())
        || style.line.description?.toLowerCase()?.includes(search.toLowerCase())
        || style.name?.toLowerCase()?.includes(search.toLowerCase())
        || style.description?.toLowerCase()?.includes(search.toLowerCase())
      );
    }

    return result;
  })
);
