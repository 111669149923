import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/services/app-settings.service';
import { RegionFilterService } from 'src/services/region-filter.service';
import { Region, RegionDetails } from '../models/region.model';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';
import { ManagerDetails } from '../models/manager.model';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})
export class RegionsComponent implements OnInit, OnDestroy {

  activeRegion$: Observable<number>;
  activeRegionMapSrc$: Observable<string>;
  regions$: Observable<RegionDetails[]>;
  managers$: Observable<ManagerDetails[]>;
  baseUrl = environment.apiUrl;
  showTerms: boolean;
  tourIsComplete: boolean;
  tourIsLoaded: boolean = false;
  mobileSize = window.innerWidth <= 991 && window.innerWidth > 767

  public regionTour: GuidedTour = {
    tourId: 'region-tour',
    useOrb: false,
    steps: [
      {
        title: 'Select your Region',
        selector: window.innerWidth <= 767 ? '.wx-btn-group-toggle' : '#usa-map',
        content: 'This selection will filter the standards and products that are available for your region.',
        orientation: Orientation.Top
      },
      {
        title: 'Open Region Settings',
        selector: '#globe-icon',
        content: 'Change your region at any time by selecting the globe icon located at the top of the screen.',
        orientation: Orientation.BottomRight
      },
      {
        title: 'Explore Westex',
        selector: window.innerWidth <= 767 ? '#region-explore-mobile' : '#region-explore',
        content: 'Select Explore Westex to apply your newly selected region.',
        orientation: window.innerWidth <= 991 ? Orientation.Bottom : Orientation.Top
      }
    ],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };

  constructor(private settingsService:AppSettingsService, private regionFilters:RegionFilterService, private guidedTourService: GuidedTourService, private tourService: TourService) { }

  ngOnInit(): void {
    this.activeRegion$ = this.settingsService.appSettings$.pipe(
      map(settings => settings.region)
    );

    this.activeRegionMapSrc$ = this.settingsService.appSettings$.pipe(
      map(settings => { 
        switch(settings.region) {
          case 1:
            return 'assets/images/map/map_northAmerica_full.png'
          case 3:
            return 'assets/images/map/map_southEastAsia_full.png'
          case 4:
            return 'assets/images/map/map_china_full.png'
          case 5:
            return 'assets/images/map/map_asia_full.png'
          case 6:
            return 'assets/images/map/map_southAmerica_full.png'
          case 7:
            return 'assets/images/map/map_southAsia_full.png'
        }
      })
    );

    this.regions$ = this.settingsService.getAllRegions().pipe(
      map(regions => regions.filter(r => r.is_app_visible === true))
    );

    this.managers$ = this.settingsService.getAllManagers().pipe(
      map(managers => {
        let sortedByManager = (managers ?? []).sort((a, b) => a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0);

        for(let manager of sortedByManager) {
          manager.regions = (manager.regions ?? []).sort((a, b) => a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0) 
        }

        return sortedByManager;
      })
    );

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-light');
    const containerBg = document.getElementById('containerBg');
    containerBg.classList.add('rounded-corners-container');
    containerBg.style.backgroundColor = "#08003D";
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.add('rounded-corners-body');

    this.tourIsComplete = this.tourService.getRegionsTourComplete();
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-light');
    const containerBg = document.getElementById('containerBg');
    containerBg.classList.remove('rounded-corners-container');
    containerBg.style.backgroundColor = "#ffffff";
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.remove('rounded-corners-body');
  }

  toggleActiveRegion(regionId:number){
    this.regionFilters.reset();
    this.regionFilters.add(regionId);
    this.settingsService.toggleActiveRegion(regionId);
  }

  toggleNARegion(){
    this.regionFilters.reset();
    this.regionFilters.add(1);
    this.regionFilters.add(2);
  }


  toggleMapRegion(){

  }

  toggleActiveLanguage(langId:number){
    //this.settingsService.toggleActiveLanguage(langId);
  }

  public startTour(): void {
    if (!this.tourIsComplete && !this.tourIsLoaded) {
      this.tourIsLoaded = true;
      setTimeout(() => {
        this.guidedTourService.startTour(this.regionTour);
      }, 100);
    }
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.regionTour);
  }

  private tourComplete(): void {
    this.tourService.setRegionsTourComplete(true);
  }
}
