import { Component, ElementRef, HostListener, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GarmentService } from 'src/services/garment.service';
import { environment } from 'src/environments/environment';
import { GarmentPairing } from '../models/garment-pairing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { SpecSheetService } from 'src/services/spec-sheet.service';
import { AppSettingsService } from 'src/services/app-settings.service';
import { StyleDetails } from '../models/style.model';
import { ProductLineService } from 'src/services/product-line.service';
import { StandardDetails } from '../models/standard.model';
import { StandardService } from 'src/services/standard.service';

@Component({
  selector: 'app-resource-sidebar',
  templateUrl: './resource-sidebar.component.html',
  styleUrls: ['./resource-sidebar.component.scss']
})
export class ResourceSidebarComponent implements OnInit {

  activeStandard$: Observable<StandardDetails>;
  baseUrl = environment.apiUrl;

  constructor(private route: ActivatedRoute, private standardService: StandardService) { }

  ngOnInit(): void {
    var activeStandardId;
    this.standardService.activeStandardId$.subscribe(num => activeStandardId = num);
    this.activeStandard$ = this.standardService.get(activeStandardId);
  }
}
