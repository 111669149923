import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GarmentService } from 'src/services/garment.service';
import { ProductLineService } from 'src/services/product-line.service';
import { GarmentPairing } from '../models/garment-pairing.model';
import { GarmentDetails } from '../models/garment.model';
import { StyleDetails } from '../models/style.model';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-uniform-modal',
  templateUrl: './uniform-modal.component.html',
  styleUrls: ['./uniform-modal.component.scss']
})
export class UniformModalComponent implements OnInit {

  garmentPairing:GarmentPairing
  baseUrl: string;

  chevronUpIcon = faChevronUp;

  constructor(private garmentService:GarmentService, private productLineService:ProductLineService, private router:Router, private bsModal:BsModalRef) { }

  ngOnInit(): void {
    this.baseUrl = `${environment.apiUrl}`;
  }

  getStyleDetails(styleId: number): Observable<StyleDetails> {
    return this.productLineService.getStyle(styleId);
  }

  getGarmentDetails(garmentId: number): Observable<GarmentDetails> {
    return this.garmentService.get(garmentId);
  }

  exploreInCatalog(styleId: number){
    this.bsModal.hide();
    this.garmentService.setSelectedGarmentId(-1);
    this.router.navigate(['/product-details', styleId])
  }

}
