<ngx-guided-tour></ngx-guided-tour>

<section class="mb-6">
  <h1 class="section-title">Regions</h1>
</section>

<section class="container d-block d-md-none">
  <div class="row">
    <div class="col-lg mb-4 mb-lg-0 d-flex flex-column align-items-center d-md-inline">
        <h2 class="h5 text-primary">Select Region</h2>
        <div *ngFor="let region of (regions$ | async)" class="wx-btn-group-toggle" (click)="toggleActiveRegion(region.id)" style="width: 234px;">
            {{startTour()}}
            <input type="radio" name="regions[{{region.id - 1}}]" [checked]="(this.activeRegion$ | async) == region.id">
            <label class="btn btn-round">
                <img *ngIf="region.flag" [src]="baseUrl + region.flag.url" class="my-3">
                <span>{{region.name}}</span>
            </label>
        </div>

    </div>
    <div class="col-lg mb-4 mb-lg-0 d-flex flex-column align-items-center d-md-inline">
        <h2 class="h5 text-primary">Select Language</h2>
        <div class="wx-btn-group-toggle">
            <input type="radio" name="language" id="language1" checked disabled>
            <label class="btn btn-round" for="language1">
                <span>English</span>
            </label>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="d-flex flex-fill justify-content-center">
      <button role="button" id="region-explore-mobile" class="btn btn-accent wx-details-button" [routerLink]="['/dashboard']" >Explore Westex</button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="d-flex flex-fill justify-content-center">
      <button role="button" class="btn" (click)="showTerms = true" style="text-decoration: underline;">Product Disclaimer</button>
    </div>
  </div>
</section>

<section id="region-map" class="d-none d-md-block">
  <div class="row justify-content-center">
    <div class="d-flex justify-content-center map-container" style="height: 550px;">
      <img [src]="(activeRegionMapSrc$ | async)" usemap="#workmap" class="overlay">
    </div>

      <map name="workmap">
        <area shape="rect" coords="40,70, 130,150" alt="U.S."  (click)="toggleActiveRegion(1)" style="cursor: pointer;">
        <area shape="poly" coords="152,146, 226,159, 269,177, 286,164, 288,171, 302,244, 166,208, 152,146" alt="U.S."  (click)="toggleActiveRegion(1)" style="cursor: pointer;">
        <area shape="poly" coords="114,82, 260,16, 432,24, 428,80, 288,164, 236,156, 158,158, 114,121, 114,82" alt="Canada"  (click)="toggleActiveRegion(1)" style="cursor: pointer;">
        <area shape="rect" coords="760,380, 1040,500" alt="Australia/New Zealand"  (click)="toggleActiveRegion(3)" style="cursor: pointer;">
        <area shape="poly" coords="870,230, 950,170, 950,250, 920,230" alt="Australia/New Zealand"  (click)="toggleActiveRegion(3)" style="cursor: pointer;">
        <area shape="rect" coords="667,182, 890,250" alt="China"  (click)="toggleActiveRegion(4)" style="cursor: pointer;">
        <area shape="rect" coords="450,100, 480,120" alt="Europe"  (click)="toggleActiveRegion(5)" style="cursor: pointer;">
        <area shape="rect" coords="510,10, 1040,110" alt="Europe"  (click)="toggleActiveRegion(5)" style="cursor: pointer;">
        <area shape="poly" coords="728,238, 586,500, 444,188, 515,64, 718,20, 940,96, 798,140, 638,140, 638,220, 728,238" alt="Europe" (click)="toggleActiveRegion(5)" style="cursor: pointer;">
        <area shape="rect" coords="260,260, 400,500" alt="Latin America"  (click)="toggleActiveRegion(6)" style="cursor: pointer;">
        <area shape="rect" coords="150,210, 260,280" alt="Latin America"  (click)="toggleActiveRegion(6)" style="cursor: pointer;">
        <area shape="rect" coords="690,230, 765,320" alt="SouthEast Asia/India"  (click)="toggleActiveRegion(7)" style="cursor: pointer;">
        <area shape="poly" coords="780,310, 850,310, 910,330, 910,360, 760,370, 780,310" alt="SouthEast Asia/India"  (click)="toggleActiveRegion(7)" style="cursor: pointer;">
        <area shape="poly" coords="810,300, 820,290, 810,270, 770,240, 750,290, 810,300" alt="SouthEast Asia/India"  (click)="toggleActiveRegion(7)" style="cursor: pointer;">
      </map>

  </div>
</section>
<section class="d-flex justify-content-center no-gutters">
  <div class="d-none d-lg-flex flex-column mb-3">
    <div class="d-flex flex-row mb-3">
      
      <ng-container *ngFor="let manager of (managers$ | async)">
        <div 
          class="col-3 pt-2" 
          *ngIf="manager.regions[0]?.is_app_visible"
          [class.bg-region-selected]="(this.activeRegion$ | async) == manager.regions[0]?.id"
          (click)="toggleActiveRegion(manager.regions[0]?.id)" style="cursor: pointer;"
        >
          <div class="region-header mb-1 d-flex justify-content-between align-items-center" 
            [style.background-color]="manager.regions[0]?.color"  
            [style.color]="manager.regions[0]?.text_color"
          >
            <div class="py-1 px-2">{{manager.regions[0]?.name}}</div>
            <div class="h-100 d-flex align-items-center justify-content-end">
              <ng-container *ngFor="let region of manager.regions">
                <div class="color-square" *ngIf="region.id != manager.regions[0]?.id && region.is_app_visible" [style.background-color]="region.color" >              
                </div>
              </ng-container>
            </div>
          </div>
          <div class="pl-2"><strong>{{manager.name}} </strong>| {{manager.title}}</div>
          <div class="pl-2">{{manager.email}}</div>
          <div class="pl-2">{{manager.phone}}</div>
        </div>
      </ng-container>

      <!-- 
      <div class="col-3 pt-2" [class.bg-region-selected]="(this.activeRegion$ | async) == 2" (click)="toggleNARegion()" style="cursor: pointer;">
        <div class="py-1 px-2 mb-1 bg-region-color-asia">Europe, Russia, Ukraine & Kazakhstan</div>
        <div class="pl-2"><strong>Jerry Morris </strong>| Market Manager</div>
        <div class="pl-2">jerry.moris@milliken.com</div>
        <div class="pl-2">+39.348.4222.936 </div>
      </div>
      <div class="col-3 pt-2" [class.bg-region-selected]="(this.activeRegion$ | async) == 7" (click)="toggleActiveRegion(7)" style="cursor: pointer;">
        <div class="py-1 px-2 mb-1 bg-region-color-south-east-asia">India & Southeast Asia</div>
        <div class="pl-2"><strong>Dr. Raj Batlaw </strong>| Market Manager</div>
        <div class="pl-2">Raj.batlaw@milliken.com</div>
        <div class="pl-2">+65.6593.1308</div>
      </div>
      <div class="col-3 pt-2" [class.bg-region-selected]="(this.activeRegion$ | async) == 4" (click)="toggleActiveRegion(4)" style="cursor: pointer;">
        <div class="py-1 px-2 mb-1 bg-region-color-china">China</div>
        <div class="pl-2"><strong>Deron Dong</strong>| Market Manager</div>
        <div class="pl-2">deron.dong@milliken.com</div>
        <div class="pl-2">Office: +86.181.1727.6118</div>
      </div>
    </div>
    <div class="d-flex flex-row mb-3">
      <div class="col-3 pt-2" [class.bg-region-selected]="(this.activeRegion$ | async) == 1 || (this.activeRegion$ | async) == 2" (click)="toggleNARegion()" style="cursor: pointer;">
        <div class="py-1 px-2 mb-1 bg-region-color-na">North America Director</div>
        <div class="pl-2"><strong>Tom Moore</strong>| N.A. Sales Director</div>
        <div class="pl-2">tom.moore@milliken.com</div>
        <div class="pl-2">+1.225.218.5408</div>
      </div>
      <div class="col-3 pt-2" [class.bg-region-selected]="(this.activeRegion$ | async) == 6" (click)="toggleActiveRegion(6)" style="cursor: pointer;">
        <div class="py-1 px-2 mb-1 bg-region-color-mexico">Mexico</div>
        <div class="pl-2"><strong>Jorge Gonzalez</strong>| Market Manager</div>
        <div class="pl-2">jorge.gonzalez@milliken.com</div>
        <div class="pl-2">+52.44.2265.5730</div>
      </div>
      <div class="col-3 pt-2" [class.bg-region-selected]="(this.activeRegion$ | async) == 6" (click)="toggleActiveRegion(6)" style="cursor: pointer;">
        <div class="py-1 px-2 mb-1 bg-region-color-south-america">Latin America</div>
        <div class="pl-2"><strong>Maria do Carmo Chies</strong>| Market Manager</div>
        <div class="pl-2">maria.chies@milliken.com</div>
        <div class="pl-2">+55.11.99215.9626</div>
      </div>
      <div class="col-3 pt-2" [class.bg-region-selected]="(this.activeRegion$ | async) == 3" (click)="toggleActiveRegion(3)" style="cursor: pointer;">
        <div class="py-1 px-2 mb-1 bg-region-color-south-asia">Australia & New Zealand</div>
        <div class="pl-2"><strong>Andrew Antala</strong>| Market Manager</div>
        <div class="pl-2">andrew.antala@milliken.com</div>
        <div class="pl-2">+61.457.074.274</div>
      </div> -->
    </div>
  </div>
</section>
<section>
  <div>
    <div class="row">
      <div class="d-flex flex-fill justify-content-center" [class.mb-tour]="tourIsLoaded && !tourIsComplete && mobileSize">
        <button role="button" id="region-explore" class="btn btn-accent wx-details-button" [routerLink]="['/dashboard']" >Explore Westex</button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="d-flex flex-fill justify-content-center">
        <button role="button" class="btn" (click)="showTerms = true" style="text-decoration: underline;">Product Disclaimer</button>
      </div>
    </div>
  </div>
</section>

<p-dialog header="Product Disclaimer" [(visible)]="showTerms" [modal]="true" styleClass="dialog-modal">
  <div class="col">
    <p>
      PLEASE NOTE: AS EACH CUSTOMER’S USE OF OUR PRODUCT MAY BE DIFFERENT,
      INFORMATION WE PROVIDE, INCLUDING WITHOUT LIMITATION, TEST RESULTS, RECOMMENDATIONS, SAMPLES, CARE/LABELING/PROCESSING INSTRUCTIONS OR MARKETING ADVICE,
      IS PROVIDED IN GOOD FAITH BUT WITHOUT WARRANTY AND WITHOUT ACCEPTING ANY RESPONSIBILITY/LIABILITY.
      ANY TEST RESULTS REPORTED ARE BASED ON STANDARD LABORATORY TESTING BUT SHOULD NOT BE USED TO PREDICT PERFORMANCE IN ACTUAL FIRE SITUATIONS.
      EACH CUSTOMER MUST TEST AND BE RESPONSIBLE FOR THEIR OWN SPECIFIC USE, FURTHER PROCESSING, LABELING, MARKETING, ETC.
      ALL SALES ARE EXCLUSIVELY SUBJECT TO OUR STANDARD TERMS OF SALE POSTED AT <a href="https://www.milliken.com/terms" target="_blank">www.milliken.com/terms</a> (ALL ADDITIONAL/ DIFFERENT TERMS ARE REJECTED) UNLESS EXPLICITLY AGREED OTHERWISE IN A SIGNED WRITING.
      USE CAUTION NEAR SOURCES OF FLAME OR INTENSE HEAT, AND FOLLOW THE CARE INSTRUCTIONS – DO NOT LAUNDER WITH BLEACH OR FABRIC SOFTENERS.
      ANY FR LIFETIME GUARANTEES ARE SUBJECT TO THE LIMITATIONS AND CONDITIONS IN THE FORGOING TERMS OF SALE AND CARE INSTRUCTIONS.
      Westex, Milliken, Indura, UltraSoft, UltraSoft AC, TrueComfort, Vinex, DH Air, Synergy, Indigo, AllOut, InsulAir and CXP are trademarks of Milliken & Company.
      Teijin is a trademark of Teijin Limited. Lenzing Lyocell is a trademark of Lenzing Group. Nomex and Kevlar are trademarks of E. I. du Pont de Nemours and Company.
    </p>
  </div>
</p-dialog>
