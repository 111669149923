import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/services/app-settings.service';
import { RegionFilterService } from 'src/services/region-filter.service';
import { Region, RegionDetails } from '../models/region.model';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  constructor(private tourService: TourService, private messageService: MessageService) { }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-light');
    const containerBg = document.getElementById('containerBg');
    containerBg.classList.add('rounded-corners-container');
    containerBg.style.backgroundColor = "#08003D";
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.add('rounded-corners-body');
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-light');
    const containerBg = document.getElementById('containerBg');
    containerBg.classList.remove('rounded-corners-container');
    containerBg.style.backgroundColor = "#ffffff";
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.remove('rounded-corners-body');
  }

  resetTours() {
    this.tourService.setCatalogTourComplete(false);
    this.tourService.setHazardDetailsTourComplete(false);
    this.tourService.setHazardsTourComplete(false);
    this.tourService.setProductDetailsTourComplete(false);
    this.tourService.setProductLineExpandedTourComplete(false);
    this.tourService.setProductLinesTourComplete(false);
    this.tourService.setRegionsTourComplete(false);
    this.tourService.setSpecSheetDetailsTourComplete(false);
    this.tourService.setStandardDetailsTourComplete(false);
    this.tourService.setStandardsTourComplete(false);
    this.tourService.setUniformBuilderTourComplete(false);
    this.messageService.add({severity:'success', summary: 'Success', detail: 'The feature tours were reset.'});
  }
}
