import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { GarmentService } from 'src/services/garment.service';
import { environment } from 'src/environments/environment';
import { GarmentPairing } from '../models/garment-pairing.model';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { SpecSheetService } from 'src/services/spec-sheet.service';
import { ConfirmationService } from 'primeng/api';
import { SpecSheet } from '../models/spec-sheet.model';
import jsPDF from 'jspdf';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { StandardService } from 'src/services/standard.service';
import { ProductLineService } from 'src/services/product-line.service';
import { StyleDetails } from '../models/style.model';
import { GarmentDetails } from '../models/garment.model';
import { ConfirmDialog } from 'primeng/confirmdialog';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-spec-sheet-details',
  templateUrl: './spec-sheet-details.component.html',
  styleUrls: ['./spec-sheet-details.component.scss'],
  providers: [ConfirmationService]
})
export class SpecSheetDetailsComponent implements OnInit {

  @ViewChild('cd') dialog:ConfirmDialog;

  pairedGarments$: Observable<GarmentPairing[]>;
  baseUrl$: string;
  selectedGarmentId$: Observable<number>;
  activeSpecSheet$: Observable<SpecSheet>;
  garmentPairingsSaved: Observable<boolean>;
  private tourIsComplete: boolean;

  public specSheetDetailsTour: GuidedTour = {
    tourId: 'spec-sheet-details-tour',
    useOrb: false,
    steps: [
        {
          title: 'Name your Spec Sheet',
          selector: '#sheetNameInput',
          content: 'Type in the text field to customize the name of your uniform spec.',
          orientation: Orientation.Bottom
        },
        {
          title: 'Download',
          selector: '.ssd-download',
          content: 'Generates a PDF output of your saved uniform selections into a comprehensive summary.',
          orientation: Orientation.BottomLeft
        },
        {
          title: 'Swatches',
          selector: '#spec-sheet-swatches',
          content: 'View your list of product styles and their properties for a saved spec sheet.',
          orientation: Orientation.Top
        }
    ],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };

  @ViewChild('pdfContent', {static: false}) pdfContent: ElementRef;

  public showShareDialog: boolean = false;
  public sharingInProgress: boolean = false;

  shareForm: FormGroup = this.formBuilder.group({
    shareEmail: ['', [Validators.required, Validators.email]],
    captcha: ['', [Validators.required]]
  });

  get shareEmail() { return this.shareForm.get('shareEmail'); }
  get captcha() { return this.shareForm.get('captcha'); }

  siteKey: string = environment.recaptchaSiteKey;

  constructor(
    private garmentService: GarmentService,
    private specSheetService: SpecSheetService,
    private confirmationService: ConfirmationService,
    private productLineService: ProductLineService,
    private guidedTourService: GuidedTourService,
    private tourService: TourService,
    private messageService: MessageService, 
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.pairedGarments$ = this.garmentService.garmentsPairings$;

    this.pairedGarments$.subscribe(pgs => this.specSheetService.areGarmentPairingsSaved(pgs));
    this.garmentPairingsSaved = this.specSheetService.areGarmentPairingsSaved$;

    this.activeSpecSheet$ = this.specSheetService.activeSpecSheet$.pipe();

    this.baseUrl$ = `${environment.apiUrl}`;

    this.tourIsComplete = this.tourService.getSpecSheetDetailsTourComplete()
  }

  ngAfterViewInit(): void {
    if (!this.tourIsComplete) {
      setTimeout(() => {
        this.guidedTourService.startTour(this.specSheetDetailsTour);
      }, 0);
    }
  }

  saveSpecSheet() {
    combineLatest(this.specSheetService.activeSpecSheet$, this.garmentService.garmentsPairings$).pipe(
      //take(1)
    ).subscribe(
      ([specSheet, pairedGarments]) => {
        var sheetName = (document.getElementById("sheetNameInput") as HTMLInputElement).value != "" ? (document.getElementById("sheetNameInput") as HTMLInputElement)?.value : specSheet.name;
        if (sheetName != specSheet.name) {
          var newSheetName = sheetName;
          var count = 0;

          while(this.specSheetService.getAllSpecSheets().some(specSheet => specSheet.name == newSheetName)){
            count++;
            newSheetName = sheetName +  " (" + count + ")";
          }

          sheetName = newSheetName;
        }

        this.confirmationService.confirm({
          message: "<div class='modal-inner-header'>" + sheetName + "</div>Would you like to save '" + sheetName + "' to My Sheets?",
          header: "Add to My Sheets",
          accept: () => {
            specSheet.name = sheetName;
            specSheet.garmentPairings = pairedGarments;
            this.specSheetService.updateSpecSheet(specSheet);
            this.pairedGarments$.subscribe(pgs => this.specSheetService.areGarmentPairingsSaved(pgs));
          }
        });
      });
  }

  public async downloadAsPDF() {
    const doc = new jsPDF();

    pdfMake.fonts = {
      Comfortaa: {
        normal: 'Comfortaa-Regular.ttf',
        bold: 'Comfortaa-Bold.ttf'
      }
    }

    var table = {
      layout: 'noBorders',
      table: {
        heights: 85,
        widths: [85, "*"],
        body: []
      }
    }

    this.activeSpecSheet$.pipe(
        take(1)
      ).subscribe(
      async activeSheet => {
        // for more information: https://pdfmake.github.io/docs/0.1/
        const documentDefinition = {
          content: [
            {text: activeSheet.name, alignment: "center", fontSize: 24, bold: true},
            htmlToPdfmake("<hr />")
          ],
          defaultStyle: {
            font: 'Comfortaa'
          }
        };

        var pairedGarments;
        this.pairedGarments$.subscribe(pgs => pairedGarments = pgs);

        if (activeSheet.garmentPairings.length > 0) {
          for(let garmentPairing of pairedGarments){
            var style: StyleDetails;
            this.productLineService.getStyle(garmentPairing.styleId).subscribe(s => style = s);
            var garment: GarmentDetails;
            this.garmentService.get(garmentPairing.garmentId).subscribe(g => garment = g);
            
            var row = []
            row.push({image: await this.getBase64Image(environment.apiUrl + style.image.url), width: 75, height: 75});
            row.push(htmlToPdfmake("<strong>" + style.name + " | Paired with " + garment.name + "</strong><p>" + style.description + "</p><br />"));
            table.table.body.push(row);
          }

          documentDefinition.content.push(table);
        } else {
          documentDefinition.content.push({text: "No swatches have been added to this spec sheet", alignment: "center"});
        }

        pdfMake.createPdf(documentDefinition).download(activeSheet.name);
      }
    )
  }

  public openShareDialog() {
    this.showShareDialog = true;
  }

  public shareActiveSpecSheet() {
    if(this.shareEmail.invalid) {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please enter a valid email.'})
      return;
    }

    if(this.captcha.invalid) {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Please complete the reCAPTCHA.'})
      return;
    }

    this.sharingInProgress = true;
    this.specSheetService.shareSpecSheet(this.shareEmail.value, this.captcha.value).subscribe(
      () => {
        this.showShareDialog = false; 
        this.sharingInProgress = false;
        this.shareEmail.setValue('');
      },
      (err) => { 
        console.error(err);
        this.sharingInProgress = false;
      },
      () => { 
        this.sharingInProgress = false;
      }
    )

  }

  public downloadActiveSpecSheet() {
    this.specSheetService.downloadSpecSheet().subscribe(
      (download) => { this.specSheetService.exportPdf(download.blob, download.specSheet.name)},
      (error) => { console.log(error) }
    )
  }

  getBase64Image(imgUrl): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        var img = document.createElement("img") as HTMLImageElement;
        img.crossOrigin = 'Anonymous';
        img.src = imgUrl;

        img.onload = (e) => {
          // Create an empty canvas element
          var canvas = document.createElement("canvas");
          canvas.width = 100;
          canvas.height = 100;

          // Copy the image contents to the canvas
          var ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0);

          // Get the data-URL formatted image
          // Firefox supports PNG and JPEG. You could check img.src to
          // guess the original format, but be aware the using "image/jpg"
          // will re-encode the image.
          var dataURL = canvas.toDataURL("image/png");

          return resolve(dataURL);
        };
      } catch(err) {
        reject(err);
      }
    });
  }

  toggleSelectedGarment(garmentId: number, styleId: number){
    this.garmentService.toggleGarmentPairing(garmentId, styleId);

    combineLatest(this.specSheetService.activeSpecSheet$, this.garmentService.garmentsPairings$).pipe(
      take(1)
    ).subscribe(
      ([specSheet, pairedGarments]) => { 
        specSheet.garmentPairings = pairedGarments;
        this.pairedGarments$.subscribe(pgs => this.specSheetService.areGarmentPairingsSaved(pgs));
      }
    );
  }

  getStyleDetails(styleId: number): Observable<StyleDetails> {
    return this.productLineService.getStyle(styleId);
  }

  getGarmentDetails(garmentId: number): Observable<GarmentDetails> {
    return this.garmentService.get(garmentId);
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if (this.dialog.visible && event.key == "Enter"){
      this.dialog.accept();
    } else if (this.dialog.visible && event.key == "Escape"){
      this.dialog.reject();
    }
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.specSheetDetailsTour);
  }

  private tourComplete(): void {
    this.tourService.setSpecSheetDetailsTourComplete(true);
  }

}
