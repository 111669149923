import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductLineService } from 'src/services/product-line.service';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { GarmentService } from 'src/services/garment.service';
import { AppSettingsService } from 'src/services/app-settings.service';
import { StyleDetails } from '../models/style.model';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';
import { ShoppingCartService } from 'src/services/shopping-cart.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  activeProduct$: Observable<StyleDetails>;
  activeRegion$: Observable<number>;
  responsiveOptions;
  baseUrl$:string;
  numProductsShown: number;
  horSidebar$: boolean;
  showStandardDescriptions$: boolean
  private tourIsComplete: boolean;
  private tourIsLoaded: boolean = false;
  
  private resourcesStep = {
    title: 'Resources',
    selector: '#product-details-resources',
    content: 'Find information related to a Standard specific criteria, recommended applications and safety specifications.',
    orientation: Orientation.Top
  }

  private mobileSteps = [
    {
      title: 'Garment Area',
      selector: '.uniform-hor-sidebar',
      content: 'Tap a garment area to apply this product to your mannequin on the Uniform Builder page.',
      orientation: window.innerWidth <= 767 ? Orientation.Top : Orientation.Left
    },
    this.resourcesStep
  ]
  
  private fullSteps = [
    this.resourcesStep,
    {
      title: 'Uniform Builder Panel',
      selector: '.uniform',
      content: 'Select a garment area to apply this product to your mannequin on the Uniform Builder page.',
      orientation: Orientation.Left
    }
  ]

  public productDetailsTour: GuidedTour = {
    tourId: 'product-details-tour',
    useOrb: false,
    steps: [],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };

  constructor(
    private productLineService: ProductLineService,
    private route: ActivatedRoute,
    private garmentService: GarmentService,
    private appSettingsService: AppSettingsService,
    private guidedTourService: GuidedTourService,
    private tourService: TourService,
    private shoppingCartService: ShoppingCartService
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1490px',
        numVisible: 4,
        numScroll: 4
      },
      {
        breakpoint: '1320px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '991px',
        numVisible: 5,
        numScroll: 5
      },
      {
        breakpoint: '850px',
        numVisible: 4,
        numScroll: 4
      },
      {
        breakpoint: '650px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '500px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '300px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.activeProduct$ = this.route.params.pipe(
      map(params => params['productId']),
      switchMap(productId => this.productLineService.getStyle(productId))
    );

    this.activeRegion$ = this.appSettingsService.appSettings$.pipe(
      map(appSettings => appSettings.region)
    );

    this.baseUrl$ = `${environment.apiUrl}`;

    this.garmentService.resetGarmentSelectorComponents();
    
    this.setNumProductsShown();

    this.horSidebar$ = window.innerWidth < 992;

    this.tourIsComplete = this.tourService.getProductDetailsTourComplete()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setNumProductsShown();
    this.horSidebar$ = window.innerWidth < 992;
  }

  setNumProductsShown() {
    if (window.innerWidth <= 300) {
      this.numProductsShown = 1;
    } else if (window.innerWidth <= 500) {
      this.numProductsShown = 2;
    } else if (window.innerWidth <= 650 || (window.innerWidth > 991 && window.innerWidth <= 1320)) {
      this.numProductsShown = 3;
    } else if (window.innerWidth <= 850 || (window.innerWidth > 1320 && window.innerWidth <= 1490)) {
      this.numProductsShown = 4;
    } else {
      this.numProductsShown = 5;
    }
  }

  public startTour(): void {
    if (!this.tourIsComplete && !this.tourIsLoaded) {
      this.tourIsLoaded = true;

      if (window.innerWidth <= 991) {
        this.productDetailsTour.steps = this.mobileSteps;
      } else {
        this.productDetailsTour.steps = this.fullSteps;
      }

      setTimeout(() => {
        this.guidedTourService.startTour(this.productDetailsTour);
      }, 300);
    }
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.productDetailsTour);
  }

  private tourComplete(): void {
    this.tourService.setProductDetailsTourComplete(true);
  }

  swatchInCart(swatch: StyleDetails): Observable<boolean> {
    return this.shoppingCartService.cartIncludesSwatch(swatch);
  }

  addToCart(swatch: StyleDetails) {
    this.shoppingCartService.addToCart(swatch);
  }

  removeFromCart(swatch: StyleDetails) {
    this.shoppingCartService.removeFromCart(swatch);
  }
}
