<section class="mb-6">
  <h1 class="section-title">Contact Sales</h1>
</section>

<section *ngIf="region$ | async as activeRegion" class="d-flex mt-3 col mx-auto wx-form narrow">
  <div *ngFor="let manager of activeRegion.managers" class="d-flex flex-row flex-fill sales-contact-container">
      <div *ngIf="manager.profile as image;else elseBlock" class="d-flex sales-contact-image"
      [ngStyle]="{backgroundImage:'url('+ baseUrl + image.url +')', backgroundSize: 'cover'}">
    </div>
      <ng-template #elseBlock>
        <div class="d-flex sales-contact-image">
          <h3 class="m-0">{{ manager.initials }}</h3>
        </div>
      </ng-template>
    <div class="d-flex flex-column sales-contact">
      <p class="text-uppercase"><strong>{{ manager.name }}</strong></p>
      <p>{{ manager.title }}</p>
      <p>{{ manager.email }}</p>
      <p>{{ manager.phone }}</p>
    </div>
  </div>
</section>
<section class="d-flex mt-5 mb-3 col-md-8 mx-auto justify-content-center wx-form narrow">
  <p style="width: 90%">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    Ut enim ad minim veniam, quis nostrud exercitation ullamco
    laboris nisi ut aliquip ex ea commodo consequat.
  </p>
</section>
<section class="d-flex">
    <form class="mx-auto col-md-8 wx-form narrow primary" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label class="label" for="name">Name</label>
            <input type="text" formControlName="name" class="wx-form-control" id="name">
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <label class="label" for="company">Company</label>
            <input type="text" formControlName="company" class="wx-form-control" id="company">
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
              <div *ngIf="f.company.errors.required">Company is required</div>
            </div>
        </div>
        <div class="form-group">
            <label class="label" for="email">Email</label>
            <input type="email" formControlName="email" class="wx-form-control" id="email">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        <div class="form-group">
            <label class="label" for="message">Message</label>
            <textarea class="wx-form-control" formControlName="message" id="message" rows="3"></textarea>
            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
              <div *ngIf="f.message.errors.required">Message is required</div>
            </div>
        </div>
        <div class="mt-3">
          <re-captcha formControlName="captcha" required siteKey="{{ siteKey }}"></re-captcha>
        </div>

        <button type="submit" class="btn btn-accent btn-block mt-3">Send</button>
    </form>
</section>
