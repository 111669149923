import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, pipe } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { StandardDetails } from 'src/app/models/standard.model';

@Injectable({
  providedIn: 'root'
})
export class StandardService {

  private activeStandardsSubject$ = new BehaviorSubject<number[]>([]);
  public activeStandards$ = this.activeStandardsSubject$.asObservable();

  private selectedStandardsSubject$ = new BehaviorSubject<number[]>([]);
  public selectedStandards$ = this.selectedStandardsSubject$.asObservable();

  private activeStandardIdSubject$ = new BehaviorSubject<number>(null);
  public activeStandardId$ = this.activeStandardIdSubject$.asObservable();

  private standards$: Observable<StandardDetails[]>;

  constructor(private http: HttpClient) { }

  getAllStandards() : Observable<StandardDetails[]>{
    if(!this.standards$) {
      this.standards$ = this.http.get<StandardDetails[]>(`${environment.apiUrl}` + "/standards").pipe(
        shareReplay(1)
      );
    }

    return this.standards$;
  }

  get(standardId: number) : Observable<StandardDetails>{
    return this.getAllStandards().pipe(
      map(standards => standards.find(standard => standard.id == standardId))
    );
  }

  setActiveStandardDetails(standardId: number) {
    this.activeStandardIdSubject$.next(standardId);
  }

  toggleActiveStandard(standardId: number) {
    let arr = [...this.activeStandardsSubject$.value];
    if (arr.includes(standardId) && arr.length > 0){
        let i = arr.findIndex( val => val == standardId);
        arr.splice(i, 1);
    }else{
        arr.push(standardId);
    }

    this.activeStandardsSubject$.next(arr);

    this.addSelectedStandard(standardId);
  }

  addSelectedStandard(standardId: number) {
    let arr = [...this.selectedStandardsSubject$.value];
    if (!arr.includes(standardId)){
        arr.push(standardId);
    }

    this.selectedStandardsSubject$.next(arr);
  }

  addActiveStandard(standardId: number){
    let arr = [...this.activeStandardsSubject$.value];
    if (!arr.includes(standardId)){
      arr.push(standardId)
    }

    this.activeStandardsSubject$.next(arr);
  }

}
