import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SpecSheet } from 'src/app/models/spec-sheet.model';
import { Subject } from 'rxjs/internal/Subject';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { GarmentPairing } from 'src/app/models/garment-pairing.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GarmentPairingFull } from 'src/app/models/garment-pairing-full';

interface SpecSheetDownload {
    blob: Blob;
    specSheet: SpecSheet
}

@Injectable({
  providedIn: 'root'
})
export class SpecSheetService {

  private activeSpecSheetIdSubject$ = new Subject<string>();
  public activeSpecSheetId$ = this.activeSpecSheetIdSubject$.asObservable();

  public activeSpecSheetIdString$ = "";
  private activeSpecSheetSubject$ = new BehaviorSubject<SpecSheet>(null);
  public activeSpecSheet$ = this.activeSpecSheetSubject$.asObservable();

  private specSheetsSubject$ = new BehaviorSubject<SpecSheet[]>([]);
  public specSheets$ = this.specSheetsSubject$.asObservable();

  private areGarmentPairingsSavedSubject$ = new BehaviorSubject<boolean>(false);
  public areGarmentPairingsSaved$ = this.areGarmentPairingsSavedSubject$.asObservable();

  constructor(private http: HttpClient) {

  }

  setActiveSpecSheetId(specSheetId: string) {
    this.activeSpecSheetIdSubject$.next(specSheetId);
    this.activeSpecSheetIdString$ = specSheetId;
    this.activeSpecSheetSubject$.next(this.get(specSheetId))
  }

  updateSpecSheet(specSheet: SpecSheet){
    let specSheets = this.getAllSpecSheets();
    let i = specSheets.findIndex( x => x.id == specSheet.id);

    if (i == -1) {
      this.addSpecSheet(specSheet);
    } else {
      specSheets[i] = specSheet;

      localStorage.setItem('spec-sheets', JSON.stringify(specSheets));

      this.specSheetsSubject$.next(specSheets);
      this.setActiveSpecSheetId(specSheet.id);
    }
  }

  deleteSpecSheet(specSheetId: string){
    let specSheets = this.getAllSpecSheets();
    let i = specSheets.findIndex( x => x.id == specSheetId);

    if (specSheets.length == i+1) {
      this.setActiveSpecSheetId(specSheets[i-1].id);
    }
    else{
      this.setActiveSpecSheetId(specSheets[i+1].id);
    }

    specSheets.splice(i, 1);

    localStorage.setItem('spec-sheets', JSON.stringify(specSheets));

    this.specSheetsSubject$.next(specSheets);
  }

  addSpecSheet(specSheet:SpecSheet){
    let specSheets = this.getAllSpecSheets();
    specSheets.push(specSheet);

    localStorage.setItem('spec-sheets', JSON.stringify(specSheets));
    this.setActiveSpecSheetId(specSheet.id);
    this.specSheetsSubject$.next(specSheets);
  }

  areGarmentPairingsSaved(garmentPairings:GarmentPairing[]){
    var savedGarmentPairings = this.get(this.activeSpecSheetIdString$).garmentPairings;
    this.areGarmentPairingsSavedSubject$.next(JSON.stringify(garmentPairings.sort(function(a, b){return a.garmentId-b.garmentId})) == JSON.stringify(savedGarmentPairings.sort(function(a, b){return a.garmentId-b.garmentId})));
  }

  getAllSpecSheets() : SpecSheet[]{
    if (localStorage.getItem("spec-sheets") === null) {
      localStorage.setItem('spec-sheets', JSON.stringify([]))
      return [];
    }else{
      return JSON.parse(localStorage.getItem('spec-sheets'));
    }
  }

  get(specSheetId:string) : SpecSheet{
    return this.getAllSpecSheets().find(x => x.id == specSheetId);
  }

  shareSpecSheet(targetEmail: string, captcha: string) {
    if(!targetEmail) {
      return;
    }

    return this.activeSpecSheet$.pipe(
      take(1),
      switchMap(specSheet => this.http.get<void>(`${environment.apiUrl}/spec-sheets/share`, {
        params: {
          garmentPairings: this.serializePairings(specSheet.garmentPairings),
          id: specSheet.id,
          name: specSheet.name,
          targetEmail,   
          captcha        
        },
        // headers: {
        //   responseType: 'application/json'
        // }
      }))
    )
  }



  downloadSpecSheet(): Observable<SpecSheetDownload> {
    let specSheetName = 'Spec Sheet';
    return this.activeSpecSheet$.pipe(
      take(1),
      tap(specSheet => specSheetName = specSheet.name),
      switchMap(specSheet => this.http.get(`${environment.apiUrl}/spec-sheets/download`, {
        params: {          
          garmentPairings: this.serializePairings(specSheet.garmentPairings),
          id: specSheet.id,
          name: specSheetName,
        },
        responseType: 'blob'
      })),
      map(blob => <SpecSheetDownload>({blob, specSheet: this.activeSpecSheetSubject$.value}))
    )
  }

  
  exportPdf(blob: Blob, fileName: string) {
    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api         

    var pdfBlob = new Blob([blob], { type: "application/pdf" });

    const data = window.URL.createObjectURL(pdfBlob);

    let link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  populateGarmentPairings(garmentPairings: GarmentPairing[]): Observable<GarmentPairingFull[]> {
    if(garmentPairings.length === 0) {
      return of([]);
    }
    return this.http.post<GarmentPairingFull[]>(`${environment.apiUrl}/spec-sheets/populate-garment-pairings`, {
      garmentPairings
    });
  }

  private serializePairings(pairings: GarmentPairing[]): string {
    return btoa(pairings.map(p => `garmentId=${p.garmentId}:styleId=${p.styleId}`).join('&'));
  }

}
