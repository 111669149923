import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { SpecSheetService } from 'src/services/spec-sheet.service';
import { ConfirmationService } from 'primeng/api';
import { SpecSheet } from '../models/spec-sheet.model';
import { Router } from '@angular/router';
import { GarmentService } from 'src/services/garment.service';
import { combineLatest } from 'rxjs';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
  selector: 'app-my-spec-sheets',
  templateUrl: './my-spec-sheets.component.html',
  styleUrls: ['./my-spec-sheets.component.scss'],
  providers: [ConfirmationService]
})
export class MySpecSheetsComponent implements OnInit {

  @ViewChild('cd') dialog:ConfirmDialog;
  
  specSheets$: SpecSheet[];

  constructor(private specSheetService: SpecSheetService, private garmentService:GarmentService, private confirmationService: ConfirmationService, private router: Router) { }

  ngOnInit(): void {
    this.specSheets$ = this.specSheetService.getAllSpecSheets();
  }

  deleteSpecSheet(specSheetId: string) {
    var sheet = this.specSheetService.get(specSheetId);

    this.confirmationService.confirm({
      message: "<div class='modal-inner-header'>" + sheet.name + "</div>Are you sure you wish to delete '" + sheet.name + "' from My Sheets?",
      header: "Delete from My Sheets",
      accept: () => {
        this.specSheetService.deleteSpecSheet(specSheetId);
        this.specSheets$ = this.specSheetService.getAllSpecSheets();
      }
    });
  }

  changeActiveSpecSheet(specSheetId: string){
    var areGarmentPairingsSaved;
    this.garmentService.garmentsPairings$.subscribe(pgs => this.specSheetService.areGarmentPairingsSaved(pgs));
    this.specSheetService.areGarmentPairingsSaved$.subscribe(bool => areGarmentPairingsSaved = bool);

    if (specSheetId == this.specSheetService.activeSpecSheetIdString$) {
      this.router.navigateByUrl('/spec-sheet-details');
    } else if (areGarmentPairingsSaved == false){
      this.confirmationService.confirm({
        message: "Your garment pairings have not been saved. Do you wish to continue without saving?",
        header: "Changes Not Saved",
        accept: () => {
          this.setActiveSpecSheet(specSheetId);
        }
      });
    } else {
      let selectedSpecSheet = this.specSheetService.get(specSheetId);
      this.confirmationService.confirm({
        message: `Are you sure you want to load ${selectedSpecSheet.name}?`,
        header: "Load Spec Sheet",
        accept: () => {
          this.setActiveSpecSheet(specSheetId);
        }
      })      
    }
  }

  setActiveSpecSheet(specSheetId: string){
    this.specSheetService.setActiveSpecSheetId(specSheetId);

    // convert old spec sheets to new ones
    var garmentPairings: any = this.specSheetService.get(this.specSheetService.activeSpecSheetIdString$).garmentPairings;
    var oldPairings = garmentPairings.filter(gp => gp.id !== undefined);
    if (oldPairings.length > 0) {
      var newPairings = garmentPairings.filter(gp => gp.id === undefined);
      
      for (let pairing of oldPairings) {
        newPairings.push({
          garmentId: pairing.id,
          styleId: pairing.style.id
        });
      }

      var updatedSpecSheet = this.specSheetService.get(this.specSheetService.activeSpecSheetIdString$);
      updatedSpecSheet.garmentPairings = newPairings;
      this.specSheetService.updateSpecSheet(updatedSpecSheet);
    }

    this.specSheetService.activeSpecSheet$.subscribe(activeSheet => this.garmentService.updateGarmentPairings(activeSheet.garmentPairings));

    this.router.navigateByUrl('/spec-sheet-details');
  }

  addSpecSheetNotification() {
    var areGarmentPairingsSaved;
    this.garmentService.garmentsPairings$.subscribe(pgs => this.specSheetService.areGarmentPairingsSaved(pgs));
    this.specSheetService.areGarmentPairingsSaved$.subscribe(bool => areGarmentPairingsSaved = bool);

    if (areGarmentPairingsSaved == false){
      this.confirmationService.confirm({
        message: "Your garment pairings have not been saved. Do you wish to continue without saving?",
        header: "Changes Not Saved",
        accept: () => {
          this.addSpecSheet();
        }
      });
    } else {
      this.addSpecSheet();
    }
  }

  addSpecSheet(){
    var name = "My New Spec Sheet";
    var count = 0;

    while(this.specSheetService.getAllSpecSheets().some(specSheet => specSheet.name == name)){
      count++;
      name = "My New Spec Sheet (" + count + ")";
    }

    this.specSheetService.addSpecSheet(new SpecSheet(name));

    this.specSheetService.activeSpecSheet$.subscribe(activeSheet => this.garmentService.updateGarmentPairings(activeSheet.garmentPairings));

    this.router.navigateByUrl('/spec-sheet-details');
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if (this.dialog.visible && event.key == "Enter"){
      this.dialog.accept();
    } else if (this.dialog.visible && event.key == "Escape"){
      this.dialog.reject();
    }
  }
}
