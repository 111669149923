<ngx-guided-tour></ngx-guided-tour>

<section class="mb-4 row mx-0">
  <h1 class="section-title justify-content-start">Standards</h1>
</section>

<section class="wx-details">
  <div class="h5 text-border-primary mb-3 pb-3 d-flex align-items-center">
    <button type="button" class="wx-close">
      <span class="wx-close-icon" aria-hidden="true" [routerLink]="['/standards']"></span>
    </button>
  </div>
  <div class="row mb-7">
    <div class="col-md-4">
      <div *ngIf="(activeStandard$ | async) as activeStandard" class="wx-details-img">
        <div *ngIf="activeStandard.image as image; else elseBlock" class="text-center">
          <img [src]="baseUrl + image.url" class="object-fit-cover wx-details-img-element-standards">
        </div>
        <ng-template #elseBlock>
          <img src="https://via.placeholder.com/150" class="object-fit-cover wx-details-img-element-standards">
        </ng-template>
      </div>
    </div>
    <div class="col-md-8 d-flex flex-column justify-content-between">
      <div *ngIf="activeStandard$ | async as activeStandard"
        class="h-100 d-flex flex-column justify-content-between">
        {{startTour()}}
        <div>
          <div class="mb-5">
            <div class="h3 text-black">{{ activeStandard.name }}</div>
            <p>{{ activeStandard.description}}</p>
          </div>
          <div class="mb-5">
            <div *ngIf="activeStandard.hazards.length > 0" class="h6 text-black">Applicable Hazards</div>
            <div class="row">
              <span *ngFor="let hazard of activeStandard.hazards" class="mr-2 d-flex flex-wrap">
                <div *ngIf="hazard.image as image; else elseBlock">
                  <img [src]="baseUrl + image.url" class="applicable-img">
                </div>
                <ng-template #elseBlock>
                  <img src="https://via.placeholder.com/45" class="applicable-img">
                </ng-template>
              </span>
            </div>
          </div>
        </div>
        <button role="button" class="btn btn-primary wx-details-button standard-btn" [disabled]="!(filterStyleHasRecords(activeStandard.id) | async)"
          (click)="toggleActiveStandard(activeStandard.id)" [class.cursor-initial]="!(filterStyleHasRecords(activeStandard.id) | async)">
          {{ !(filterStyleHasRecords(activeStandard.id) | async) ? "N/A" : ((activeStandards$ | async).includes(activeStandard.id) ? 'Remove' : 'Filter Products') }}
        </button>
        <button role="button" id="explore-in-catalog-btn" class="btn btn-accent wx-details-button standard-btn mt-3" [disabled]="!(filterStyleHasRecords(activeStandard.id) | async)"
          [class.cursor-initial]="!(filterStyleHasRecords(activeStandard.id) | async)" [routerLink]="['/product-catalog']" (click)="addActiveStandard(activeStandard.id)"> Explore in Catalog</button>
      </div>
    </div>
  </div>
</section>

<div  id="resources-section">
  <section *ngIf="showResources$">
    <div *ngIf="(activeStandard$ | async)?.videos as resources">
      <div *ngIf="resources.length > 0" class="h5 text-primary">Video Resources</div>
    </div>
    <div *ngIf="(activeStandard$ | async)?.videos as resources">
      <div *ngIf="resources.length > 0">
        <p-carousel [value]="resources" [numVisible]="numProductsShown" [numScroll]="numProductsShown" [circular]="false" [responsiveOptions]="responsiveOptions" [class.hidden-pagination]="numProductsShown >= resources.length">
          <ng-template let-resource pTemplate="item">
            <div class="wx-card-fluid" style="max-width: 170px;">
              <a class="wx-card-inner" [attr.href]="resource.url" target="_blank" [title]="resource.name">
                <div *ngIf="resource.image as image; else elseBlock">
                  <img [src]="baseUrl + image.url" class="wx-card-img">
                </div>
                <ng-template #elseBlock>
                  <img src="https://via.placeholder.com/170x220" class="wx-card-img">
                </ng-template>
                <div class="wx-card-body">
                  <h6 class="wx-card-title-resource">{{ resource.name }}</h6>
                  <p class="card-text">{{ resource.description }}</p>
                </div>
              </a>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </section>

  <section *ngIf="showResources$" class="mt-2">
    <div *ngIf="(activeStandard$ | async)?.resources as resources">
      <div *ngIf="resources.length > 0" class="h5 text-primary">Resources to Download</div>
    </div>
    <div *ngIf="(activeStandard$ | async)?.resources as resources">
      <div *ngIf="resources.length > 0">
        <p-carousel [value]="resources" [numVisible]="numProductsShown" [numScroll]="numProductsShown" [circular]="false" [responsiveOptions]="responsiveOptions" [class.hidden-pagination]="numProductsShown >= resources.length">
          <ng-template let-resource pTemplate="item">
            <div class="wx-card-fluid" style="max-width: 170px;">
              <div class="wx-card-inner">
                <div *ngIf="resource.image as image; else elseBlock">
                  <img [src]="baseUrl + image.url" class="wx-card-img">
                </div>
                <ng-template #elseBlock>
                  <img src="https://via.placeholder.com/170x220" class="wx-card-img">
                </ng-template>
                <div class="wx-card-body">
                  <h6 class="wx-card-title-resource">{{ resource.name }}</h6>
                  <p class="card-text">{{ resource.description }}</p>
                  <a class="btn m-0 w-100 btn-primary btn-sm" [attr.href]="resource.url" target="_blank" [title]="resource.name">Download</a>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </section>
</div>
