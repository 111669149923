<nav class="navbar-bottom navbar-expand navbar-dark bg-primary fixed-bottom" [ngClass]="{'gray': grayBg}">
  <div class="collapse navbar-collapse">
      <ul class="navbar-nav">
          <li class="nav-item" [class.active]="hazardActive" title="Hazards">
            <a class="nav-link" [routerLink]="['/hazards']">
                <span class="icon"><img src="assets/images/hazards.svg"></span>
            </a>
          </li>
          <li class="nav-item" [class.active]="standardActive" title="Standards">
              <a class="nav-link" [routerLink]="['/standards']">
                  <span class="icon"><img src="assets/images/standards.svg"></span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.5 75.77" width="100%">
                  <defs>
                      <clipPath id="navBottomClip" clipPathUnits="objectBoundingBox" transform="scale(0.0045558 0.0131978)">
                          <path transform="rotate(180 109.75 37.885)" d="M109.75,0C47.31.29,67.6,69.54,0,75.37v.4H219.5v-.4C151.9,69.54,172.19.29,109.75,0Z"/>
                      </clipPath>
                  </defs>
              </svg>
              </a>
          </li>
          <li class="nav-item" [class.active]="productLinesActive" title="Product Lines">
              <a class="nav-link" [routerLink]="['/product-lines']">
                  <span class="icon"><img src="assets/images/products.svg"></span>
              </a>
          </li>
          <li class="nav-item" [class.active]="productCatalogActive" title="Product Catalog">
              <a class="nav-link" [routerLink]="['/product-catalog']">
                  <span class="icon"><img src="assets/images/catalog.svg"></span>
              </a>
          </li>
          <li class="nav-item" [class.active]="uniformBuilderActive" title="Uniform Builder">
              <a class="nav-link" [routerLink]="['/uniform-builder']">
                  <span class="icon"><img src="assets/images/uniform.svg"></span>
              </a>
          </li>
      </ul>
  </div>
</nav>
