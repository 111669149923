import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor() { }

  setHazardsTourComplete(isComplete: boolean) {
    localStorage.setItem('hazards-tour-complete', JSON.stringify(isComplete));
  }

  getHazardsTourComplete() : boolean {
    if (localStorage.getItem("hazards-tour-complete") === null) {
      localStorage.setItem('hazards-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('hazards-tour-complete'));
    }
  }

  setRegionsTourComplete(isComplete: boolean) {
    localStorage.setItem('regions-tour-complete', JSON.stringify(isComplete));
  }

  getRegionsTourComplete() : boolean {
    if (localStorage.getItem("regions-tour-complete") === null) {
      localStorage.setItem('regions-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('regions-tour-complete'));
    }
  }

  setHazardDetailsTourComplete(isComplete: boolean) {
    localStorage.setItem('hazards-details-tour-complete', JSON.stringify(isComplete));
  }

  getHazardDetailsTourComplete() : boolean {
    if (localStorage.getItem("hazards-details-tour-complete") === null) {
      localStorage.setItem('hazards-details-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('hazards-details-tour-complete'));
    }
  }

  setStandardsTourComplete(isComplete: boolean) {
    localStorage.setItem('standards-tour-complete', JSON.stringify(isComplete));
  }

  getStandardsTourComplete() : boolean {
    if (localStorage.getItem("standards-tour-complete") === null) {
      localStorage.setItem('standards-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('standards-tour-complete'));
    }
  }

  setStandardDetailsTourComplete(isComplete: boolean) {
    localStorage.setItem('standards-details-tour-complete', JSON.stringify(isComplete));
  }

  getStandardDetailsTourComplete() : boolean {
    if (localStorage.getItem("standards-details-tour-complete") === null) {
      localStorage.setItem('standards-details-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('standards-details-tour-complete'));
    }
  }

  setCatalogTourComplete(isComplete: boolean) {
    localStorage.setItem('catalog-tour-complete', JSON.stringify(isComplete));
  }

  getCatalogTourComplete() : boolean {
    if (localStorage.getItem("catalog-tour-complete") === null) {
      localStorage.setItem('catalog-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('catalog-tour-complete'));
    }
  }

  setProductDetailsTourComplete(isComplete: boolean) {
    localStorage.setItem('product-details-tour-complete', JSON.stringify(isComplete));
  }

  getProductDetailsTourComplete() : boolean {
    if (localStorage.getItem("product-details-tour-complete") === null) {
      localStorage.setItem('product-details-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('product-details-tour-complete'));
    }
  }

  setUniformBuilderTourComplete(isComplete: boolean) {
    localStorage.setItem('uniform-builder-complete', JSON.stringify(isComplete));
  }

  getUniformBuilderTourComplete() : boolean {
    if (localStorage.getItem("uniform-builder-complete") === null) {
      localStorage.setItem('uniform-builder-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('uniform-builder-complete'));
    }
  }

  setSpecSheetDetailsTourComplete(isComplete: boolean) {
    localStorage.setItem('spec-sheet-details-tour-complete', JSON.stringify(isComplete));
  }

  getSpecSheetDetailsTourComplete() : boolean {
    if (localStorage.getItem("spec-sheet-details-tour-complete") === null) {
      localStorage.setItem('spec-sheet-details-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('spec-sheet-details-tour-complete'));
    }
  }

  setProductLinesTourComplete(isComplete: boolean) {
    localStorage.setItem('product-lines-tour-complete', JSON.stringify(isComplete));
  }

  getProductLinesTourComplete() : boolean {
    if (localStorage.getItem("product-lines-tour-complete") === null) {
      localStorage.setItem('product-lines-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('product-lines-tour-complete'));
    }
  }

  setProductLineExpandedTourComplete(isComplete: boolean) {
    localStorage.setItem('product-line-expanded-tour-complete', JSON.stringify(isComplete));
  }

  getProductLineExpandedTourComplete() : boolean {
    if (localStorage.getItem("product-line-expanded-tour-complete") === null) {
      localStorage.setItem('product-line-expanded-tour-complete', JSON.stringify(false))
      return false;
    } else {
      return JSON.parse(localStorage.getItem('product-line-expanded-tour-complete'));
    }
  }
}
