import { GarmentPairing } from './garment-pairing.model';
import { v4 as uuid } from 'uuid';

export class SpecSheet {
  id: string;
  name: string;
  garmentPairings: GarmentPairing[];

  constructor(name:string){
    this.id = uuid();
    this.name = name;
    this.garmentPairings = [];
  }
}
