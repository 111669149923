<ngx-guided-tour></ngx-guided-tour>

<p-confirmDialog #cd>
  <p-footer>
    <button type="button" pButton label="Cancel" class="p-button-secondary" (click)="cd.reject()"></button>
    <button type="button" pButton label="Yes" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<section class="d-flex mb-4 mx-0 ml-md-5">
  <h1 class="section-title justify-content-start">Uniform Builder</h1>
</section>

<section>
  <div class="row no-gutters position-relative pt-3">
    <div class="uniform-percentage"></div>
    <div class="uniform-tools">
      <button class="uniform-info-icon" [routerLink]="['/contact-us']"></button>
      <button class="uniform-bookmark" [class.active]="garmentPairingsSaved | async" (click)="saveSpecSheet()">
        <span class="uniform-bookmark-icon"></span>
      </button>
      <button class="btn btn-circle spec-sheet-btn" [routerLink]="['/spec-sheet-details']"></button>
    </div>
  </div>
  <div class="row no-gutters justify-content-center">
    <div class="btn-group d-none" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-secondary" disabled>Tested Combo</button>
      <button type="button" class="btn btn-secondary" disabled>Off</button>
    </div>
  </div>
</section>

<div *ngIf="smallSidebar$" class="uniform-sidebar">
  <app-garment-selector></app-garment-selector>
</div>

<section class="mx-auto position-relative mannequin-section">
  <button type="button" class="p-carousel-prev p-link p-ripple text-center" [class.p-disabled]="isMaleActive$ | async" [disabled]="isMaleActive$ | async" (click)="navBackward($event)">
    <span class="p-carousel-prev-icon pi pi-chevron-left"></span>
  </button>

  <p-carousel #genderCarousel [value]="genderList$" class="hidden-pagination-area mannequin-section mx-auto" id="mannequin-carousel">
    <ng-template let-gender pTemplate="item">
      <div (touchend)="onTouchEnd($event)" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)">
        <div id="gender" class="d-none">{{ gender }}</div>
        <div class="mannequin {{ gender }}">
          <img src="assets/images/{{ gender }}/base.png" class="img-fluid">
          <div id="hair" class="garment" [class.d-none]="(isGarmentPaired(6) | async) || showPonytail$ == false" ><img class="img-fluid" src="assets/images/female/hair.png"></div>
          <div id="boot" class="garment" [class.d-none]="(isGarmentPaired(5) | async) == false && (isGarmentPaired(4) | async) == false">
            <img class="img-fluid" src="assets/images/{{ gender }}/boot.png">
          </div>
          <div id="mask" class="garment {{ gender.substr(0, 1) }}-mask" [class.d-none]="(isGarmentPaired(6) | async) == false" [class.active]="(selectedGarmentId$ | async) == 6">
            <button class="btn-percent d-none" disabled>90%</button>
            <span class="highlight">
              <img class="img-fluid" src="assets/images/{{ gender }}/mask.png">
            </span>
          </div>
          <div id="shortSleeve" class="garment {{ gender.substr(0, 1) }}-s-sleeve" [class.d-none]="(isGarmentPaired(1) | async) == false || ((isGarmentPaired(2) | async) && (selectedGarmentId$ | async) != 1)" [class.active]="(selectedGarmentId$ | async) == 1">
            <button class="btn-percent d-none" disabled>80%</button>
            <span class="highlight">
              <img class="img-fluid" src="assets/images/{{ gender }}/shortSleeve.png">
            </span>
          </div>
          <div id="pant" class="garment {{ gender.substr(0, 1) }}-pant" [class.d-none]="(isGarmentPaired(4) | async) == false || ((isGarmentPaired(5) | async) && (selectedGarmentId$ | async) != 4)" [class.active]="(selectedGarmentId$ | async) == 4">
            <button class="btn-percent d-none" disabled>80%</button>
            <span class="highlight">
              <img class="img-fluid" src="assets/images/{{ gender }}/pant.png">
            </span>
          </div>
          <div id="longSleeve" class="garment {{ gender.substr(0, 1) }}-l-sleeve" [class.d-none]="(isGarmentPaired(2) | async) == false" [class.active]="(selectedGarmentId$ | async) == 2">
            <button class="btn-percent d-none" disabled>80%</button>
            <span class="highlight">
              <img class="img-fluid" src="assets/images/{{ gender }}/longSleeve.png">
            </span>
          </div>
          <div id="jacket" class="garment {{ gender.substr(0, 1) }}-jacket" [class.d-none]="(isGarmentPaired(3) | async) == false" [class.active]="(selectedGarmentId$ | async) == 3">
            <button class="btn-percent d-none" disabled>90%</button>
            <span class="highlight">
              <img class="img-fluid" src="assets/images/{{ gender }}/jacket.png">
            </span>
          </div>
          <div id="cuff" class="garment {{ gender.substr(0, 1) }}-cuff" [class.d-none]="(isGarmentPaired(7) | async) == false || (isGarmentPaired(3) | async) == false" [class.active]="(selectedGarmentId$ | async) == 7 && (isGarmentPaired(3) | async)">
            <button class="btn-percent d-none" disabled>90%</button>
            <span class="highlight">
              <img class="img-fluid" src="assets/images/{{ gender }}/cuff.png">
            </span>
          </div>
          <div id="coverall" class="garment {{ gender.substr(0, 1) }}-coverall" [class.d-none]="(isGarmentPaired(5) | async) == false" [class.active]="(selectedGarmentId$ | async) == 5">
            <button class="btn-percent d-none" disabled>90%</button>
            <span class="highlight">
              <img class="img-fluid" src="assets/images/{{ gender }}/coverall.png">
            </span>
          </div>
          <div id="hat" class="garment d-none" [class.d-none]="(isGarmentPaired(6) | async)">
            <img class="img-fluid" src="assets/images/{{ gender }}/hat.png">
          </div>
          <div class="garment mannequin-toggle mannequin-toggle-{{ gender.substr(0, 1) }}-chest" (click)="cycleChest()"></div>
          <div class="garment mannequin-toggle mannequin-toggle-{{ gender.substr(0, 1) }}-head" (click)="cycleHead()"></div>
          <div class="garment mannequin-toggle mannequin-toggle-{{ gender.substr(0, 1) }}-legs" (click)="cycleLegs()"></div>
        </div>
      </div>
    </ng-template>
  </p-carousel>

  <button type="button" id="mannequin-right-btn" class="p-carousel-next p-link p-ripple text-center" [class.p-disabled]="isFemaleActive$ | async" [disabled]="isFemaleActive$ | async" (click)="navForward($event)">
    <span class="p-carousel-next-icon pi pi-chevron-right"></span>
  </button>
</section>

<div *ngIf="smallSidebar$ == false" class="uniform-tools">
  <button *ngIf="(isFemaleActive$ | async)" class="uniform-icon p-0" (click)="navBackward($event)">
    <span class="uniform-icon-male"></span>
  </button>
  <button *ngIf="(isMaleActive$ | async)" class="uniform-icon p-0" (click)="navForward($event)">
    <span class="uniform-icon-female"></span>
  </button>
</div>