import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HazardsComponent } from './hazards/hazards.component';
import { LoginComponent } from './login/login.component';
import { ProductCatalogComponent } from './product-catalog/product-catalog.component';
import { StandardsComponent } from './standards/standards.component';
import { UniformBuilderComponent } from './uniform-builder/uniform-builder.component';
import { ProductLinesComponent } from './product-lines/product-lines.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SettingsComponent } from './settings/settings.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductLinesExpandedComponent } from './product-lines-expanded/product-lines-expanded.component';
import { HazardDetailsComponent } from './hazard-details/hazard-details.component';
import { StandardDetailsComponent } from './standard-details/standard-details.component';
import { SpecSheetDetailsComponent } from './spec-sheet-details/spec-sheet-details.component';
import { MySpecSheetsComponent } from './my-spec-sheets/my-spec-sheets.component';
import { RegionsComponent } from './regions/regions.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SpecSheetExportComponent } from './spec-sheet-export/spec-sheet-export.component';
import { ContactSalesComponent } from './contact-sales/contact-sales.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard' } },
  { path: 'standards', component: StandardsComponent, data: { title: 'Standards' }  },
  { path: 'hazards', component: HazardsComponent, data: { title: 'Hazards' }  },
  { path: 'product-catalog', component: ProductCatalogComponent, data: { title: 'Product Catalog' }  },
  { path: 'product-catalog/:lineId', component: ProductCatalogComponent, data: { title: 'Product Catalog' } },
  { path: 'uniform-builder', component: UniformBuilderComponent, data: { title: 'Uniform Builder' } },
  { path: 'product-lines', component: ProductLinesComponent, data: { title: 'Product Lines' }   },
  { path: 'product-lines/:categoryId', component: ProductLinesComponent, data: { title: 'Product Lines' } },
  { path: 'contact-us', component: ContactUsComponent, data: { title: 'Contact Us' } },
  { path: 'contact-sales', component: ContactSalesComponent, data: { title: 'Contact Sales' } },
  { path: 'regions', component: RegionsComponent, data: { title: 'Regions' } },
  { path: 'product-details/:productId', component: ProductDetailsComponent, data: { title: 'Product Details' } },
  { path: 'standard-details/:standardId', component: StandardDetailsComponent, data: { title: 'Standard Details' } },
  { path: 'hazard-details/:hazardId', component: HazardDetailsComponent, data: { title: 'Hazard Details' } },
  { path: 'product-lines-expanded/:productLineId', component: ProductLinesExpandedComponent, data: { title: 'Product Lines' } },
  { path: 'spec-sheet-details', component: SpecSheetDetailsComponent, data: { title: 'Spec Sheet Details' } },
  { path: 'my-spec-sheets', component: MySpecSheetsComponent, data: { title: 'My Spec Sheets' } },
  { path: 'settings', component: SettingsComponent, data: { title: 'Settings' } },
  { path: '', component: RegionsComponent, data: { title: 'Regions' } },
  { path: 'spec-sheet-export', component: SpecSheetExportComponent, data: { title: 'Spec Sheet Export' }  },
  { path: 'cart', loadChildren: () => import('./shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule) },
  { path: '', redirectTo: '/regions', pathMatch: 'full'},
  { path: '**', component: NotFoundComponent, data: { title: 'Not Found' } },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
