<ngx-guided-tour></ngx-guided-tour>

<section class="mb-4 row mx-0 ml-md-5" [class.mt-5]="!(tourIsComplete | async) && mobileSize">
  <h1 id="hazards-title" class="section-title justify-content-start">Hazards</h1>
</section>

<section class="mx-0 ml-md-5">
  <div class="row">
      <form>
      </form>
  </div>

  <div class="row" id="hazards-list">
    <div *ngFor="let hazard of (hazards$ | async); index as i" class="wx-card">
      {{startTour(i)}}
      <div class="wx-card-inner cursor-pointer" [routerLink]="['/hazard-details', hazard.id]" style="cursor: pointer;">
        <div *ngIf="hazard.image as image; else elseBlock">
          <img [src]="baseUrl$ + image.url" class="wx-card-img">
        </div>
        <ng-template #elseBlock>
          <img src="https://via.placeholder.com/150" class="wx-card-img">
        </ng-template>
        <div class="wx-card-body">
          <h6 class="wx-card-title">{{ hazard.name }}</h6>
          <p class="card-text">{{ hazard.description }}</p>
        </div>
      </div>
      <button class="btn btn-accent btn-sm btn-block hazard-details-btn" [routerLink]="['/hazard-details', hazard.id]">View Details</button>
    </div>
  </div>


</section>