import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  grayBg: boolean;
  productCatalogActive: boolean;
  standardActive: boolean;
  productLinesActive: boolean;
  hazardActive: boolean;
  uniformBuilderActive: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      var route = this.router.url.split('/')[1];
      var pagesWithGrayBg = ['contact-us', 'dashboard', 'settings'];

      this.grayBg = pagesWithGrayBg.includes(route);

      this.productCatalogActive = route.includes("product-details") || route.includes("product-catalog");
      this.standardActive = route.includes("standard-details") || route.includes("standards");
      this.productLinesActive = route.includes("product-lines-expanded") || route.includes("product-lines");
      this.hazardActive = route.includes("hazard-details") || route.includes("hazards");
      this.uniformBuilderActive = route.includes("uniform-builder") || route.includes("spec-sheet-details") || route.includes("my-spec-sheets");
    });
  }

}
