import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { AppMessage } from 'src/app/models/app-message.model';

@Injectable({
  providedIn: 'root'
})
export class AppMessageService {

  private messages$: Observable<AppMessage[]>;

  constructor(private http: HttpClient) { }


  getAllMessages() : Observable<AppMessage[]>{
    if(!this.messages$) {
      this.messages$ = this.http.get<AppMessage[]>(`${environment.apiUrl}` + "/messages").pipe(
        shareReplay(1)
      );
    }

    return this.messages$;
  }

  get(messageId: number) : Observable<AppMessage>{
    return this.getAllMessages().pipe(
      map(messages => messages.find(messages => messages.id == messageId))
    );
  }

  getLatest() : Observable<AppMessage>{
    return this.getAllMessages()
    .pipe(
      map(messages => messages
        .filter(message => message.published_at)
        .sort( (a,b) => {
          return this.getTime(b.created_at) - this.getTime(a.created_at)
      })[0])
    );
  }

  getTime(date?: string) : number {
    return date != null ? new Date(date).getTime() : 0;
  }
}
