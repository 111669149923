import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HazardService } from 'src/services/hazard.service';
import { HazardDetails } from '../models/hazard.model';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';

@Component({
  selector: 'app-hazard-details',
  templateUrl: './hazard-details.component.html',
  styleUrls: ['./hazard-details.component.scss']
})
export class HazardDetailsComponent implements OnInit {

  activeHazard$: Observable<HazardDetails>;
  baseUrl$:string;
  private tourIsComplete: boolean;
  private tourIsLoaded: boolean = false;

  public hazardDetailsTour: GuidedTour = {
    tourId: 'hazard-details-tour',
    useOrb: false,
    steps: [],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };

  constructor(private hazardService: HazardService, private route:ActivatedRoute, private guidedTourService: GuidedTourService, private tourService: TourService) { }

  ngOnInit(): void {
    this.activeHazard$ = this.route.params.pipe(
      map(params => params['hazardId']),
      switchMap(hazardId => this.hazardService.get(hazardId))
    );

    this.baseUrl$ = `${environment.apiUrl}`;

    this.tourIsComplete = this.tourService.getHazardDetailsTourComplete()
  }

  public startTour(): void {
    if (!this.tourIsComplete && !this.tourIsLoaded) {
      this.tourIsLoaded = true;
      setTimeout(() => {
        this.guidedTourService.startTour(this.hazardDetailsTour);
      }, 100);
    }
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.hazardDetailsTour);
  }

  private tourComplete(): void {
    this.tourService.setHazardDetailsTourComplete(true);
  }

}
