<div class="modal-header">
  <button type="button" class="wx-close">
    <span class="wx-close-icon" aria-hidden="true" (click)="close()"></span>
  </button>
</div>
<div *ngIf="(filterGarmentHasRecords(this.garmentId) | async); then thenBlock else elseBlock"></div>
<ng-template #thenBlock>
  <div class="modal-body">
    Browse {{ (garment$ | async)?.name }} in Product Catalog
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="browse(this.garmentId)">Browse</button>
  </div>
</ng-template>
<ng-template #elseBlock>
  <div class="modal-body">
    Based on your filter criteria this garment is currently unavailable. Please adjust your filters to add a style to
    this garment.
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="close()">Ok</button>
  </div>
</ng-template>
