<section class="login">
    <div class="login-title mb-auto">
        <div class="login-title-graphic"></div>
        <div class="login-title-inner">Welcome</div>        
    </div>

    <form class="mx-auto wx-form narrow white py-6">
        <div class="form-group">
            <label class="label" for="email">Email</label>
            <input type="email" class="wx-form-control" id="email">
        </div>
        <div class="form-group">
            <label class="label" for="company">Password</label>
            <input type="password" class="wx-form-control" id="password">
            <small class="form-text text-white text-sm-right">Forgot Password?</small>
        </div>
        <div class="text-center">
            <button type="submit" class="btn btn-outline-white">Sign In</button>
        </div>        
    </form>

    <img class="img-fluid mt-auto" src="assets/images/wx-logo.svg" alt="Westex">
</section>