<div class="wx-sidebar" #wxSidebar [class.wx-sidebar-small]="smallSidebar$ && route == 'uniform-builder'" [class.wx-sidebar-hor]="route == 'product-details' && smallSidebar$">
    <div class="wx-sidebar-heading pb-2" [class.d-none]="smallSidebar$">
        <div class="h5 text-border-accent">Uniform Builder</div>
        <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.176 56.588"><defs/><path d="M55.628 43.114c-1.862-.972-5.814-1.688-9.507-3.144-3.427-1.351-7.823-4.125-8.068-4.673a13 13 0 01-.618-4.695 17.884 17.884 0 001.326-4.273c.169-.3.761-.369.885-.858.627-2.457 2-6.039 1.454-6.48-.467-.38 3.885-.841 4.186-2.465.167-.7-2.3-.977-2.437-1.743-.092-.527-.522-5.679-2.688-9.358a10.509 10.509 0 00-4.591-4.183c-.2-.177.061-.3-.736-.635-2.252-.943-7.1-.713-8.313.185-.224.166.055.3-.326.508a9.5 9.5 0 00-3.311 2.538 16.781 16.781 0 00-2.2 3.384 26.291 26.291 0 00-1.807 7.6c-.19 1.266-4.01.877-1.8 2.442a19.694 19.694 0 003.516 1.442c.727 3.517 1.3 6.316 1.987 7.022.212.22.659-.055.7 1.262.038 1.158 1.361 2.641 1.43 3.9a12.6 12.6 0 01-.8 4.473c-.476.966-4.208 2.747-5.283 3.415-2.039 1.266-6.618 2.323-9.205 3.025a11.758 11.758 0 00-7.695 6.137 22.459 22.459 0 00-1.67 5.507 2.63 2.63 0 002.587 3.138h55.9a2.631 2.631 0 002.585-3.151c-.783-3.811-2.23-8.703-5.501-10.32z"/></svg>
        </span>
    </div>
    <p *ngIf="smallSidebar$ && route == 'product-details'" class="h5" style="color: #08003D;">Uniform Builder</p>
    <div *ngIf="route == 'uniform-builder'; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
      <p [class.mx-2]="smallSidebar$ == false || route != 'product-details'" [class.mb-4]="route != 'product-details'" [class.text-center]="smallSidebar$ == false" [class.d-none]="smallSidebar$">Select an area to browse swatches.</p>
    </ng-template>
    <ng-template #elseBlock>
      <p [class.mx-2]="smallSidebar$ == false || route != 'product-details'" [class.mb-4]="route != 'product-details'" [class.mb-1]="smallSidebar$ && route == 'product-details'" [class.text-center]="smallSidebar$ == false || route != 'product-details'">To begin, select an area to add this swatch to your uniform.</p>
    </ng-template>

    <div class="wx-sidebar-list-container">
        <!-- Female -->
        <div class="wx-sidebar-list female" [class.d-none]="!(isFemaleActive$ | async)" [class.wx-sidebar-list-hor]="route == 'product-details' && smallSidebar$">
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(5)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(5) | async)" [class.active]="(selectedGarmentId$ | async) == 5 && route == 'uniform-builder'">
                <div class="garment-img thumb-f-coverall" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(5)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(5) | async)" src="assets/images/female/thumb_coverall.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(5) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(5) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(5, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(7)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(7) | async)" [class.active]="(selectedGarmentId$ | async) == 7 && route == 'uniform-builder'">
                <div class="garment-img thumb-f-cuff" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(7)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(7) | async)" src="assets/images/female/thumb_cuff.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(7) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(7) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(7, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(3)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(3) | async)" [class.active]="(selectedGarmentId$ | async) == 3 && route == 'uniform-builder'">
                <div class="garment-img thumb-f-jacket" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(3)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(3) | async)" src="assets/images/female/thumb_jacket.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(3) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(3) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(3, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(2)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(2) | async)" [class.active]="(selectedGarmentId$ | async) == 2 && route == 'uniform-builder'">
                <div class="garment-img thumb-f-l-sleeve" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(2)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(2) | async)" src="assets/images/female/thumb_longSleeve.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(2) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(2) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(2, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(1)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(1) | async)" [class.active]="(selectedGarmentId$ | async) == 1 && route == 'uniform-builder'">
                <div class="garment-img thumb-f-s-sleeve" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(1)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(1) | async)" src="assets/images/female/thumb_shortSleeve.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(1) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(1) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(1, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(6)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(6) | async)" [class.active]="(selectedGarmentId$ | async) == 6 && route == 'uniform-builder'">
                <div class="garment-img thumb-f-mask" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(6)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(6) | async)" src="assets/images/female/thumb_mask.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(6) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(6) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(6, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(4)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(4) | async)" [class.active]="(selectedGarmentId$ | async) == 4 && route == 'uniform-builder'">
                <div class="garment-img thumb-f-pant" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(4)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(4) | async)" src="assets/images/female/thumb_pant.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(4) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(4) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(4, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Male -->
        <div class="wx-sidebar-list male" [class.d-none]="!(isMaleActive$ | async)" [class.wx-sidebar-list-hor]="route == 'product-details' && smallSidebar$">
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(5)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(5) | async)" [class.active]="(selectedGarmentId$ | async) == 5 && route == 'uniform-builder'">
                <div class="garment-img thumb-m-coverall" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(5)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(5) | async)" src="assets/images/male/thumb_coverall.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(5) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(5) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(5, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(7)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(7) | async)" [class.active]="(selectedGarmentId$ | async) == 7 && route == 'uniform-builder'">
                <div class="garment-img thumb-m-cuff" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(7)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(7) | async)" src="assets/images/male/thumb_cuff.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(7) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(7) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(7, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(3)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(3) | async)" [class.active]="(selectedGarmentId$ | async) == 3 && route == 'uniform-builder'">
                <div class="garment-img thumb-m-jacket" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(3)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(3) | async)" src="assets/images/male/thumb_jacket.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(3) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(3) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(3, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(2)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(2) | async)" [class.active]="(selectedGarmentId$ | async) == 2 && route == 'uniform-builder'">
                <div class="garment-img thumb-m-l-sleeve" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(2)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(2) | async)" src="assets/images/male/thumb_longSleeve.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(2) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(2) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(2, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(1)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(1) | async)" [class.active]="(selectedGarmentId$ | async) == 1 && route == 'uniform-builder'">
                <div class="garment-img thumb-m-s-sleeve" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(1)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(1) | async)" src="assets/images/male/thumb_shortSleeve.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(1) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(1) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(1, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(6)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(6) | async)" [class.active]="(selectedGarmentId$ | async) == 6 && route == 'uniform-builder'">
                <div class="garment-img thumb-m-mask" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(6)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(6) | async)" src="assets/images/male/thumb_mask.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(6) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(6) | async as pairedGarment" class="garment-body" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(6, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="route == 'uniform-builder' || garmentIds$.includes(4)" class="garment" [class.garment-small]="smallSidebar$ && route == 'uniform-builder'" [class.garment-small-hor]="smallSidebar$ && route == 'product-details'" [class.on]="(isGarmentPaired(4) | async)" [class.active]="(selectedGarmentId$ | async) == 4 && route == 'uniform-builder'">
                <div class="garment-img thumb-m-pant" [class.garment-img-small]="smallSidebar$">
                    <img (click)="toggleSelectedGarment(4)" [class.cursor-pointer]="route == 'product-details' || (isGarmentPaired(4) | async)" src="assets/images/male/thumb_pant.png">
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(4) | async as pairedGarment">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-swatch" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
                    </div>
                </div>
                <div *ngIf="smallSidebar$ == false && getPairedGarment(4) | async as pairedGarment" class="garment-body">
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-title">
                        <span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment">{{ garment.name }} | {{ style.name }}</span>
                        <button (click)="toggleSelectedGarment(4, true)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-text">
                        <p class="mb-0">{{ style.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="text-center mb-4" [class.mt-4]="!smallSidebar$" *ngIf="route != 'uniform-builder'">
        <button class="btn btn-primary btn-sm standard-btn px-5" [routerLink]="['/uniform-builder']">
            View Your Uniform
        </button>
    </div>
</div>
