import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Carousel } from 'primeng/carousel';
import { AppMessage } from '../models/app-message.model';
import { AppMessageService } from 'src/services/app-message.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('dashboardCarousel') carousel: Carousel;

  private startPos: any; // tap positions
  private swipeThreshold: number = 15; // how many pixels until swipe is registered
  message$: Observable<AppMessage>;

  numCardsShown: number;
  dashButtons: string[] = ["messages", "standards", "hazards", "product-lines"];
  responsiveOptions = [
    {
      breakpoint: '1900px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '1300px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '800px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  constructor(private messageService: AppMessageService) {
  }


  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-light');
    //body.classList.add('bg-img-dashboard');
    const containerBg = document.getElementById('containerBg');
    containerBg.classList.add('rounded-corners-container');
    containerBg.style.backgroundColor = "#08003D";
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.add('rounded-corners-body');
    roundedCorners.classList.add('bg-img-dashboard');

    this.setNumProductsShown();
    this.message$ = this.messageService.getLatest();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setNumProductsShown();
  }

  setNumProductsShown() {
    if (window.innerWidth <= 800) {
      this.numCardsShown = 1;
    } else if (window.innerWidth <= 1350) {
      this.numCardsShown = 2;
    } else {
      this.numCardsShown = 3;
    }
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-light');
    const containerBg = document.getElementById('containerBg');
    containerBg.classList.remove('rounded-corners-container');
    containerBg.style.backgroundColor = "#ffffff";
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.remove('rounded-corners-body');
    roundedCorners.classList.remove('bg-img-dashboard');
  }

  // Touch events for carousel
  onTouchEnd(e: any) {
    let touchobj = e.changedTouches[0];

    this.changePageOnTouch(e, (touchobj.pageX - this.startPos.x));
  }

  onTouchStart(e: any) {
    let touchobj = e.changedTouches[0];

    this.startPos = {
      x: touchobj.pageX,
      y: touchobj.pageY
    };
  }

  onTouchMove = () => { };

  changePageOnTouch(e, diff) {
    if (Math.abs(diff) > this.swipeThreshold) {
      if (diff < 0) {
        this.navForward(e);
      }
      else {
        this.navBackward(e);
      }
    }
  }

  navForward(e: any) {
    let i = this.carousel.page + 1;
    if (this.carousel.page < this.carousel.value.length && this.carousel.page > 0) {
      this.carousel.navForward(e, i);
    }
  }

  navBackward(e: any) {
    let i = this.carousel.page - 1;
    if (this.carousel.page < this.carousel.value.length && this.carousel.page > 0) {
      this.carousel.navForward(e, i);
    }
  }

}
