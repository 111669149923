import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, pipe } from 'rxjs';
import { ProductLineService } from './product-line.service';
import { GarmentPairing } from 'src/app/models/garment-pairing.model';
import { map, shareReplay } from 'rxjs/operators';
import { Garment, GarmentDetails } from 'src/app/models/garment.model';

@Injectable({
  providedIn: 'root'
})
export class GarmentService {

  private garmentPairingsSubject$ = new BehaviorSubject<GarmentPairing[]>([]);
  public garmentsPairings$ = this.garmentPairingsSubject$.asObservable();

  public garmentSelectorComponents$ = [];

  private selectedGarmentIdSubject$ = new BehaviorSubject<number>(-1);
  public selectedGarmentId$ = this.selectedGarmentIdSubject$.asObservable();;

  public activeSpecSheetId = null;

  private garments$: Observable<GarmentDetails[]>;

  constructor(private http: HttpClient, private productLineService: ProductLineService) { }

  getAllGarments() : Observable<GarmentDetails[]>{
    if(!this.garments$) {
      this.garments$ = this.http.get<GarmentDetails[]>(`${environment.apiUrl}` + "/garments").pipe(
        map(garments => garments.sort((a,b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))),
        shareReplay(1)
      );
    }

    return this.garments$;
  }

  get(garmentId: number) : Observable<GarmentDetails>{
    return this.getAllGarments().pipe(
      map(garments => garments.find(garment => garment.id == garmentId))
    );
  }

  getGarmentPairing(garmentId: number) : Observable<GarmentPairing> {
    return this.garmentsPairings$.pipe(map(pairedGarments => pairedGarments.find(pairedGarment => pairedGarment.garmentId == garmentId)));
  }

  toggleGarmentPairing(garmentId: number, styleId: number) {
    let arr = [...this.garmentPairingsSubject$.value];

    if (arr.some(gp => gp.garmentId == garmentId) && arr.length > 0){
      let i = arr.findIndex( gp => gp.garmentId == garmentId);
      arr.splice(i, 1);
    }else{
      var pairing: any = {};
      pairing.garmentId = garmentId;
      pairing.styleId = styleId;

      arr.push(pairing);
    }

    this.garmentPairingsSubject$.next(arr);
  }

  resetGarmentSelectorComponents(): void {
    this.garmentSelectorComponents$.forEach(component => component.ngOnInit());
  }

  updateGarmentPairings(garmentPairings: GarmentPairing[]){
    this.garmentPairingsSubject$.next(garmentPairings);
  }

  addGarmentPairings(garmentPairings: GarmentPairing[]) {
    let arr = [...this.garmentPairingsSubject$.value];

    for(let pairing of garmentPairings){
      if (arr.some(gp => gp.garmentId == pairing.garmentId) == false){
        arr.push(pairing);
      }
    }

    this.garmentPairingsSubject$.next(arr);
  }

  clearGarmentPairings() {
    let arr = [...this.garmentPairingsSubject$.value];
    arr.splice(0, arr.length);
    this.garmentPairingsSubject$.next(arr);
  }

  setSelectedGarmentId(garmentId: number) {
    this.selectedGarmentIdSubject$.next(garmentId);
  }

}
