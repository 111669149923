import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Carousel } from 'primeng/carousel';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { AppSettingsService } from 'src/services/app-settings.service';
import { GarmentService } from 'src/services/garment.service';
import { SpecSheetService } from 'src/services/spec-sheet.service';
import { GarmentPairing } from '../models/garment-pairing.model';
import { AppSettings } from '../models/settings.model';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UniformModalComponent } from '../uniform-modal/uniform-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-uniform-builder',
  templateUrl: './uniform-builder.component.html',
  styleUrls: ['./uniform-builder.component.scss'],
  providers: [ConfirmationService]
})
export class UniformBuilderComponent implements OnInit {

  @ViewChild('genderCarousel') carousel:Carousel;
  @ViewChild('cd') dialog:ConfirmDialog;

  bsModalRef: BsModalRef;

  pairedGarments$: Observable<GarmentPairing[]>;
  selectedGarmentId$: Observable<number>;
  selectedChestGarmentIds$: Observable<number[]>;
  selectedLegGarmentIds$: Observable<number[]>;

  isMaleActive$: Observable<boolean>;
  isFemaleActive$: Observable<boolean>;

  chestGarmentIds: number[] = [1, 2, 3, 5, 7];
  legGarmentIds: number[] = [4, 5];

  smallSidebar$: boolean;
  genderList$: string[] = ["male", "female"];
  appSettings$: Observable<AppSettings>;
  garmentPairingsSaved: Observable<boolean>;
  showPonytail$: boolean;

  destroyed$ = new Subject();

  private startPos: any; // tap positions
  private swipeThreshold: number = 20; // how many pixels until gender is changed via swipe
  private tourIsComplete: boolean;

  private mobileSteps = [
    {
      title: 'Uniform Builder Panel',
      selector: window.innerWidth <= 991 ? '.uniform-sidebar' : '.uniform',
      content: 'Select a garment area to view this product on your mannequin.',
      orientation: Orientation.Left
    },
    {
      title: 'Garment Area',
      selector: window.innerWidth <= 991 ? '.uniform-sidebar .wx-sidebar .wx-sidebar-list-container .wx-sidebar-list .garment-small .thumb-m-coverall' : '.thumb-m-coverall',
      content: 'Tap a garment area icon to highlight this product on your mannequin.',
      orientation: Orientation.Left
    },
    {
      title: 'Mannequin',
      selector: '#mannequin-carousel',
      content: 'Tapping garment areas on the mannequin will highlight areas that have an applied product.',
      orientation: window.innerWidth <= 991 ? Orientation.Top : Orientation.Left
    },
    {
      title: 'Mannequin Gender',
      selector: '#mannequin-right-btn',
      content: 'Click this arrow to change your mannequin’s gender.',
      orientation: Orientation.BottomRight
    },
    {
      title: 'Spec Sheet',
      selector: '.uniform-bookmark-icon',
      content: 'Select the save icon to create a spec sheet from the products you have added to the mannequin garment areas.',
      orientation: Orientation.BottomRight
    },
    {
      title: 'View Spec Sheets',
      selector: '.spec-sheet-btn',
      content: 'Select the spec sheet icon to view your list of saved spec sheets.',
      orientation: Orientation.BottomRight
    }
  ]

  private fullSteps = [
    {
      title: 'Selected Standards',
      selector: '.wx-sidebar-container',
      content: 'The Standards Sidebar shows your standard selections along with standards from other regions.',
      orientation: Orientation.Right
    }
  ].concat(this.mobileSteps)

  public uniformBuilderTour: GuidedTour = {
    tourId: 'uniform-builder-tour',
    useOrb: false,
    steps: [],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };

  constructor(
    private elem: ElementRef,
    private garmentService: GarmentService,
    private confirmationService: ConfirmationService,
    private specSheetService: SpecSheetService,
    private appSettingsService: AppSettingsService,
    private guidedTourService: GuidedTourService,
    private tourService: TourService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.garmentService.resetGarmentSelectorComponents();

    this.pairedGarments$ = this.garmentService.garmentsPairings$;

    this.pairedGarments$.subscribe(pgs => this.specSheetService.areGarmentPairingsSaved(pgs));
    this.garmentPairingsSaved = this.specSheetService.areGarmentPairingsSaved$;

    this.selectedGarmentId$ = this.garmentService.selectedGarmentId$;

    this.appSettings$ = this.appSettingsService.appSettings$;

    this.isMaleActive$ = this.appSettingsService.appSettings$.pipe(
      map( appSettings => appSettings.gender == 1)
    )

    this.isFemaleActive$ = this.appSettingsService.appSettings$.pipe(
      map( appSettings => appSettings.gender == 2)
    )

    var isFemaleActive;
    this.isFemaleActive$.subscribe(active => isFemaleActive = active);
    this.showPonytail$ = isFemaleActive;

    this.smallSidebar$ = window.innerWidth < 992;

    this.tourIsComplete = this.tourService.getUniformBuilderTourComplete()

    //tap to show garment details
    this.garmentService.selectedGarmentId$
    .pipe(
      takeUntil(this.destroyed$),
      switchMap(id => this.garmentService.getGarmentPairing(id))
    ).subscribe(pairing => {
      if (pairing){
        this.openModal(pairing)
      }
    } )

    this.selectedChestGarmentIds$ = this.garmentService.garmentsPairings$
    .pipe(
      map(pairings => pairings
        .filter(pg => this.chestGarmentIds.includes(pg.garmentId))
        .map(pg => pg.garmentId))
    )

    this.selectedLegGarmentIds$ = this.garmentService.garmentsPairings$
    .pipe(
      map(pairings => pairings
        .filter(pg => this.legGarmentIds.includes(pg.garmentId))
        .map(pg => pg.garmentId))
    )

  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    // switch to female if it was previously selected
    var genderId;
    this.appSettingsService.appSettings$.subscribe( appSettings => genderId = appSettings.gender);
    if (genderId == 2){
      this.carousel.step(-1, null);
    }

    if (!this.tourIsComplete) {
      if (window.innerWidth <= 991) {
        this.uniformBuilderTour.steps = this.mobileSteps;
      } else {
        this.uniformBuilderTour.steps = this.fullSteps;
      }

      setTimeout(() => {
        this.guidedTourService.startTour(this.uniformBuilderTour);
      }, 0);
    }
  }

  onTouchEnd(e: any) {
    let touchobj = e.changedTouches[0];

		this.changePageOnTouch(e, (touchobj.pageX - this.startPos.x));
  }

  onTouchStart(e: any) {
    let touchobj = e.changedTouches[0];

		this.startPos = {
			x: touchobj.pageX,
			y: touchobj.pageY
		};
  }

  onTouchMove = () => { };

  changePageOnTouch(e, diff) {
		if (Math.abs(diff) > this.swipeThreshold) {
			if (diff < 0) {
				this.navForward(e);
			}
			else {
				this.navBackward(e);
			}
		}
	}

  navForward(e: any){
    this.appSettingsService.toggleActiveGender(2);
    this.carousel.navForward(e);
    this.delay(200).then(any=>{
      this.showPonytail$ = true;
    });
  }

  navBackward(e: any){
    this.appSettingsService.toggleActiveGender(1);
    this.carousel.navBackward(e);
    this.showPonytail$ = false;
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  isGarmentPaired(garmentId: number): Observable<boolean> {
    return this.pairedGarments$.pipe(map(garments => garments.some(garment => garment.garmentId == garmentId)));
  }

  saveSpecSheet() {
    combineLatest(this.specSheetService.activeSpecSheet$, this.garmentService.garmentsPairings$).pipe(
      //take(1)
    ).subscribe(
      ([specSheet, pairedGarments]) => {
        this.confirmationService.confirm({
          message: "<div class='modal-inner-header'>" + specSheet.name + "</div>Would you like to save '" + specSheet.name + "' to My Sheets?",
          header: "Add to My Sheets",
          accept: () => {
            specSheet.garmentPairings = pairedGarments;
            this.specSheetService.updateSpecSheet(specSheet);
            this.pairedGarments$.subscribe(pgs => this.specSheetService.areGarmentPairingsSaved(pgs));
            this.router.navigateByUrl('/contact-sales');
          }
        });
      });
  }

  toggleActiveGender(genderId: number){
    this.appSettingsService.toggleActiveGender(genderId);
  }

  cycleHead(){
    combineLatest(this.selectedGarmentId$, this.pairedGarments$)
    .pipe(
      take(1)
    ).subscribe( ([selectedGarmentId,pairings]) => {
      if(pairings.some(pg => pg.garmentId == 6) && selectedGarmentId != 6){
        this.garmentService.setSelectedGarmentId(6);
      } else if(pairings.some(pg => pg.garmentId == 6) && selectedGarmentId == 6){
        this.garmentService.setSelectedGarmentId(-1);
      }
    });
  }

  cycleChest(){
    combineLatest(this.selectedGarmentId$, this.selectedChestGarmentIds$)
    .pipe(
      take(1)
    ).subscribe(([selectedGarmentId,pairingIds]) => {
      if(pairingIds.includes(7) && pairingIds.includes(3) == false){
        pairingIds.splice(pairingIds.indexOf(7), 1);
      }

      var index = pairingIds.indexOf(selectedGarmentId);
      this.garmentService.setSelectedGarmentId(index == pairingIds.length ? -1 : pairingIds[index + 1]);
    });
  }

  cycleLegs(){
    combineLatest(this.selectedGarmentId$, this.selectedLegGarmentIds$)
    .pipe(
      take(1)
    ).subscribe(([selectedGarmentId,pairingIds]) => {
      if(pairingIds.includes(7) && pairingIds.includes(3) == false){
        pairingIds.splice(pairingIds.indexOf(7), 1);
      }

      var index = pairingIds.indexOf(selectedGarmentId);
      this.garmentService.setSelectedGarmentId(index == pairingIds.length ? -1 : pairingIds[index + 1]);
    });
  }

  openModal(pairing:GarmentPairing) {
    this.bsModalRef = this.modalService.show(UniformModalComponent,
      {
        class: 'modal-dialog-centered uniform-modal-content',
        initialState: { garmentPairing: pairing }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.smallSidebar$ = window.innerWidth < 992;
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.dialog.visible && event.key == "Enter"){
      this.dialog.accept();
    } else if (this.dialog.visible && event.key == "Escape"){
      this.dialog.reject();
    }
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.uniformBuilderTour);
  }

  private tourComplete(): void {
    this.tourService.setUniformBuilderTourComplete(true);
  }

}
