import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { StandardService } from 'src/services/standard.service';
import { environment } from 'src/environments/environment';
import { Resource } from '../models/resource.model';
import { StandardDetails } from '../models/standard.model';
import { StyleDetails } from '../models/style.model';
import { filterStandards } from '../operators';
import { ProductLineService } from 'src/services/product-line.service';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';

@Component({
  selector: 'app-standard-details',
  templateUrl: './standard-details.component.html',
  styleUrls: ['./standard-details.component.scss']
})
export class StandardDetailsComponent implements OnInit {

  activeStandard$: Observable<StandardDetails>;
  activeStandards$:Observable<number[]>;
  responsiveOptions;
  showHazards: boolean;
  baseUrl = environment.apiUrl;
  filteredStyles$: Observable<StyleDetails[]>;
  numProductsShown: number;
  showResources$: boolean;
  private tourIsComplete: boolean;
  private tourIsLoaded: boolean = false;

  public standardDetailsTour: GuidedTour = {
    tourId: 'standard-details-tour',
    useOrb: false,
    steps: [
      {
        title: 'Resources',
        selector: window.innerWidth <= 991 ? '#resources-section' : '.resources',
        content: 'Find information related to a Standard specific criteria, recommended applications and safety specifications.',
        orientation: window.innerWidth <= 991 ? Orientation.Top : Orientation.Left
      },
      {
        title: 'Explore in Catalog',
        selector: '#explore-in-catalog-btn',
        content: 'Apply this Standard to your filter set and browse for products that meet your desired criteria.',
        orientation: window.innerWidth <= 767 ? Orientation.Bottom : Orientation.Top
      }
    ],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };

  constructor(private standardService: StandardService, private route:ActivatedRoute, private productLineService: ProductLineService, private guidedTourService: GuidedTourService, private tourService: TourService) {
    this.responsiveOptions = [
      {
        breakpoint: '1200px',
        numVisible: 4,
        numScroll: 4
      },
      {
        breakpoint: '768px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '576px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '362px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit(): void {
    this.activeStandard$ = this.route.params.pipe(
      map(params => params['standardId']),
      switchMap(standardId => this.standardService.get(standardId))
    );

    this.activeStandard$.subscribe(standard => this.standardService.setActiveStandardDetails(standard.id));

    this.activeStandards$ = this.standardService.activeStandards$;

    this.showHazards = this.activeStandard$ != null;

    this.filteredStyles$ = this.productLineService.getProductStyles().pipe(
      filterStandards(this.standardService.activeStandards$)
    );

    this.setNumProductsShown();
    this.showResources$ = window.innerWidth < 992;

    this.tourIsComplete = this.tourService.getStandardDetailsTourComplete()
  }

  toggleActiveStandard(id: number){
    this.standardService.toggleActiveStandard(id);
  }

  addActiveStandard(id: number){
    this.standardService.addActiveStandard(id);
  }

  filterStyleHasRecords(standardId: number) {
    return this.filteredStyles$.pipe(
      filterStandards(of([standardId])),
      map(styles => styles.length > 0)
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setNumProductsShown();
    this.showResources$ = window.innerWidth < 992;
  }

  setNumProductsShown() {
    if (window.innerWidth <= 362) {
      this.numProductsShown = 1;
    } else if (window.innerWidth <= 576) {
      this.numProductsShown = 2;
    } else if (window.innerWidth <= 768) {
      this.numProductsShown = 3;
    } else if (window.innerWidth <= 1200) {
      this.numProductsShown = 4;
    } else {
      this.numProductsShown = 5;
    }
  }

  public startTour(): void {
    if (!this.tourIsComplete && !this.tourIsLoaded) {
      this.tourIsLoaded = true;
      setTimeout(() => {
        this.guidedTourService.startTour(this.standardDetailsTour);
      }, 500);
    }
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.standardDetailsTour);
  }

  private tourComplete(): void {
    this.tourService.setStandardDetailsTourComplete(true);
  }

}
