import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShoppingCartService } from 'src/services/shopping-cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  grayBg: boolean;
  productCatalogActive: boolean;
  standardActive: boolean;
  productLinesActive: boolean;
  hazardActive: boolean;
  uniformBuilderActive: boolean;

  selectedSwatches$: Observable<number>;

  constructor(private router: Router, private cartService: ShoppingCartService) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      var route = this.router.url.split('/')[1];
      var pagesWithGrayBg = ['contact-us', 'dashboard', 'settings'];

      this.grayBg = pagesWithGrayBg.includes(route);

      this.productCatalogActive = route.includes("product-details") || route.includes("product-catalog");
      this.standardActive = route.includes("standard-details") || route.includes("standards");
      this.productLinesActive = route.includes("product-lines-expanded") || route.includes("product-lines");
      this.hazardActive = route.includes("hazard-details") || route.includes("hazards");
      this.uniformBuilderActive = route.includes("uniform-builder") || route.includes("spec-sheet-details") || route.includes("my-spec-sheets");
    });

    this.selectedSwatches$ = this.cartService.selectedSwatches$.pipe(
      map(swatches => swatches.length)
    );
  }

}
