import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { map, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/services/app-settings.service';
import { CategoryFilterService } from 'src/services/category-filter.service';
import { GarmentFilterService } from 'src/services/garment-filter.service';
import { GarmentService } from 'src/services/garment.service';
import { LineFilterService } from 'src/services/line-filter.service';
import { ProductLineService } from 'src/services/product-line.service';
import { RegionFilterService } from 'src/services/region-filter.service';
import { SpecialFilterService } from 'src/services/special-filter.service';
import { StandardService } from 'src/services/standard.service';
import { CategoryDetails } from '../models/category.model';
import { GarmentDetails } from '../models/garment.model';
import { LineDetails } from '../models/line.model';
import { RegionDetails } from '../models/region.model';
import { SpecialDetails } from '../models/special.model';
import { StandardDetails } from '../models/standard.model';
import { StyleDetails } from '../models/style.model';
import { filterCategories, filterGarments, filterLines, filterRegions, filterSpecials, filterStandards } from '../operators';

@Component({
  selector: 'app-selected-standards',
  templateUrl: './selected-standards.component.html',
  styleUrls: ['./selected-standards.component.scss']
})
export class SelectedStandardsComponent implements OnInit {

  activeStandards$: Observable<number[]>;
  selectedStandards$: Observable<StandardDetails[]>;
  baseUrl$:string;



  /**
   * Filters.
   */
  filterCategories$: Observable<CategoryDetails[]>;
  allCategories$: Observable<CategoryDetails[]>;

  filterSpecials$: Observable<SpecialDetails[]>;
  allSpecials$: Observable<SpecialDetails[]>;

  filterLines$: Observable<LineDetails[]>;
  allLines$: Observable<LineDetails[]>;

  filterGarments$: Observable<GarmentDetails[]>;
  allGarments$: Observable<GarmentDetails[]>;

  filterStandards$: Observable<StandardDetails[]>;
  regionStandards$: Observable<StandardDetails[]>;

  filteredStyles$: Observable<StyleDetails[]>;
  filteredLines$: Observable<LineDetails[]>;
  filteredCategories$: Observable<CategoryDetails[]>;

  filterRegions$: Observable<RegionDetails[]>;
  allRegions$: Observable<RegionDetails[]>;

  filteredRegions$: Observable<RegionDetails[]>;
  openRegions$: Observable<number[]>;

  allStandards$: Observable<StandardDetails[]>;

  constructor(
    private productLineService: ProductLineService,
    private standardService: StandardService,
    private garmentService: GarmentService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private appSettingsService: AppSettingsService,
    public categoryFilters: CategoryFilterService,
    public specialFilters: SpecialFilterService,
    public lineFilters: LineFilterService,
    public garmentFilters: GarmentFilterService,
    public regionFilters: RegionFilterService,
    public settingsService: AppSettingsService
  ) { }

  ngOnInit(): void {
    this.allCategories$ = this.productLineService.getCategories();
    this.allSpecials$ = this.productLineService.getSpecials();
    this.allLines$ = this.productLineService.getProductLines();
    this.allGarments$ = this.garmentService.getAllGarments();
    this.allRegions$ = this.appSettingsService.getAllRegions();

    this.activeStandards$ = this.standardService.activeStandards$.pipe();
    this.selectedStandards$ = this.standardService.getAllStandards();
    this.openRegions$ = this.settingsService.getOpenRegions();
    this.settingsService.appSettings$.pipe(
      map(settings => settings.region),
      take(1)
    ).subscribe(m => this.settingsService.toggleOpenRegion(m));


    const regionIds = this.selectedStandards$.pipe(
      map(standards => standards.reduce((curr, standard) => [...curr, ...standard.regions.map(region => region.id)], [] as number[]))
    );

    this.filteredRegions$ = combineLatest([this.settingsService.getAllRegions(), regionIds]).pipe(
      map(([regions, regionIds]) => regions.filter(region => regionIds.includes(region.id)))
    );

    this.filterCategories$ = combineLatest([
      this.allCategories$,
      this.categoryFilters.changes$
    ]).pipe(
      map(([categories, filterCategoryIds]) => categories.filter(category => filterCategoryIds.includes(category.id)))
    );

    this.filterSpecials$ = combineLatest([
      this.allSpecials$,
      this.specialFilters.changes$
    ]).pipe(
      map(([specials, filterSpecialIds]) => specials.filter(special => filterSpecialIds.includes(special.id)))
    );

    this.filterLines$ = combineLatest([
      this.allLines$,
      this.lineFilters.changes$
    ]).pipe(
      map(([lines, filterLineIds]) => lines.filter(line => filterLineIds.includes(line.id)))
    );

    this.filterGarments$ = combineLatest([
      this.allGarments$,
      this.garmentFilters.changes$
    ]).pipe(
      map(([garments, filterGarmentIds]) => garments.filter(garment => filterGarmentIds.includes(garment.id)))
    );

    this.filterRegions$ = combineLatest([
      this.allRegions$,
      this.regionFilters.changes$
    ]).pipe(
      map(([regions, filterRegionIds]) => regions.filter(region => filterRegionIds.includes(region.id)))
    );


    this.filteredStyles$ = this.productLineService.getProductStyles().pipe(
      filterStandards(this.standardService.activeStandards$),
      filterCategories(this.categoryFilters.changes$),
      filterLines(this.lineFilters.changes$),
      filterGarments(this.garmentFilters.changes$),
      filterSpecials(this.specialFilters.changes$),
      filterRegions(this.selectedStandards$,this.regionFilters.changes$)
    );

    this.baseUrl$ = `${environment.apiUrl}`
  }

  getStandards(regionId: number) {
    return this.selectedStandards$.pipe(
      map(standards => standards.filter(standard => standard.regions.some(region => region.id == regionId)))
    );
  }

  toggleActiveStandard(id: number){
    this.standardService.toggleActiveStandard(id);
  }

  toggleOpenRegion(id: number){
    this.settingsService.toggleOpenRegion(id);
  }

  filterCategoryHasRecords(categoryId: number) {
    return this.filteredStyles$.pipe(
      filterCategories(of([categoryId])),
      map(lines => lines.length > 0)
    );
  }

  filterLineHasRecords(lineId: number) {
    return this.filteredStyles$.pipe(
      filterLines(of([lineId])),
      map(lines => lines.length > 0)
    );
  }

  filterGarmentHasRecords(garmentId: number) {
    return this.filteredStyles$.pipe(
      filterGarments(of([garmentId])),
      map(lines => lines.length > 0)
    );
  }

  filterSpecialHasRecords(specialId: number) {
    return this.filteredStyles$.pipe(
      filterSpecials(of([specialId])),
      map(lines => lines.length > 0)
    );
  }

  filterRegionHasRecords(regionId: number) {
    return this.filteredStyles$.pipe(
      filterRegions(this.selectedStandards$,of([regionId])),
      map(styles => styles.length > 0)
    );
  }

  filterStyleHasRecords(standardId: number) {
    return this.filteredStyles$.pipe(
      filterStandards(of([standardId])),
      map(styles => styles.length > 0)
    );
  }

}
