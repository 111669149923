import { Component, ElementRef, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HazardService } from 'src/services/hazard.service';
import { HazardDetails } from '../models/hazard.model';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { AppSettingsService } from 'src/services/app-settings.service';
import { TourService } from 'src/services/tour.service';

@Component({
  selector: 'app-hazards',
  templateUrl: './hazards.component.html',
  styleUrls: ['./hazards.component.scss']
})
export class HazardsComponent implements OnInit {

  hazards$: Observable<HazardDetails[]>;
  baseUrl$: string;

  private tourIsCompleteSubject = new BehaviorSubject<boolean>(false);
  tourIsComplete: Observable<boolean> = this.tourIsCompleteSubject.asObservable();
  tourIsLoaded: boolean = false;
  mobileSize = window.innerWidth <= 768
  
  private list;

  public hazardsTour: GuidedTour = {
    tourId: 'hazards-tour',
    useOrb: false,
    steps: [
        {
            title: 'Hazards',
            selector: '#hazards-list',
            content: 'Choosing the right FR fabric for your program depends on knowing the hazards your workers face.',
            orientation: Orientation.Top
        },
        {
          title: 'View Details',
          selector: '.hazard-details-btn',
          content: 'Select "View Details" to read the full hazard description and see applicable standards.',
          orientation: window.innerWidth <= 767 ? Orientation.Top : Orientation.TopLeft
      }
    ],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };

  constructor(private hazardService: HazardService, private guidedTourService: GuidedTourService, private tourService: TourService, private elRef: ElementRef) { }

  ngOnInit(): void {
    this.hazards$ = this.hazardService.getAllHazards().pipe(
      map( hazards => hazards)
    );

    this.baseUrl$ = `${environment.apiUrl}`;

    this.tourIsCompleteSubject.next(this.tourService.getHazardsTourComplete());
    this.list = this.elRef.nativeElement.querySelector("#hazards-list");
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.hazardsTour);
  }

  public startTour(index: number): void {
    var count: number = -1;
    this.hazards$.subscribe(hazards => count = hazards.length);

    var complete = false;
    this.tourIsComplete.subscribe(bool => complete = bool);

    if (!complete && !this.tourIsLoaded && index == count - 1) {
      this.tourIsLoaded = true;
      setTimeout(() => {
        this.guidedTourService.startTour(this.hazardsTour);
      }, 100);
    }
  }

  private tourComplete(): void {
    this.tourIsCompleteSubject.next(true);
    this.tourService.setHazardsTourComplete(true)
  }

}
