import { Component, OnInit } from '@angular/core';
import { ProductLineService } from 'src/services/product-line.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LineDetails } from '../models/line.model';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';

@Component({
  selector: 'app-product-lines-expanded',
  templateUrl: './product-lines-expanded.component.html',
  styleUrls: ['./product-lines-expanded.component.scss']
})
export class ProductLinesExpandedComponent implements OnInit {

  activeProductLine$: Observable<LineDetails>;
  baseUrl$:string;
  private tourIsComplete: boolean;
  private tourIsLoaded: boolean = false;

  public productLineExpandedTour: GuidedTour = {
    tourId: 'product-line-expanded-tour',
    useOrb: false,
    steps: [
        {
          title: 'Categories',
          selector: '#product-line-description',
          content: 'At Westex - A Milliken Brand, we offer an extensive portfolio of AR/FR fabrics that include various types of fibers, yarns and FR technologies.',
          orientation: Orientation.Top
        }
    ],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };

  constructor(private productLineService: ProductLineService, private route: ActivatedRoute, private guidedTourService: GuidedTourService, private tourService: TourService) { }

  ngOnInit(): void {
    this.activeProductLine$ = this.route.params.pipe(
      map(params => params['productLineId']),
      switchMap(productLineId => this.productLineService.getProductLine(productLineId))
    );

    this.baseUrl$ = `${environment.apiUrl}`;

    this.tourIsComplete = this.tourService.getProductLineExpandedTourComplete()
  }

  public startTour(): void {
    if (!this.tourIsComplete && !this.tourIsLoaded) {
      this.tourIsLoaded = true;
      setTimeout(() => {
        this.guidedTourService.startTour(this.productLineExpandedTour);
      }, 100);
    }
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.productLineExpandedTour);
  }

  private tourComplete(): void {
    this.tourService.setProductLineExpandedTourComplete(true);
  }

}
