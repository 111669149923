<ngx-guided-tour></ngx-guided-tour>

<p-dialog header="Standards" [(visible)]="showStandardDescriptions$" [modal]="true" styleClass="dialog-modal">
  <div *ngIf="activeProduct$ | async as activeProduct">
    <span *ngFor="let standard of activeProduct.standards" class="row">
      <div *ngIf="standard.image as image; else elseBlock">
        <img [src]="baseUrl$ + image.url" class="applicable-img">
      </div>
      <ng-template #elseBlock>
        <img src="https://via.placeholder.com/45" class="applicable-img">
      </ng-template>
      <div class="col">
        <div class="text-strong">{{ standard.name }}</div>
        <p>{{ standard.description }}</p>
      </div>
    </span>
  </div>
</p-dialog>

<section class="mb-4 row mx-0">
    <h1 class="section-title justify-content-start">Product Styles</h1>
</section>

<section class="wx-details">
    <div class="h5 text-border-primary mb-3 pb-3 d-flex align-items-center">
        <span>{{ (activeProduct$ | async)?.line?.name }} </span>
        <button type="button" class="wx-close">
          <span class="wx-close-icon" aria-hidden="true" [routerLink]="['/product-catalog', (activeProduct$ | async)?.line?.id]"></span>
        </button>
    </div>
    <div class="row mb-7">
        <div class="col-md">
            <!--
            <p-galleria [(value)]="images" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '640px'}" [numVisible]="5">
                <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc" style="width: 100%;" />
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                    <div class="p-grid p-nogutter p-justify-center">
                        <img [src]="item.thumbnailImageSrc" />
                    </div>
                </ng-template>
            </p-galleria>
            -->
            <!--<div class="mb-3">
              <button role="button" class="btn btn-accent d-inline d-md-none w-100" >
                <div ></div>
                Order Sample
                <ng-template >Order Sample</ng-template>
                <ng-template >Remove</ng-template>
                </button>
            </div>-->
            <div class="wx-details-img">
                <div *ngIf="activeProduct$ | async as activeProduct; else noActive">
                  <div *ngIf="activeProduct.image as image; else elseBlock">
                      <img [src]="baseUrl$ + image.url" class="w-100 h-100 object-fit-cover">
                  </div>
                  <ng-template #elseBlock>
                      <img src="https://via.placeholder.com/150" class="w-100 h-100 object-fit-cover">
                  </ng-template>
                </div>
                <ng-template #noActive>
                  <img src="https://via.placeholder.com/150" class="w-100 h-100 object-fit-cover">
                </ng-template>
            </div>
        </div>
        <div class="col-md d-flex flex-column justify-content-between">
          <div *ngIf="horSidebar$" class="uniform-hor-sidebar">
            <app-garment-selector></app-garment-selector>
          </div>
            <div *ngIf="activeProduct$ | async as activeProduct" class="h-100 d-flex flex-column justify-content-between align-items-center">
                {{startTour()}}
                <div id="product-description">
                    <div class="mb-5">
                        <div class="h3 text-black">{{ activeProduct.name }}</div>
                        <p>{{ activeProduct.description }}</p>
                    </div>
                    <div class="mb-5">
                        <div *ngIf="activeProduct.standards.length > 0" class="h6 text-black">Standards</div>
                        <div class="row">
                            <span *ngFor="let standard of activeProduct.standards" class="mr-2 d-flex flex-wrap">
                                <div *ngIf="standard.image as image; else elseBlock">
                                    <img [src]="baseUrl$ + image.url" class="applicable-img cursor-pointer" (click)="showStandardDescriptions$ = true">
                                </div>
                                <ng-template #elseBlock>
                                    <img src="https://via.placeholder.com/45" class="applicable-img cursor-pointer">
                                </ng-template>
                            </span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="swatchInCart(activeProduct) | async; else notInCart">
                  <button role="button" class="btn btn-accent wx-details-button d-md-inline" (click)="removeFromCart(activeProduct)">
                    <div ></div>  
                    Remove From Cart                  
                  </button>                
                </ng-container>
                <ng-template #notInCart>
                  <ng-container>
                    <button role="button" class="btn btn-accent wx-details-button d-md-inline" (click)="addToCart(activeProduct)">
                      <div ></div>  
                      Add to Cart                  
                    </button>    
                  </ng-container>
                </ng-template>
            </div>
        </div>
    </div>
</section>

<section *ngIf="activeProduct$ | async as activeProduct" class="mb-6">
  <div class="h5 text-black">Properties</div>
  <div *ngIf="(activeRegion$ | async) == 1; else elseNotUS">
    <p>Weight: {{ activeProduct.imperialWeight }} oz/yd<sup>2</sup></p>
  </div>
  <ng-template #elseNotUS>
    <p>Weight: {{ activeProduct.metricWeight }} g/m<sup>2</sup></p>
  </ng-template>
  <p>{{ activeProduct.properties }}</p>
</section>

<div id="product-details-resources">
  <section>
    <div *ngIf="(activeProduct$ | async)?.videos as resources">
      <div *ngIf="resources.length > 0" class="h5 text-primary">Video Resources</div>
    </div>
    <div *ngIf="(activeProduct$ | async)?.videos as resources">
      <div *ngIf="resources.length > 0">
        <p-carousel [value]="resources" [numVisible]="numProductsShown" [numScroll]="numProductsShown" [circular]="false" [responsiveOptions]="responsiveOptions" [class.hidden-pagination]="numProductsShown >= resources.length">
          <ng-template let-resource pTemplate="item">
            <div class="wx-card-fluid" style="max-width: 170px;">
              <a class="wx-card-inner" [attr.href]="resource.url" target="_blank" [title]="resource.name">
                <div *ngIf="resource.image as image; else elseBlock">
                  <img [src]="baseUrl$ + image.url" class="wx-card-img">
                </div>
                <ng-template #elseBlock>
                  <img src="https://via.placeholder.com/170x220" class="wx-card-img">
                </ng-template>
                <div class="wx-card-body">
                  <h6 class="wx-card-title-resource">{{ resource.name }}</h6>
                  <p class="card-text">{{ resource.description }}</p>
                </div>
              </a>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </section>

  <section class="mt-2">
    <div *ngIf="(activeProduct$ | async)?.resources as resources">
      <div *ngIf="resources.length > 0" class="h5 text-primary">Resources to Download</div>
    </div>
    <div *ngIf="(activeProduct$ | async)?.resources as resources">
      <div *ngIf="resources.length > 0">
        <p-carousel [value]="resources" [numVisible]="numProductsShown" [numScroll]="numProductsShown" [circular]="false" [responsiveOptions]="responsiveOptions" [class.hidden-pagination]="numProductsShown >= resources.length">
          <ng-template let-resource pTemplate="item">
            <div class="wx-card-fluid" style="max-width: 170px;">
              <div class="wx-card-inner">
                <div *ngIf="resource.image as image; else elseBlock">
                  <img [src]="baseUrl$ + image.url" class="wx-card-img">
                </div>
                <ng-template #elseBlock>
                  <img src="https://via.placeholder.com/170x220" class="wx-card-img">
                </ng-template>
                <div class="wx-card-body">
                  <h6 class="wx-card-title-resource">{{ resource.name }}</h6>
                  <p class="card-text">{{ resource.description }}</p>
                  <a class="btn btn-primary btn-sm" [attr.href]="resource.url" target="_blank"
                    [title]="resource.name">Download</a>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </section>
</div>