<p-confirmDialog #cd>
    <p-footer>
      <button type="button" pButton label="Cancel" class="p-button-secondary" (click)="cd.reject()"></button>
      <button type="button" pButton label="Yes" (click)="cd.accept()"></button>
    </p-footer>
</p-confirmDialog>

<section class="mb-4 row mx-0">
    <h1 class="section-title justify-content-start">My Spec Sheets</h1>
</section>

<section>
    <div class="row no-gutters position-relative">
      <div class="uniform-tools">
        <button class="btn btn-circle btn-outline-primary" style="font-size: 5pt;" (click)="addSpecSheetNotification()"></button>
      </div>
    </div>
</section>

<section>
    <div class="wx-sidebar-list-container">
        <ul class="wx-sidebar-list mx-3 mt-5">
            <li *ngFor="let specSheet of specSheets$" class="garment on py-3 m-0 ssd-list-item cursor-pointer">
                <div class="ssd-text-area">
                    <div class="garment-title">
                        <div class="w-100" (click)="changeActiveSpecSheet(specSheet.id)">
                            <span>{{ specSheet.name }} | {{ specSheet.garmentPairings.length }}</span>
                        </div>
                        <button *ngIf="specSheets$.length > 1" (click)="deleteSpecSheet(specSheet.id)" class="trash"><span class="trash-icon"></span></button>
                    </div>
                    <div class="garment-text">
                        <p class="mb-0"></p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>
