import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SpecSheetService } from 'src/services/spec-sheet.service';
import { GarmentPairingFull } from '../models/garment-pairing-full';
import { GarmentPairing } from '../models/garment-pairing.model';
import { GarmentDetails } from '../models/garment.model';
import { StyleDetails } from '../models/style.model';

@Component({
  selector: 'app-spec-sheet-export',
  templateUrl: './spec-sheet-export.component.html',
  styleUrls: ['./spec-sheet-export.component.scss']
})
export class SpecSheetExportComponent implements OnInit {
  apiUrl = environment.apiUrl;  
  
  genders = ['male', 'female'];

  constructor(private activatedRoute: ActivatedRoute, private specSheetService: SpecSheetService) { }

  private _pairings$: Observable<GarmentPairingFull[]>;
  get pairings$(): Observable<GarmentPairingFull[]> {
    if(!this._pairings$) {
      this._pairings$ = this.activatedRoute.queryParams.pipe(
        map(params => {
          if(!!params['garmentPairings']) {
            return this.deserializePairings(params['garmentPairings']);
          }
          else {
            return [];
          }
        }),
        switchMap((pairings) => this.specSheetService.populateGarmentPairings(pairings)),
        shareReplay(1)
      );
    }
    return this._pairings$;
  }

  name$: Observable<string> = this.activatedRoute.queryParams.pipe(
    map(params => {
      return !!params['name'] ? params['name'] : 'Spec Sheet';
    })
  );

  ngOnInit(): void {
  }

  
  deserializePairings(base64): GarmentPairing[] {
    let utf8String = atob(base64);

    let pairings: GarmentPairing[] = [];

    let pairingStrings = utf8String.split('&');

    for(let pairingString of pairingStrings) {
      let values = pairingString.split(':');
      
      let [garmentString, styleString] = values;

      let garmentId = parseInt(garmentString.split('=')[1]);
      let styleId = parseInt(styleString.split('=')[1]);

      pairings.push({garmentId, styleId});
    }    

    return pairings;
  }

  isGarmentPaired(inputGarmentId: number, garmentIdToDisplay: number): boolean {
    return inputGarmentId === garmentIdToDisplay;
  }
}
