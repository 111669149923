import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppSettingsService } from 'src/services/app-settings.service';
import { CategoryFilterService } from 'src/services/category-filter.service';
import { GarmentFilterService } from 'src/services/garment-filter.service';
import { GarmentService } from 'src/services/garment.service';
import { LineFilterService } from 'src/services/line-filter.service';
import { ProductLineService } from 'src/services/product-line.service';
import { RegionFilterService } from 'src/services/region-filter.service';
import { SpecialFilterService } from 'src/services/special-filter.service';
import { StandardService } from 'src/services/standard.service';
import { CategoryDetails } from '../models/category.model';
import { Garment, GarmentDetails } from '../models/garment.model';
import { LineDetails } from '../models/line.model';
import { RegionDetails } from '../models/region.model';
import { SpecialDetails } from '../models/special.model';
import { StandardDetails } from '../models/standard.model';
import { StyleDetails } from '../models/style.model';
import { filterCategories, filterGarments, filterLines, filterRegions, filterSpecials, filterStandards } from '../operators';

@Component({
  selector: 'app-browse-modal',
  templateUrl: './browse-modal.component.html',
  styleUrls: ['./browse-modal.component.scss']
})
export class BrowseModalComponent implements OnInit {

  title: string;
  garmentId: number;
  garment$: Observable<Garment>;

    /**
   * Filters.
   */
  filterCategories$: Observable<CategoryDetails[]>;
  allCategories$: Observable<CategoryDetails[]>;

  filterSpecials$: Observable<SpecialDetails[]>;
  allSpecials$: Observable<SpecialDetails[]>;

  filterLines$: Observable<LineDetails[]>;
  allLines$: Observable<LineDetails[]>;

  filterStandards$: Observable<StandardDetails[]>;
  regionStandards$: Observable<StandardDetails[]>;

  filterGarments$: Observable<GarmentDetails[]>;
  allGarments$: Observable<GarmentDetails[]>;

  filteredStyles$: Observable<StyleDetails[]>;
  filteredLines$: Observable<LineDetails[]>;

  filterRegions$: Observable<RegionDetails[]>;
  allRegions$: Observable<RegionDetails[]>;
  allStandards$: Observable<StandardDetails[]>;

  constructor(private router: Router,
    private garmentService: GarmentService,
    public bsModalRef:BsModalRef,
    private appSettingsService: AppSettingsService,
    private standardService: StandardService,
    private productLineService: ProductLineService,
    public categoryFilters: CategoryFilterService,
    public specialFilters: SpecialFilterService,
    public lineFilters: LineFilterService,
    public garmentFilters: GarmentFilterService,
    public regionFilters: RegionFilterService) { }

  ngOnInit(): void {
    this.garment$ = this.garmentService.get(this.garmentId)

    this.allCategories$ = this.productLineService.getCategories();
    this.allSpecials$ = this.productLineService.getSpecials();
    this.allLines$ = this.productLineService.getProductLines();
    this.allGarments$ = this.garmentService.getAllGarments();
    this.allRegions$ = this.appSettingsService.getAllRegions();
    this.allStandards$ = this.standardService.getAllStandards();


    this.filterStandards$ = combineLatest([
      this.standardService.getAllStandards(),
      this.standardService.activeStandards$
    ]).pipe(
      map(([allStandards, activeStandardIds]) => allStandards.filter(standard => activeStandardIds.includes(standard.id)))
    );

    this.filterCategories$ = combineLatest([
      this.allCategories$,
      this.categoryFilters.changes$
    ]).pipe(
      map(([categories, filterCategoryIds]) => categories.filter(category => filterCategoryIds.includes(category.id)))
    );

    this.filterSpecials$ = combineLatest([
      this.allSpecials$,
      this.specialFilters.changes$
    ]).pipe(
      map(([specials, filterSpecialIds]) => specials.filter(special => filterSpecialIds.includes(special.id)))
    );

    this.filterLines$ = combineLatest([
      this.allLines$,
      this.lineFilters.changes$
    ]).pipe(
      map(([lines, filterLineIds]) => lines.filter(line => filterLineIds.includes(line.id)))
    );

    this.filterGarments$ = combineLatest([
      this.allGarments$,
      this.garmentFilters.changes$
    ]).pipe(
      map(([garments, filterGarmentIds]) => garments.filter(garment => filterGarmentIds.includes(garment.id)))
    );

    this.filterRegions$ = combineLatest([
      this.allRegions$,
      this.regionFilters.changes$
    ]).pipe(
      map(([regions, filterRegionIds]) => regions.filter(region => filterRegionIds.includes(region.id)))
    );

    this.filteredStyles$ = this.productLineService.getProductStyles().pipe(
      filterStandards(this.standardService.activeStandards$),
      filterCategories(this.categoryFilters.changes$),
      filterLines(this.lineFilters.changes$),
      filterGarments(this.garmentFilters.changes$),
      filterSpecials(this.specialFilters.changes$),
      filterRegions(this.allStandards$,this.regionFilters.changes$)
    );

  }

  filterGarmentHasRecords(garmentId: number) {
    return this.filteredStyles$.pipe(
      filterGarments(of([garmentId])),
      map(lines => lines.length > 0)
    );
  }

  close() {
    this.bsModalRef.hide();
  }

  browse(id: number) {
    this.garmentFilters.reset();

    this.filterGarmentHasRecords(id).pipe(
      take(1)
    ).subscribe(canAddFilter => {
        if (!this.garmentFilters.has(id) && canAddFilter) {
          this.garmentFilters.add(id);
        }
      }
    )

    this.bsModalRef.hide();
    this.router.navigateByUrl('/product-catalog');
  }
}
