<ngx-guided-tour></ngx-guided-tour>

<section class="mb-4 row mx-0 ml-md-5">
    <h1 class="section-title justify-content-start">Standards</h1>
</section>

<section class="mx-0 ml-md-5">
  <div id="standards-filters">
    <h6 class="text-border-primary">Filters</h6>
    <div class="mb-2">
      <div class="btn-group mr-4" dropdown>
        <button dropdownToggle type="button" class="btn btn-sm btn-link px-0" aria-controls="dropdown-basic">
            Regions <fa-icon class="ml-1" [icon]="dropdownCaretIcon"></fa-icon>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngFor="let region of allRegions$ | async">
            <a [ngClass]="{'disabled bg-light': regionFilters.has(region.id) || !(filterRegionHasRecords(region.id) | async)}" class="dropdown-item" [routerLink]="" (click)="regionFilters.add(region.id)">{{region.name}}</a>
          </li>
        </ul>
      </div>
    </div>
    <wx-chip *ngFor="let region of filterRegions$ | async" class="mr-2 mb-2" label="Region" [value]="region.id" [name]="region.name" (remove)="regionFilters.remove(region.id)">{{startTour()}}</wx-chip>
  </div>
  <div class="row">
      <div *ngFor="let standard of (standards$ | async)" class="wx-card">
          <div class="wx-card-inner cursor-pointer" [routerLink]="['/standard-details', standard.id]" style="cursor: pointer;">
            <div *ngIf="standard.image as image; else elseBlock">
              <img [src]="baseUrl + image.url" class="wx-card-img">
            </div>
            <ng-template #elseBlock>
                <img src="https://via.placeholder.com/150" class="wx-card-img">
            </ng-template>
              <div class="wx-card-body">
                  <h6 class="wx-card-title">{{ standard.name }}</h6>
                  <p class="card-text">{{ standard.description }}</p>
              </div>
          </div>
          <button class="btn btn-accent btn-sm btn-block standard-read-more-btn" [routerLink]="['/standard-details', standard.id]">Read More</button>
          <button class="btn btn-primary btn-sm btn-block standard-btn standard-filter-products-btn" (click)="toggleActiveStandard(standard.id)" [disabled]="!(filterStyleHasRecords(standard.id) | async)">
              {{ !(filterStyleHasRecords(standard.id) | async) ? 'N/A' : ((activeStandards$ | async).includes(standard.id) ? 'Remove' : 'Filter Products') }}
          </button>
      </div>
  </div>
</section>

<section class="mt-4 text-center" [class.mb-tour]="tourIsLoaded && !tourIsComplete && mobileSize">
    <button class="btn btn-primary btn-sm standard-btn px-7 standard-view-filtered-btn" [routerLink]="['/product-catalog']">
        View Filtered Products
    </button>
</section>
