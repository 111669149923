<nav class="navbar navbar-expand-lg navbar-dark bg-primary wx-navbar">
  <a class="navbar-brand" [routerLink]="['/dashboard']">
    <img src="assets/images/wx-logo.svg" alt="Westex">
  </a>
  <div class="d-flex flex-column ml-auto">
      <div class="navbar-toolbar d-flex flex-shrink-0 align-items-center ml-auto">
        <a class="navbar-tool" [routerLink]="['/cart', 'view']">
            <div class="navbar-tool-icon-box" >
                <img class="img-fluid" src="assets/images/shopping-cart.svg">
                <ng-container *ngIf="selectedSwatches$ | async as selectedSwatches">
                    <span class="badge badge-pill badge-secondary shopping-badge" *ngIf="selectedSwatches > 0">{{selectedSwatches}}</span>
                </ng-container>
            </div>
        </a>
        <a class="navbar-tool" [routerLink]="['/contact-us']">
            <div class="navbar-tool-icon-box"><img class="img-fluid" src="assets/images/phone.svg"></div>
        </a>
        <a class="navbar-tool" [routerLink]="['/regions']">
            <div class="navbar-tool-icon-box"><img class="img-fluid" src="assets/images/globe.svg" id="globe-icon"></div>
        </a>
        <a class="navbar-tool" [routerLink]="['/settings']">
            <div class="navbar-tool-icon-box"><img class="img-fluid" src="assets/images/cog.svg" id="cog-icon"></div>
        </a>
    </div>
    <div class="collapse navbar-collapse">
        <ul class="navbar-nav" [ngClass]="{'gray': grayBg}">
            <li class="nav-item" [class.active]="hazardActive" title="Hazards">
              <a class="nav-link" [routerLink]="['/hazards']">
                  <span class="icon"><img src="assets/images/hazards.svg"></span>
              </a>
            </li>
            <li class="nav-item" [class.active]="standardActive" title="Standards">
                <a class="nav-link" [routerLink]="['/standards']">
                    <span class="icon"><img src="assets/images/standards.svg"></span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.5 75.77" width="100%">
                    <defs>
                        <clipPath id="navTopClip" clipPathUnits="objectBoundingBox" transform="scale(0.0045558 0.0131978)">
                            <path d="M109.75,0C47.31.29,67.6,69.54,0,75.37v.4H219.5v-.4C151.9,69.54,172.19.29,109.75,0Z"/>
                        </clipPath>
                    </defs>
                </svg>
                </a>
            </li>
            <li class="nav-item" [class.active]="productLinesActive" title="Product Lines">
                <a class="nav-link" [routerLink]="['/product-lines']">
                    <span class="icon"><img src="assets/images/products.svg"></span>
                </a>
            </li>
            <li class="nav-item" [class.active]="productCatalogActive" title="Product Catalog">
                <a class="nav-link" [routerLink]="['/product-catalog']">
                    <span class="icon"><img src="assets/images/catalog.svg"></span>
                </a>
            </li>
            <li class="nav-item" [class.active]="uniformBuilderActive" title="Uniform Builder">
                <a class="nav-link" [routerLink]="['/uniform-builder']">
                    <span class="icon"><img src="assets/images/uniform.svg"></span>
                </a>
            </li>
        </ul>
    </div>
  </div>
</nav>

