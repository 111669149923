import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { StandardService } from 'src/services/standard.service';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductLineService } from 'src/services/product-line.service';
import { AppSettingsService } from 'src/services/app-settings.service';
import { StyleDetails } from '../models/style.model';
import { StandardDetails } from '../models/standard.model';
import { filterRegions, filterStandards } from '../operators';
import { Region, RegionDetails } from '../models/region.model';
import { RegionFilterService } from 'src/services/region-filter.service';
import { faChevronDown, faMinus, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';

@Component({
  selector: 'app-standards',
  templateUrl: './standards.component.html',
  styleUrls: ['./standards.component.scss']
})
export class StandardsComponent implements OnInit {
  activeStandards$: Observable<number[]>;

  filteredStyles$: Observable<StyleDetails[]>;
  standards$: Observable<StandardDetails[]>;

  allRegions$: Observable<RegionDetails[]>;
  filterRegions$: Observable<RegionDetails[]>;

  dropdownCaretIcon = faChevronDown;
  showFilterIcon = faPlus;
  hideFilterIcon = faMinus;
  searchIcon = faSearch;

  baseUrl = environment.apiUrl;
  tourIsComplete: boolean;
  tourIsLoaded: boolean = false;

  mobileSize = window.innerWidth <= 767
  
  private mobileSteps = [
    {
      title: 'Filters',
      selector: '#standards-filters',
      content: 'Add or remove region criteria to filter standards that will match your needs.',
      orientation: Orientation.Bottom
    },
    {
      title: 'Read More',
      selector: '.standard-read-more-btn',
      content: 'Select "Read More" to view more details about a specific standard, see applicable hazards and download relevant resources.',
      orientation: window.innerWidth <= 767 ? Orientation.Top : Orientation.TopLeft
    },
    {
      title: 'Filter Products',
      selector: '.standard-filter-products-btn',
      content: 'Select "Filter Products" to add the standard as a filter when you’re ready to view our products.',
      orientation: window.innerWidth <= 767 ? Orientation.Top : Orientation.TopLeft
    }
  ]

  private fullSteps = [
    {
      title: 'Selected Standards',
      selector: '.wx-sidebar-container',
      content: 'The Standards sidebar shows your standard selections along with standards from other regions.',
      orientation: Orientation.Right
    }
  ].concat(this.mobileSteps)

  public standardsTour: GuidedTour = {
    tourId: 'standards-tour',
    useOrb: false,
    steps: [],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };

  constructor(private standardService: StandardService,
    private productLineService: ProductLineService,
    private appSettingsService: AppSettingsService,
    public regionFilters: RegionFilterService,
    private guidedTourService: GuidedTourService,
    private tourService: TourService) { }

  ngOnInit(): void {

    this.standards$ = combineLatest([this.standardService.getAllStandards(), this.regionFilters.changes$]).pipe
    (
      map(([standards, regionIds]) => standards.filter(standard => regionIds.length == 0 || standard.regions?.some(r => regionIds.includes(r.id)))),
      map( standards => standards.sort(function (a, b) {
        return a.regions[0].id - b.regions[0].id;
      }))
    )

    this.activeStandards$ = this.standardService.activeStandards$.pipe();

    this.allRegions$ = this.appSettingsService.getAllRegions();

    this.filteredStyles$ = this.productLineService.getProductStyles().pipe(
      filterStandards(this.standardService.activeStandards$)
    );

    this.filterRegions$ = combineLatest([
      this.allRegions$,
      this.regionFilters.changes$
    ]).pipe(
      map(([regions, filterRegionIds]) => regions.filter(region => filterRegionIds.includes(region.id)))
    );

    this.tourIsComplete = this.tourService.getStandardsTourComplete()
  }

  toggleActiveStandard(id: number){
    this.standardService.toggleActiveStandard(id);
  }


  filterStyleHasRecords(standardId: number) {
    return this.filteredStyles$.pipe(
      filterStandards(of([standardId])),
      map(styles => styles.length > 0)
    );
  }

  filterRegionHasRecords(regionId: number) {
    return this.filteredStyles$.pipe(
      filterRegions(this.standards$,of([regionId])),
      map(styles => styles.length > 0)
    );
  }

  public startTour(): void {
    if (!this.tourIsComplete && !this.tourIsLoaded) {
      if (window.innerWidth <= 991) {
        this.standardsTour.steps = this.mobileSteps;
      } else {
        this.standardsTour.steps = this.fullSteps;
      }

      this.tourIsLoaded = true;
      setTimeout(() => {
        this.guidedTourService.startTour(this.standardsTour);
      }, 100);
    }
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.standardsTour);
  }

  private tourComplete(): void {
    this.tourIsComplete = true;
    this.tourService.setStandardsTourComplete(true);
  }

}
