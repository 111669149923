<ng-container *ngIf="!(exporting$ | async)">
  <p-toast [baseZIndex]="2000" position="bottom-center"></p-toast>

  <app-header *ngIf="showHeader"></app-header>
  
  <div id="containerBg" class="d-flex flex-grow-1">
    <div id="roundedCorners" class="container-fluid">
      <div class="row" [ngClass]="{'h-100': inCart}">
        <app-selected-standards *ngIf="showSelectedStandards" class="col-lg-3 wx-sidebar-container standards"></app-selected-standards>
      
        <app-garment-selector *ngIf="showGarmentSelector" class="col-lg-3 wx-sidebar-container uniform"></app-garment-selector>
      
        <main class="col-md mx-auto wx-content" [ngClass]="{'dashboard-full': isDashboard, 'pb-10': !inCatalog && !isUniformBuilder && !inCart, 'pt-4': !inCatalog && !inCart, 'col-lg-6': showThreeColumn, 'p-0': inCatalog || inCart, 'pt-lg-6': !inCart, 'pb-lg-4': !inCart}" role="main">
          <router-outlet></router-outlet>
        </main>
      
        <app-resource-sidebar *ngIf="showResourcesSidebar" class="col-lg-3 wx-sidebar-container resources"></app-resource-sidebar>
      
        <div *ngIf="showEmptyRightColumn" class="col-lg-3 wx-sidebar-container resources"></div>
      </div>
    </div>
  </div>
  
  <app-footer *ngIf="showFooter"></app-footer>
</ng-container>

<app-spec-sheet-export *ngIf="exporting$ | async"></app-spec-sheet-export>