import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { Hazard, HazardDetails } from 'src/app/models/hazard.model';

@Injectable({
  providedIn: 'root'
})
export class HazardService {

  private hazards$: Observable<HazardDetails[]>;

  constructor(private http: HttpClient) { }


  getAllHazards() : Observable<HazardDetails[]>{
    if(!this.hazards$) {
      this.hazards$ = this.http.get<HazardDetails[]>(`${environment.apiUrl}` + "/hazards").pipe(
        shareReplay(1)
      );
    }

    return this.hazards$;
  }

  get(hazardId: number) : Observable<HazardDetails>{
    return this.getAllHazards().pipe(
      map(hazards => hazards.find(hazard => hazard.id == hazardId))
    );
  }

}
