export class AppSettings {
  region:number;
  language:number;
  gender:number;

  constructor(){
    this.region = 1;
    this.gender = 1;
  }
}
