<ngx-guided-tour></ngx-guided-tour>

<section class="mb-4 row mx-0">
    <h1 class="section-title justify-content-start">Product Lines</h1>
</section>

<section class="wx-details">
    <div class="h5 text-border-primary mb-3 pb-3 d-flex align-items-center">
        <span>{{ (activeProductLine$ | async)?.category?.name }}</span>
        <button type="button" class="wx-close">
          <span class="wx-close-icon" aria-hidden="true" [routerLink]="['/product-lines', (activeProductLine$ | async)?.category?.id]"></span>
        </button>
    </div>
    <div class="row mb-7">
        <div class="col-md-4">
            <div *ngIf="(activeProductLine$ | async) as activeProductLine" class="wx-details-img">
                <div *ngIf="activeProductLine.image as image; else elseBlock" class="text-center">
                    <img [src]="baseUrl$ + image.url" class="object-fit-contain wx-details-img-element-product-line">
                </div>
                <ng-template #elseBlock class="text-center">
                    <img src="https://via.placeholder.com/150" class="object-fit-contain wx-details-img-element-product-line">
                </ng-template>
            </div>
        </div>
        <div class="col-md-8 d-flex flex-column justify-content-between">
            <div *ngIf="activeProductLine$ | async as activeProductLine" class="h-100 d-flex flex-column justify-content-between align-items-center">
                {{startTour()}}
                <div>
                    <div class="mb-5" id="product-line-description">
                        <div class="h3 text-black">{{ activeProductLine.name }}</div>
                        <p>{{ activeProductLine.description }}</p>
                    </div>
                </div>
                <button role="button" class="btn btn-accent wx-details-button" [routerLink]="['/product-catalog', activeProductLine.id]">
                    Explore in Catalog
                </button>
            </div>
        </div>
    </div>
</section>
