
<div class="modal-content">
  <div class="uniform-modal-circle"><fa-icon class="uniform-modal-icon" [icon]="chevronUpIcon" size="2x"></fa-icon></div>
  <div class="modal-body pt-5">
    <div *ngIf="getStyleDetails(garmentPairing.styleId) | async as style" class="uniform-modal">
      <span *ngIf="getGarmentDetails(garmentPairing.garmentId) | async as garment" class="uniform-modal-title">{{ garment.name }} | {{ style.name
        }}</span>
        <div>
          <p class="pb-5">{{ style.description }}</p>
        </div>
        <div>
          <button class="btn btn-primary wx-details-button standard-btn mt-5" (click)="exploreInCatalog(style.id)"> Explore in catalog</button>
        </div>
    </div>
</div>
<div *ngIf="getStyleDetails(garmentPairing.styleId) | async as style" class="uniform-modal-swatch-container" [ngStyle]="{background: 'url(' + (style.image ? baseUrl + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
</div>
