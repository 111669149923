<div class="header">
    <img style="height: 140px; padding: 30px;" src="assets/images/wx-logo.svg">      
</div>
<div class="mt-3 ml-5" style="font-size: xxx-large; color: #08003D;">
    {{name$ | async}}
</div>
<div>
    <hr style="border-color: #08003D;" />
    <div class="w-100" *ngFor="let pairing of pairings$ | async" style="page-break-inside: avoid;">
        <div class="d-flex mb-4">
            <div class="style-img flex-shrink-0 mr-4">
                <img style="height: 250px;" src="{{apiUrl}}{{pairing.style.image.url}}" />
            </div>
            <div class="flex-grow-1" style="margin-right: 100px;">
                <div class="font-weight-bold mb-2" style="font-size: x-large;">{{pairing.style.name}} | Paired with {{pairing.garment.name}}</div>
                <div class="mb-3" style="font-size: large; color: #58585A">
                    {{pairing.style.description}}
                </div>
                <div class="font-weight-bold mb-2" style="font-size: x-large;">Standards</div>
                <div class="d-flex" style="font-size: large;">
                    <img *ngFor="let standard of pairing.style.standards" style="height:75px;" src="{{apiUrl}}{{standard.image.url}}"/>
                </div>
            </div>
            <div class="flex-grow-1 flex-shrink-0 d-flex justify-content-end">
              <div class="d-flex flex-column justify-content-center" *ngFor="let gender of genders">
                <div class="mannequin {{ gender }}">
                  <img src="assets/images/{{ gender }}/base.png" class="img-fluid">
                  <div id="hair" class="garment" [class.d-none]="(isGarmentPaired(pairing.garment.id, 6)) || showPonytail$ == false" ><img class="img-fluid" src="assets/images/female/hair.png"></div>
                  <div id="boot" class="garment" [class.d-none]="(isGarmentPaired(pairing.garment.id, 5)) == false && (isGarmentPaired(pairing.garment.id, 4)) == false">
                    <img class="img-fluid" src="assets/images/{{ gender }}/boot.png">
                  </div>
                  <div id="mask" class="garment {{ gender.substr(0, 1) }}-mask" [class.d-none]="(isGarmentPaired(pairing.garment.id, 6)) == false" [class.active]="(selectedGarmentId$ | async) == 6">
                    <button class="btn-percent d-none" disabled>90%</button>
                    <span class="highlight">
                      <img class="img-fluid" src="assets/images/{{ gender }}/mask.png">
                    </span>
                  </div>
                  <div id="shortSleeve" class="garment {{ gender.substr(0, 1) }}-s-sleeve" [class.d-none]="(isGarmentPaired(pairing.garment.id, 1)) == false || ((isGarmentPaired(pairing.garment.id, 2)) && (selectedGarmentId$ | async) != 1)" [class.active]="(selectedGarmentId$ | async) == 1">
                    <button class="btn-percent d-none" disabled>80%</button>
                    <span class="highlight">
                      <img class="img-fluid" src="assets/images/{{ gender }}/shortSleeve.png">
                    </span>
                  </div>
                  <div id="pant" class="garment {{ gender.substr(0, 1) }}-pant" [class.d-none]="(isGarmentPaired(pairing.garment.id, 4)) == false || ((isGarmentPaired(pairing.garment.id, 5)) && (selectedGarmentId$ | async) != 4)" [class.active]="(selectedGarmentId$ | async) == 4">
                    <button class="btn-percent d-none" disabled>80%</button>
                    <span class="highlight">
                      <img class="img-fluid" src="assets/images/{{ gender }}/pant.png">
                    </span>
                  </div>
                  <div id="longSleeve" class="garment {{ gender.substr(0, 1) }}-l-sleeve" [class.d-none]="(isGarmentPaired(pairing.garment.id, 2)) == false" [class.active]="(selectedGarmentId$ | async) == 2">
                    <button class="btn-percent d-none" disabled>80%</button>
                    <span class="highlight">
                      <img class="img-fluid" src="assets/images/{{ gender }}/longSleeve.png">
                    </span>
                  </div>
                  <div id="jacket" class="garment {{ gender.substr(0, 1) }}-jacket" [class.d-none]="(isGarmentPaired(pairing.garment.id, 3)) == false" [class.active]="(selectedGarmentId$ | async) == 3">
                    <button class="btn-percent d-none" disabled>90%</button>
                    <span class="highlight">
                      <img class="img-fluid" src="assets/images/{{ gender }}/jacket.png">
                    </span>
                  </div>
                  <div id="cuff" class="garment {{ gender.substr(0, 1) }}-cuff" [class.d-none]="(isGarmentPaired(pairing.garment.id, 7)) == false" [class.active]="(selectedGarmentId$) == 7">
                    <button class="btn-percent d-none" disabled>90%</button>
                    <span class="highlight">
                      <img class="img-fluid" src="assets/images/{{ gender }}/cuff.png">
                    </span>
                  </div>
                  <div id="coverall" class="garment {{ gender.substr(0, 1) }}-coverall" [class.d-none]="(isGarmentPaired(pairing.garment.id, 5)) == false" [class.active]="(selectedGarmentId$ | async) == 5">
                    <button class="btn-percent d-none" disabled>90%</button>
                    <span class="highlight">
                      <img class="img-fluid" src="assets/images/{{ gender }}/coverall.png">
                    </span>
                  </div>
                  <div id="hat" class="garment d-none" [class.d-none]="(isGarmentPaired(pairing.garment.id, 6))">
                    <img class="img-fluid" src="assets/images/{{ gender }}/hat.png">
                  </div>
                  <div class="garment mannequin-toggle mannequin-toggle-{{ gender.substr(0, 1) }}-chest"></div>
                  <div class="garment mannequin-toggle mannequin-toggle-{{ gender.substr(0, 1) }}-head"></div>
                  <div class="garment mannequin-toggle mannequin-toggle-{{ gender.substr(0, 1) }}-legs"></div>
                </div>
            </div>

            </div>
        </div>    
        <hr style="border-color: #08003D;" />
    </div>    
</div>
