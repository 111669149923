import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseFilterService<T> {

  private subject$ = new BehaviorSubject<T[]>([]);
  public changes$ = this.subject$.asObservable();

  has(value: T) {
    return this.subject$.value.includes(value);
  }

  add(value: T) {
    if(!this.has(value)) {
      let arr = [...this.subject$.value];
      arr.push(value);
      this.subject$.next(arr);
    }
  }

  remove(value: T) {
    if(this.has(value)) {
      let arr = [...this.subject$.value];
      let i = arr.findIndex(val => val == value);
      arr.splice(i, 1);
      this.subject$.next(arr);
    }
  }

  toggle(value: T) {
    if(this.has(value)) {
      this.remove(value);
    } else {
      this.add(value);
    }
  }

  reset() {
    this.subject$.next([]);
  }

}
