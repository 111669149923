<ngx-guided-tour></ngx-guided-tour>

<section class="mb-4 row mx-0 ml-md-5">
  <h1 class="section-title justify-content-start">Hazards</h1>
</section>

<section class="wx-details">
  <div class="h5 text-border-primary mb-3 pb-3 d-flex align-items-center">
    <button type="button" class="wx-close">
      <span class="wx-close-icon" aria-hidden="true" [routerLink]="['/hazards']"></span>
    </button>
  </div>
  <div class="row mb-7">
      <div class="col-md-4">
          <div *ngIf="(activeHazard$ | async) as activeHazard" class="wx-details-img">
            <div *ngIf="activeHazard.image as image; else elseBlock" class="text-center">
              <img [src]="baseUrl$ + image.url" class="object-fit-cover wx-details-img-element-hazards">
            </div>
            <ng-template #elseBlock class="text-center">
                <img src="https://via.placeholder.com/150" class="object-fit-cover wx-details-img-element-hazards">
            </ng-template>
          </div>
      </div>
      <div class="col-md-8 d-flex flex-column justify-content-between">
          <div *ngIf="activeHazard$ | async as activeHazard" class="d-flex flex-column justify-content-between">
            <div id="description-and-standards">
              {{startTour()}}
              <div class="mb-5">
                  <div class="h3 text-black">{{ activeHazard.name }}</div>
                  <p>{{ activeHazard.description }}</p>
              </div>
              <div class="mb-5">
                  <div *ngIf="activeHazard.standards.length > 0" class="h6 text-black">Applicable Standards</div>
                  <div class="row">
                    <span *ngFor="let standard of activeHazard.standards" class="mr-2 d-flex flex-wrap">
                      <div *ngIf="standard.image as image; else elseBlock">
                        <img [src]="baseUrl$ + image.url" class="applicable-img">
                      </div>
                      <ng-template #elseBlock>
                        <img src="https://via.placeholder.com/45" class="applicable-img">
                      </ng-template>
                    </span>
                  </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</section>
