import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactForm } from '../models/contact-form.model';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import { AppSettingsService } from 'src/services/app-settings.service';
import { combineLatest, Observable } from 'rxjs';
import { Region } from '../models/region.model';
import { map, switchMap, take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  public emailURL:string;
  public siteKey:string;
  contactForm: FormGroup;
  submitted = false;


  constructor(private http:HttpClient, private appSettingsService:AppSettingsService, private messageService:MessageService, private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-light');
    const containerBg = document.getElementById('containerBg');
    containerBg.classList.add('rounded-corners-container');
    containerBg.style.backgroundColor = "#08003D";
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.add('rounded-corners-body');

    this.emailURL = `${environment.apiUrl}`+ '/contacts';
    this.siteKey = `${environment.recaptchaSiteKey}`;

    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      company: ['', [Validators.required]],
      message: ['', [Validators.required]],
      captcha: ['', [Validators.required]]
      });
  }

  onSubmit() {
      this.submitted = true;

      if (this.contactForm.invalid) {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'There are missing fields.'});
          return;
      }

      this.appSettingsService.appSettings$.pipe(
        switchMap(appSettings => this.appSettingsService.getRegion(appSettings.region)),
        take(1)
      ).subscribe(
        region => {
          var data = {
            "name": this.contactForm.get('name').value,
            "email": this.contactForm.get('email').value,
            "phone": "",
            "message": this.contactForm.get('message').value,
            "region": region,
            "captcha": this.contactForm.get('captcha').value
          }

          this.http.post(`${environment.apiUrl}` + "/contacts", data).subscribe(
            res => {
              if (res){
                this.messageService.add({severity:'success', summary: 'Success', detail: 'Message has been sent.'});
              }else{
                this.messageService.add({severity:'error', summary: 'Error', detail: 'There was an unexpected error, please try again.'});
                return;
              }
            },
            err => {
                this.messageService.add({severity:'error', summary: 'Error', detail: 'There was an unexpected error, please try again.'});
                return;
            }
          );
        }
      )
  }

  get f() { return this.contactForm.controls; }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-light');
    const containerBg = document.getElementById('containerBg');
    containerBg.classList.remove('rounded-corners-container');
    containerBg.style.backgroundColor = "#ffffff";
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.remove('rounded-corners-body');
  }

}
