<ngx-guided-tour></ngx-guided-tour>

<p-confirmDialog #cd>
  <p-footer>
    <button type="button" pButton label="Cancel" class="p-button-secondary" (click)="cd.reject()"></button>
    <button type="button" pButton label="Yes" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<section class="mb-4 row mx-0">
  <h1 class="section-title justify-content-start">Spec Sheet</h1>
</section>

<section>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex flex-row">
      <input id="sheetNameInput" class="ssd-input px-3" type="text"
        placeholder="{{ (activeSpecSheet$ | async)?.name }}" />
      <button class="uniform-bookmark" [class.active]="garmentPairingsSaved | async" (click)="saveSpecSheet()">
        <span class="uniform-bookmark-icon"></span>
      </button>
    </div>
    <div class="d-flex mt-3 flex-row">
      <button class="ssd-download mr-5" (click)="downloadActiveSpecSheet()" [disabled]="!(garmentPairingsSaved | async)">Download</button>
      <button class="ssd-download mr-5" (click)="openShareDialog()" [disabled]="!(garmentPairingsSaved | async)">Share</button>
      <button [routerLink]="['/my-spec-sheets']" class="ssd-my-sheets mr-1">My Sheets</button>
    </div>
  </div>
</section>

<section>
  <div *ngIf="(pairedGarments$ | async) as pairedGarments" id="spec-sheet-swatches">
    <p *ngIf="pairedGarments.length == 0" class="mt-5 text-center">No swatches have been added to this spec sheet</p>
    <div *ngIf="pairedGarments.length > 0" class="wx-sidebar-list-container">
      <ul class="wx-sidebar-list mx-3">
        <li *ngFor="let pairedGarment of pairedGarments" class="garment on py-3 m-0 ssd-list-item">
          <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="garment-spec-full" [ngStyle]="{background: 'url(' + (style.image ? baseUrl$ + style.image.url : 'https://via.placeholder.com/40x80/ffffff/000000?text=Swatch') + ')'}">
          </div>
          <div *ngIf="getStyleDetails(pairedGarment.styleId) | async as style" class="ssd-text-area">
            <div class="garment-title">
              <span>{{ style.name }}<span *ngIf="getGarmentDetails(pairedGarment.garmentId) | async as garment"> | Paired with {{ garment.name }}</span></span>
              <button (click)="toggleSelectedGarment(pairedGarment.garmentId, pairedGarment.styleId, true)" class="trash">
                <span class="trash-icon"></span>
              </button>
            </div>
            <div class="garment-text">
              <p class="mb-0">{{ style.description }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>
<p-dialog 
  #sd
  header="Share Spec Sheet" 
  [(visible)]="showShareDialog" 
  [style]="{'min-width': '33vw'}"
  [modal]="true"
>
  <p>Enter an email below to share your spec sheet.</p>
  <form [formGroup]="shareForm" (ngSubmit)="shareActiveSpecSheet()">
    <input type="email" pInputText formControlName="shareEmail" placeholder="john.doe@gmail.com" />
    <div class="mt-3">
      <re-captcha formControlName="captcha" required siteKey="{{ siteKey }}"></re-captcha>
    </div>
      <div class="d-flex justify-content-end align-items-center"> 
        <button type="button" class="p-button-secondary" (click)="sd.close($event)">Cancel</button>
        <button type="submit" class="p-button" [disabled]="sharingInProgress">
          Share
          <p-progressSpinner styleClass="share-spinner" *ngIf="sharingInProgress"></p-progressSpinner>
        </button>
      </div>
  </form>
</p-dialog>
