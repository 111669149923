import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-primary');
    const containerBg = document.getElementById('containerBg');
    containerBg.style.backgroundColor = "#08003D";
    containerBg.classList.add('rounded-corners-container');
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.add('rounded-corners-body');
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-primary');
    const containerBg = document.getElementById('containerBg');
    containerBg.classList.remove('rounded-corners-container');
    containerBg.style.backgroundColor = "#ffffff";
    const roundedCorners = document.getElementById('roundedCorners');
    roundedCorners.classList.remove('rounded-corners-body');
  }

}
