<div class="wx-sidebar">
    <div class="wx-sidebar-heading pb-2">
        <div class="h5 text-border-accent">Resources</div>
    </div>

    <div class="wx-sidebar-list-container mt-3">
        <p *ngIf="(activeStandard$ | async)?.videos.length == 0 && (activeStandard$ | async)?.resources.length == 0">This standard has no available resources</p>
        <div *ngIf="(activeStandard$ | async)?.videos as resources">
            <div *ngIf="resources.length > 0" class="h5 text-primary">Video Resources</div>
        </div>
        <div *ngIf="(activeStandard$ | async)?.videos as resources">
            <div *ngIf="resources.length > 0">
                <div *ngFor="let resource of resources">
                    <div class="wx-card-fluid" style="max-width: 170px;">
                        <a class="wx-card-inner" [attr.href]="resource.url" target="_blank" [title]="resource.name">
                        <div *ngIf="resource.image as image; else elseBlock">
                            <img [src]="baseUrl + image.url" class="wx-card-img">
                        </div>
                        <ng-template #elseBlock>
                            <img src="https://via.placeholder.com/170x220" class="wx-card-img">
                        </ng-template>
                        <div class="wx-card-body">
                            <h6 class="wx-card-title-resource">{{ resource.name }}</h6>
                            <p class="card-text">{{ resource.description }}</p>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        
        <div *ngIf="(activeStandard$ | async)?.resources as resources" style="margin-left: 15px;">
            <div *ngIf="resources.length > 0" class="h5 text-primary" style="margin-left: 0.5rem;">Resources to Download</div>
        </div>
        <div *ngIf="(activeStandard$ | async)?.resources as resources">
            <div *ngIf="resources.length > 0" class="d-flex flex-wrap">
                <div *ngFor="let resource of resources" class="mb-5" style="max-width: 170px;">
                    <div class="wx-card-fluid" style="max-width: 170px;">
                        <div class="wx-card-inner">
                            <div *ngIf="resource.image as image; else elseBlock">
                                <img [src]="baseUrl + image.url" class="wx-card-img">
                            </div>
                            <ng-template #elseBlock>
                                <img src="https://via.placeholder.com/170x220" class="wx-card-img">
                            </ng-template>
                            <div class="wx-card-body">
                                <h6 class="wx-card-title-resource">{{ resource.name }}</h6>
                                <p class="card-text">{{ resource.description }}</p>
                                <div class="text-center">
                                    <a class="btn btn-primary btn-sm w-100" [attr.href]="resource.url" target="_blank" [title]="resource.name">Download</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>