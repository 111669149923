import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { StyleDetails } from 'src/app/models/style.model';
import { LineDetails } from 'src/app/models/line.model';
import { CategoryDetails } from 'src/app/models/category.model';
import { SpecialDetails } from 'src/app/models/special.model';

@Injectable({
  providedIn: 'root'
})
export class ProductLineService {

  private styles$: Observable<StyleDetails[]>;
  private lines$: Observable<LineDetails[]>;
  private categories$: Observable<CategoryDetails[]>;
  private specials$: Observable<SpecialDetails[]>;

  constructor(private http: HttpClient) { }

  getCategories() : Observable<CategoryDetails[]>{
    if(!this.categories$) {
      this.categories$ = this.http.get<CategoryDetails[]>(`${environment.apiUrl}` + "/categories").pipe(
        map(categories => categories.sort((a,b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))),
        shareReplay(1)
      );
    }

    return this.categories$;
  }

  getProductLines() : Observable<LineDetails[]>{
    if(!this.lines$) {
      this.lines$ = this.http.get<LineDetails[]>(`${environment.apiUrl}` + "/lines").pipe(
        map(lines => lines.sort((a,b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))),
        shareReplay(1)
      );
    }

    return this.lines$;
  }

  getProductStyles() : Observable<StyleDetails[]>{
    if(!this.styles$) {
      this.styles$ = this.http.get<StyleDetails[]>(`${environment.apiUrl}` + "/styles").pipe(
        shareReplay(1)
      );
    }

    return this.styles$;
  }

  getSpecials() {
    if(!this.specials$) {
      this.specials$ = this.http.get<SpecialDetails[]>(`${environment.apiUrl}` + "/specials").pipe(
        map(specials => specials.sort((a,b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))),
        shareReplay(1)
      );
    }

    return this.specials$;
  }

  getSpecial(specialId: number) {
    return this.getSpecials().pipe(
      map(specials => specials.find(special => special.id == specialId))
    );
  }

  getProductLine(lineId:number){
    return this.getProductLines().pipe(
      map(lines => lines.find(line => line.id == lineId))
    );
  }

  getStyle(styleId:number){
    return this.getProductStyles().pipe(
      map(styles => styles.find(style => style.id == styleId))
    );
  }

}
