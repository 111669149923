import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Observable, combineLatest, Subject, of, BehaviorSubject } from 'rxjs';
import { debounceTime, delay, every, map, shareReplay, startWith, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductLineService } from 'src/services/product-line.service';
import { StandardService } from 'src/services/standard.service';
import { ActivatedRoute } from '@angular/router';
import { stringify } from 'querystring';
import { LineDetails } from '../models/line.model';
import { StyleDetails } from '../models/style.model';
import { FormControl } from '@angular/forms';
import { faChevronDown, faPlus, faMinus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { CategoryDetails } from '../models/category.model';
import { GarmentDetails } from '../models/garment.model';
import { SpecialDetails } from '../models/special.model';
import { CategoryFilterService } from 'src/services/category-filter.service';
import { GarmentFilterService } from 'src/services/garment-filter.service';
import { LineFilterService } from 'src/services/line-filter.service';
import { SpecialFilterService } from 'src/services/special-filter.service';
import { GarmentService } from 'src/services/garment.service';
import { filterCategories, filterGarments, filterLines, filterRegions, filterSearchProductCatalog, filterSearchProductLine, filterSpecials, filterStandards } from '../operators';
import { StandardDetails } from '../models/standard.model';
import { Carousel } from 'primeng/carousel';
import { AppSettingsService } from 'src/services/app-settings.service';
import { Region, RegionDetails } from '../models/region.model';
import { RegionFilterService } from 'src/services/region-filter.service';
import { GuidedTourService, GuidedTour, Orientation } from 'ngx-guided-tour';
import { TourService } from 'src/services/tour.service';

interface LineView {
  id: number;
  name: string;
  styles: StyleDetails[]
}

@Component({
  selector: 'app-product-catalog',
  templateUrl: './product-catalog.component.html',
  styleUrls: ['./product-catalog.component.scss']
})
export class ProductCatalogComponent implements OnInit {

  baseUrl = environment.apiUrl;
  numProductsShown: number;
  destroyed$ = new Subject();

  @ViewChild('productCatalogCarousel') carousel: Carousel;

  private startPos: any; // tap positions
  private swipeThreshold: number = 20; // how many pixels until swipe is registered

  responsiveOptions = [
    {
      breakpoint: '1267px',
      numVisible: 4,
    },
    {
      breakpoint: '1025px',
      numVisible: 3,
    },
    {
      breakpoint: '991px',
      numVisible: 4,
    },
    {
      breakpoint: '775px',
      numVisible: 3,
    },
    {
      breakpoint: '592px',
      numVisible: 2,
    },
    {
      breakpoint: '476px',
      numVisible: 1,
      numScroll: 1
    }
  ];


  /**
   * Icons
   */
  dropdownCaretIcon = faChevronDown;
  showFilterIcon = faPlus;
  hideFilterIcon = faMinus;
  searchIcon = faSearch;

  /**
   * Filters.
   */
  filterCategories$: Observable<CategoryDetails[]>;
  allCategories$: Observable<CategoryDetails[]>;

  filterSpecials$: Observable<SpecialDetails[]>;
  allSpecials$: Observable<SpecialDetails[]>;

  filterLines$: Observable<LineDetails[]>;
  allLines$: Observable<LineDetails[]>;

  filterStandards$: Observable<StandardDetails[]>;
  regionStandards$: Observable<StandardDetails[]>;

  filterGarments$: Observable<GarmentDetails[]>;
  allGarments$: Observable<GarmentDetails[]>;

  filteredStyles$: Observable<StyleDetails[]>;
  filteredLines$: Observable<LineDetails[]>;

  filterRegions$: Observable<RegionDetails[]>;
  allRegions$: Observable<RegionDetails[]>;
  allStandards$: Observable<StandardDetails[]>;

  search = new FormControl(null);
  search$: Observable<string>;
  
  prevScrollHeight: number = 0;

  private tourIsCompleteSubject = new BehaviorSubject<boolean>(false);
  tourIsComplete: Observable<boolean> = this.tourIsCompleteSubject.asObservable();
  tourIsLoaded: boolean = false;
  mobileSize = window.innerWidth <= 768

  private mobileSteps = [
    {
      title: 'Search',
      selector: '#catalog-search',
      content: 'Already know what you are looking for? Search for a specific product here.',
      orientation: window.innerWidth <= 767 ? Orientation.Bottom : Orientation.BottomRight
    },
    {
      title: 'Filters',
      selector: '#catalog-filters',
      content: 'Add or remove criteria to filter products that will match your needs.',
      orientation: Orientation.Bottom
    },
    {
      title: 'Products',
      selector: '.catalog-carousel',
      content: 'Swipe or scroll to browse products that have been grouped by product line.',
      orientation: Orientation.Top
    },
    {
      title: 'View Details',
      selector: '.catalog-details-btn',
      content: 'View additional information that will help to identify properties and applications of your product.',
      orientation: window.innerWidth <= 767 ? Orientation.Top : Orientation.TopLeft
    }
  ]
  
  private fullSteps = [
    {
      title: 'Selected Standards',
      selector: '.wx-sidebar-container',
      content: 'The Standards Sidebar shows your standard selections along with standards from other regions. Selections can be changed here while you browse our products.',
      orientation: Orientation.Right
    }
  ].concat(this.mobileSteps)

  public catalogTour: GuidedTour = {
    tourId: 'catalog-tour',
    useOrb: false,
    steps: [],
    completeCallback: () => this.tourComplete(),
    skipCallback: () => this.tourComplete()
  };


  constructor(
    private productLineService: ProductLineService,
    private standardService: StandardService,
    private garmentService: GarmentService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    public categoryFilters: CategoryFilterService,
    public specialFilters: SpecialFilterService,
    public lineFilters: LineFilterService,
    public garmentFilters: GarmentFilterService,
    public regionFilters: RegionFilterService,
    public appSettingsService: AppSettingsService,
    private guidedTourService: GuidedTourService,
    private tourService: TourService
  ) { }

  ngOnInit(): void {
    this.search$ = this.search.valueChanges.pipe(
      startWith(''),
      debounceTime(250),
      shareReplay(1)
    );

    this.allCategories$ = this.productLineService.getCategories();
    this.allSpecials$ = this.productLineService.getSpecials();
    this.allLines$ = this.productLineService.getProductLines();
    this.allGarments$ = this.garmentService.getAllGarments();
    this.allRegions$ = this.appSettingsService.getAllRegions();
    this.allStandards$ = this.standardService.getAllStandards();

    this.filterStandards$ = combineLatest([
      this.standardService.getAllStandards(),
      this.standardService.activeStandards$
    ]).pipe(
      map(([allStandards, activeStandardIds]) => allStandards.filter(standard => activeStandardIds.includes(standard.id)))
    );

    this.filterCategories$ = combineLatest([
      this.allCategories$,
      this.categoryFilters.changes$
    ]).pipe(
      map(([categories, filterCategoryIds]) => categories.filter(category => filterCategoryIds.includes(category.id)))
    );

    this.filterSpecials$ = combineLatest([
      this.allSpecials$,
      this.specialFilters.changes$
    ]).pipe(
      map(([specials, filterSpecialIds]) => specials.filter(special => filterSpecialIds.includes(special.id)))
    );

    this.filterLines$ = combineLatest([
      this.allLines$,
      this.lineFilters.changes$
    ]).pipe(
      map(([lines, filterLineIds]) => lines.filter(line => filterLineIds.includes(line.id)))
    );

    this.filterGarments$ = combineLatest([
      this.allGarments$,
      this.garmentFilters.changes$
    ]).pipe(
      map(([garments, filterGarmentIds]) => garments.filter(garment => filterGarmentIds.includes(garment.id)))
    );

    this.filterRegions$ = combineLatest([
      this.allRegions$,
      this.regionFilters.changes$
    ]).pipe(
      map(([regions, filterRegionIds]) => regions.filter(region => filterRegionIds.includes(region.id)))
    );

    this.filteredStyles$ = this.productLineService.getProductStyles().pipe(
      filterStandards(this.standardService.activeStandards$),
      filterCategories(this.categoryFilters.changes$),
      filterLines(this.lineFilters.changes$),
      filterGarments(this.garmentFilters.changes$),
      filterSpecials(this.specialFilters.changes$),
      filterSearchProductCatalog(this.search$),
      filterRegions(this.allStandards$,this.regionFilters.changes$)
    );

    this.filteredLines$ = combineLatest([this.allLines$, this.filteredStyles$]).pipe(
      map(([lines, filteredStyles]) => lines.filter(line => filteredStyles.map(style => style.line.id).includes(line.id)))
    )

    this.setNumProductsShown();

    this.tourIsCompleteSubject.next(this.tourService.getCatalogTourComplete());
  }

  ngAfterViewChecked() {
    var complete = false;
    this.tourIsComplete.subscribe(bool => complete = bool);

    if (complete) {
      this.route.params.pipe(
        delay(1000),
        takeUntil(this.destroyed$)
      ).subscribe(params => {
        var element = this.elRef.nativeElement.querySelector(`#line-${params['lineId']}`);
        if (element && this.canScrollDown(element)) {
          element.scrollIntoView()
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  canScrollDown(element: any): boolean {
    var can = (this.prevScrollHeight !== element.scrollHeight);

    this.prevScrollHeight = element.scrollHeight;

    return can;
  }

  getFilteredStyles(lineId: number) {
    return this.filteredStyles$.pipe(
      map(styles => styles.filter(style => style.line.id == lineId))
    );
  }

  filterCategoryHasRecords(categoryId: number) {
    return this.filteredStyles$.pipe(
      filterCategories(of([categoryId])),
      map(lines => lines.length > 0)
    );
  }

  filterLineHasRecords(lineId: number) {
    return this.filteredStyles$.pipe(
      filterLines(of([lineId])),
      map(lines => lines.length > 0)
    );
  }

  filterGarmentHasRecords(garmentId: number) {
    return this.filteredStyles$.pipe(
      filterGarments(of([garmentId])),
      map(lines => lines.length > 0)
    );
  }

  filterSpecialHasRecords(specialId: number) {
    return this.filteredStyles$.pipe(
      filterSpecials(of([specialId])),
      map(lines => lines.length > 0)
    );
  }

  filterRegionHasRecords(regionId: number) {
    return this.filteredStyles$.pipe(
      filterRegions(this.allStandards$,of([regionId])),
      map(styles => styles.length > 0)
    );
  }

  removeActiveStandard(standardId: number) {
    this.standardService.toggleActiveStandard(standardId);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setNumProductsShown();
  }

  setNumProductsShown() {
    if (window.innerWidth <= 476) {
      this.numProductsShown = 1;
    } else if (window.innerWidth <= 592) {
      this.numProductsShown = 2;
    } else if (window.innerWidth <= 775 || (window.innerWidth > 991 && window.innerWidth <= 1025)) {
      this.numProductsShown = 3;
    } else if (window.innerWidth <= 1267) {
      this.numProductsShown = 4;
    } else {
      this.numProductsShown = 5;
    }
  }

  // Touch events for carousel
  onTouchEnd(e: any) {
    let touchobj = e.changedTouches[0];

    this.changePageOnTouch(e, (touchobj.pageX - this.startPos.x));
  }

  onTouchStart(e: any) {
    let touchobj = e.changedTouches[0];

    this.startPos = {
      x: touchobj.pageX,
      y: touchobj.pageY
    };
  }

  onTouchMove = () => { };

  changePageOnTouch(e, diff) {
    if (Math.abs(diff) > this.swipeThreshold) {
      if (diff < 0) {
        this.navForward(e);
      }
      else {
        this.navBackward(e);
      }
    }
  }

  navForward(e: any) {
    let i = this.carousel.page + 1;
    if (this.carousel.page < this.carousel.value.length && this.carousel.page > 0) {
      this.carousel.navForward(e, i);
    }
  }

  navBackward(e: any) {
    let i = this.carousel.page - 1;
    if (this.carousel.page < this.carousel.value.length && this.carousel.page > 0) {
      this.carousel.navForward(e, i);
    }
  }

  ngAfterViewInit(): void {
    var complete = false;
    this.tourIsComplete.subscribe(bool => complete = bool);

    if (!complete) {
      if (window.innerWidth <= 991) {
        this.catalogTour.steps = this.mobileSteps;
      } else {
        this.catalogTour.steps = this.fullSteps;
      }

      this.tourIsLoaded = true;

      setTimeout(() => {
        this.guidedTourService.startTour(this.catalogTour);
      }, 3000);
    }
  }

  public restartTour(): void {
    this.guidedTourService.startTour(this.catalogTour);
  }

  private tourComplete(): void {
    this.tourIsCompleteSubject.next(true);
    this.tourService.setCatalogTourComplete(true);
  }

}
