<ngx-guided-tour></ngx-guided-tour>

<div class="scroll-snap-container mx-0 ml-md-5">
  <section class="scroll-snap-child"></section>
  <section class="mb-4 row mx-0 mt-md-6 catalog-title">
    <h1 class="section-title justify-content-start">Product Lines</h1>
  </section>

  <form class="form-inline mb-3">
    <div class="input-group ml-auto wx-form-search-group" id="product-lines-search">
      <input type="text" class="form-control wx-form-search-bar" [formControl]="search" placeholder="Search" aria-label="Search" aria-describedby="button-addon2">
      <div class="input-group-append">
        <button class="btn btn-primary px-2 wx-form-search-btn" type="button" id="button-addon2"><fa-icon class="wx-form-search-icon" [icon]="searchIcon"></fa-icon></button>
      </div>
    </div>
  </form>

  <div id="product-lines-filters">
    <h6 class="text-border-primary">Filters</h6>
    <div class="mb-2">
      <div class="btn-group mr-4" dropdown>
        <button dropdownToggle type="button" class="btn btn-sm btn-link px-0" aria-controls="dropdown-basic">
            Categories <fa-icon class="ml-1" [icon]="dropdownCaretIcon"></fa-icon>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngFor="let category of allCategories$ | async">
            <a [ngClass]="{'disabled bg-light': categoryFilters.has(category.id) || !(filterCategoryHasRecords(category.id) | async)}" class="dropdown-item" [routerLink]="" (click)="categoryFilters.add(category.id)">{{category.name}}</a>
          </li>
        </ul>
      </div>

      <div class="mr-4 btn-group" dropdown>
        <button dropdownToggle type="button" class="btn btn-sm btn-link px-0" aria-controls="dropdown-basic">
            Lines <fa-icon class="ml-1" [icon]="dropdownCaretIcon"></fa-icon>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngFor="let line of allLines$ | async">
            <a [ngClass]="{'disabled bg-light': lineFilters.has(line.id) || !(filterLineHasRecords(line.id) | async)}" class="dropdown-item" [routerLink]="" (click)="lineFilters.add(line.id)">{{line.name}}</a>
          </li>
        </ul>
      </div>

      <div class="mr-4 btn-group" dropdown>
        <button dropdownToggle type="button" class="btn btn-sm btn-link px-0" aria-controls="dropdown-basic">
            Garments <fa-icon class="ml-1" [icon]="dropdownCaretIcon"></fa-icon>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngFor="let garment of allGarments$ | async">
            <a [ngClass]="{'disabled bg-light': garmentFilters.has(garment.id) || !(filterGarmentHasRecords(garment.id) | async)}" class="dropdown-item" [routerLink]="" (click)="garmentFilters.add(garment.id)">{{garment.name}}</a>
          </li>
        </ul>
      </div>

      <div class="mr-4 btn-group" dropdown>
        <button dropdownToggle type="button" class="btn btn-sm btn-link px-0" aria-controls="dropdown-basic">
            Specials <fa-icon class="ml-1" [icon]="dropdownCaretIcon"></fa-icon>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngFor="let special of allSpecials$ | async">
            <a [ngClass]="{'disabled bg-light': specialFilters.has(special.id) || !(filterSpecialHasRecords(special.id) | async)}" class="dropdown-item" [routerLink]="" (click)="specialFilters.add(special.id)">{{special.name}}</a>
          </li>
        </ul>
      </div>
      <div class="btn-group mr-4" dropdown>
        <button dropdownToggle type="button" class="btn btn-sm btn-link px-0" aria-controls="dropdown-basic">
            Regions <fa-icon class="ml-1" [icon]="dropdownCaretIcon"></fa-icon>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem" *ngFor="let region of allRegions$ | async">
            <a [ngClass]="{'disabled bg-light': regionFilters.has(region.id) || !(filterRegionHasRecords(region.id) | async)}" class="dropdown-item" [routerLink]="" (click)="regionFilters.add(region.id)">{{region.name}}</a>
          </li>
        </ul>
      </div>
    </div>
    <wx-chip *ngFor="let category of filterCategories$ | async" class="mr-2 mb-2" label="Category" [value]="category.id" [name]="category.name" (remove)="categoryFilters.remove(category.id)"></wx-chip>
    <wx-chip *ngFor="let line of filterLines$ | async" class="mr-2 mb-2" label="Line" [value]="line.id" [name]="line.name" (remove)="lineFilters.remove(line.id)"></wx-chip>
    <wx-chip *ngFor="let garment of filterGarments$ | async" class="mr-2 mb-2" label="Garment" [value]="garment.id" [name]="garment.name" (remove)="garmentFilters.remove(garment.id)"></wx-chip>
    <wx-chip *ngFor="let special of filterSpecials$ | async" class="mr-2 mb-2" label="Special" [value]="special.id" [name]="special.name" (remove)="specialFilters.remove(special.id)"></wx-chip>
    <wx-chip *ngFor="let standard of filterStandards$ | async" class="mr-2 mb-2" label="Standard" [value]="standard.id" [name]="standard.name" (remove)="removeActiveStandard(standard.id)"></wx-chip>
    <wx-chip *ngFor="let region of filterRegions$ | async" class="mr-2 mb-2" label="Region" [value]="region.id" [name]="region.name" (remove)="regionFilters.remove(region.id)"></wx-chip>
  </div>

  <section>
    <div class="my-3" *ngIf="filteredCategories$ | async as categories">
      <p *ngIf="categories.length == 0" class="mt-5 text-center">No products match the search parameters</p>
      <div *ngFor="let category of categories">
        <div *ngIf="getFilteredLines(category.id) | async as lines">
          <p-carousel #productLineCarousel class="scroll-snap-child catalog-carousel product-lines-carousel" [id]="'category-' + category.id" *ngIf="lines.length > 0" [value]="lines" [numVisible]="numProductsShown" [numScroll]="numProductsShown" [circular]="false" [responsiveOptions]="responsiveOptions" [class.hidden-pagination]="numProductsShown >= lines.length">
            <ng-template pTemplate="header">
              <h6 class="text-border-primary">{{ category.name }}</h6>
            </ng-template>
            <ng-template let-line pTemplate="item">
              <div class="wx-card-inner cursor-pointer" [routerLink]="['/product-lines-expanded', line.id]" (touchend)="onTouchEnd($event)" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)">
                <div *ngIf="line.image as image; else elseBlock">
                  <div [class.wx-card-img-lines-tour]="tourIsLoaded && !(tourIsComplete | async) && mobileSize" class="mx-auto wx-card-img-lines" [ngStyle]="{backgroundImage:'url('+ baseUrl + image.url +')'}"></div>
                </div>
                <ng-template #elseBlock>
                  <img src="https://via.placeholder.com/150" class="wx-card-img">
                </ng-template>
                <div class="wx-card-body">
                  <h6 class="wx-card-title">{{ line.name }}</h6>
                  <p *ngIf="!tourIsLoaded || (tourIsComplete | async) || !mobileSize" class="card-text">{{ line.description }}</p>
                </div>
              </div>
              <button class="btn btn-primary btn-sm explore-in-catalog-btn" [routerLink]="['/product-catalog', line.id]">Explore in catalog</button>
            </ng-template>
          </p-carousel>
        </div>
      </div>
    </div>

  <!--
        <div *ngFor="let category of (categories$ | async)">
        <h6 class="text-border-primary">{{ category.name }}</h6>
        <div *ngIf="category.lines as categoryLines" class="wx-carousel">
            <div *ngFor="let line of categoryLines" class="wx-card" [routerLink]="['/product-catalog']">
                <div class="wx-card-inner">
                    <img src="https://via.placeholder.com/150" class="wx-card-img">
                    <div class="wx-card-body">
                        <h6 class="wx-card-title">{{ line.name }}</h6>
                        <p class="card-text">{{ line.description }}</p>
                    </div>
                </div>
                <button class="btn btn-primary btn-sm btn-block">Explore in catalog</button>
            </div>
        </div>
      </div>
  -->
  </section>
</div>
