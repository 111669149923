<ngx-guided-tour></ngx-guided-tour>

<section class="mb-6">
  <h1 class="section-title">Settings</h1>
</section>

<section class="container d-block">
  <div class="row">
    <div class="col-lg mb-4 mb-lg-0 d-flex flex-column align-items-center d-md-inline">
        <h2 class="h5 text-primary text-center">Reset Tours</h2>
        <p class="text-center">Click the button below to restart the feature tours.</p>
        <div class="row">
          <div class="col text-center">
            <button class="btn btn-accent btn-sm hazard-details-btn" style="width: 200px;" (click)="resetTours()">Reset</button>
          </div>
        </div>
    </div>
  </div>
</section>