import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Carousel } from 'primeng/carousel';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GarmentService } from 'src/services/garment.service';
import { RegionFilterService } from 'src/services/region-filter.service';
import { SpecSheetService } from 'src/services/spec-sheet.service';
import { StandardService } from 'src/services/standard.service';
import { GarmentPairing } from './models/garment-pairing.model';
import { SpecSheet } from './models/spec-sheet.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'WestexSPA';
  showSelectedStandards: boolean;
  showFooter: boolean;
  showHeader: boolean;
  showGarmentSelector: boolean;
  showTwoColumn: boolean;
  showThreeColumn: boolean;
  showResourcesSidebar: boolean;
  showEmptyRightColumn: boolean;
  inCatalog: boolean;
  inCart: boolean;
  isDashboard: boolean;
  isUniformBuilder: boolean;

  exporting$: Observable<boolean>;

  constructor(
    private router: Router, 
    private specSheetService: SpecSheetService, 
    private regionFilters:RegionFilterService, 
    private garmentService: GarmentService, 
    private standardService: StandardService, 
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) { }

  ngOnInit(){
    this.exporting$ = this.activatedRoute.queryParams.pipe(
      map(params => params['export'] === 'true' || false)
    );

    Carousel.prototype.onTouchMove = () => { };

    if (!this.regionFilters.has(1)){
      this.regionFilters.add(1)
    }

    if (localStorage.getItem("spec-sheets") === null){
      localStorage.setItem('spec-sheets', JSON.stringify([]));
    }

    if (this.specSheetService.getAllSpecSheets().length <= 0) {
      var specSheet = new SpecSheet("My Spec Sheet");
      this.specSheetService.addSpecSheet(specSheet);
    } else {
      this.specSheetService.setActiveSpecSheetId(this.specSheetService.getAllSpecSheets()[0].id);
    }

    // convert old spec sheets to new ones
    var garmentPairings: any = this.specSheetService.get(this.specSheetService.activeSpecSheetIdString$).garmentPairings;
    var oldPairings = garmentPairings.filter(gp => gp.id !== undefined);
    if (oldPairings.length > 0) {
      var newPairings = garmentPairings.filter(gp => gp.id === undefined);

      for (let pairing of oldPairings) {
        newPairings.push({
          garmentId: pairing.id,
          styleId: pairing.style.id
        });
      }

      garmentPairings = newPairings;
      var updatedSpecSheet = this.specSheetService.get(this.specSheetService.activeSpecSheetIdString$);
      updatedSpecSheet.garmentPairings = garmentPairings;
      this.specSheetService.updateSpecSheet(updatedSpecSheet);
    }

    this.garmentService.addGarmentPairings(garmentPairings);
    this.garmentService.activeSpecSheetId = this.specSheetService.activeSpecSheetIdString$;

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);

      var route = this.router.url.split('/')[1];
      var pagesWithoutSelectedStandards = ['login', 'dashboard', 'contact-us','contact-sales', 'regions', 'settings', 'spec-sheet-details', 'my-spec-sheets', 'hazards', 'hazard-details', 'cart', ''];
      var pagesWithoutFooter = ['login'];
      var pagesWithoutHeader = ['login'];

      var pagesWithGarmentSelector = ['product-details', 'uniform-builder'];
      var pagesWithTwoCols = ['standards', 'hazards', 'hazard-details', 'product-lines', 'product-catalog', 'spec-sheet-details', 'my-spec-sheets'];
      var pagesWithThreeCols = ['product-details', 'standard-details', 'product-lines-expanded'];
      var pagesWithResourcesSidebar = ['standard-details'];
      var pagesWithEmptyRightColumn = ['hazards', 'hazard-details', 'product-lines-expanded'];
      var catalogs = ['product-lines', 'product-catalog'];
      var cart = ['cart', 'view', 'checkout']

      this.showSelectedStandards = !pagesWithoutSelectedStandards.includes(route);
      this.showFooter = !pagesWithoutFooter.includes(route);
      this.showHeader = !pagesWithoutHeader.includes(route);

      this.showGarmentSelector = pagesWithGarmentSelector.includes(route);
      this.showTwoColumn = pagesWithTwoCols.includes(route);
      this.showThreeColumn = pagesWithThreeCols.includes(route);
      this.showResourcesSidebar = pagesWithResourcesSidebar.includes(route) ;
      this.showEmptyRightColumn = pagesWithEmptyRightColumn.includes(route);
      this.inCatalog = catalogs.includes(route);
      this.inCart = cart.includes(route);
      this.isDashboard = route == "dashboard";
      this.isUniformBuilder = route == "uniform-builder";

      let activeRoute: ActivatedRoute = this.router.routerState.root;
      let routeTitle = '';
      while (activeRoute!.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      if (activeRoute.snapshot.data['title']) {
        routeTitle = activeRoute!.snapshot.data['title'];
      }

      if(routeTitle) {
        this.titleService.setTitle(`Westex - ${routeTitle}`);        
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload($event) {
    var areGarmentPairingsSaved;
    this.garmentService.garmentsPairings$.subscribe(pgs => this.specSheetService.areGarmentPairingsSaved(pgs));
    this.specSheetService.areGarmentPairingsSaved$.subscribe(bool => areGarmentPairingsSaved = bool);

    if (areGarmentPairingsSaved == false){
      $event.returnValue = "Your garment pairings have not been saved.";
      return "Your garment pairings have not been saved.";
    }
  }
}
